import React from "react";
// Customizable Area Start
import { Box, Typography, Button, styled, Tooltip } from "@material-ui/core";
import { qrLogo, CloseIc, flxooLiveLogo, downloadIcon, copy, qrCodeTop, qrCodeBottom } from "./assets"
import { QRCode } from 'react-qrcode-logo';
import ProfileQRCodeController, { Props, configJSON } from "./ProfileQrCodeController.web";
let frameworkConfigJSON = require('../../../framework/src/config')
// Customizable Area End

class ProfileQRCode extends ProfileQRCodeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const profileUrl = frameworkConfigJSON.baseURL + "/visit_store/" + this.state.sellerProfileData?.id
        
        return (
            <StyledBox>
                <Box className="profileQRCodeTxtContainer">
                    <Typography className="profileQrCodeTxt">{configJSON.profileQrCode}</Typography>
                    <Typography data-test-id="profileQrgenerate" className={this.state.isGenerate ? "generateTxt generatingTxt" : "generateTxt"} onClick={() => {
                        this.state.sellerProfileData?.attributes?.qr_generated ? this.handleOpenProfileQRModal() : this.getSellerProfileData();
                    }} >{this.renderDynamicText()}</Typography>
                </Box>
                { this.state.profileQRModal && 
                <StyledDialog>
                    <Box className="modalOverlay" data-test-id="profileQRModalID" onClick={this.handleCloseProfileQRModal}></Box>
                    <Box className="profileQRModal">
                        <Box className="profileQrModalClose" onClick={this.handleCloseProfileQRModal}>
                            <img src={CloseIc} alt="Close" data-test-id="closeQrmodal" />
                        </Box>
                        <Box className="profileQRCodeWrapper">
                        <div className="forQRDownload"ref={this.qrRef}>
                            <img src={qrCodeTop} alt="qr code top" />
                                <Box className="profileQRcodeBox" >
                                    <Typography className="qrCodeTitle">{this.state.sellerProfileData?.attributes?.business_name}</Typography>
                                    <Typography className="qrCodeDesc">{configJSON.qrCodeDesc}</Typography>
                                    <Typography component="span">
                                        <QRCode qrStyle="fluid" value={profileUrl} logoImage={qrLogo} size={130} />
                                    </Typography>
                                    <Box className="borderbox" />
                                    <img className="flixooImage" src={flxooLiveLogo} alt="flxooTextLogo" />
                                    <Typography className="qrCodeDescBottom">{configJSON.qrCodeDescBottom}</Typography>
                                </Box>
                            <img src={qrCodeBottom} alt="qr code bottom" />
                        </div>
                        </Box>
                        <Box className="buttonContainer">
                            <Button data-test-id="downloadButton" startIcon={<img src={downloadIcon} />} className="downloadButton" onClick={this.handledownloadButton}>{configJSON.download}</Button>
                            <Tooltip
                                title="Copied!"
                                open={this.state.tooltipCopyUrl}
                                disableHoverListener
                                disableFocusListener
                                disableTouchListener
                            >
                                <Button data-test-id="copyButton" onClick={() => this.handleCopyURL(profileUrl)} startIcon={<img src={copy} style={{height:"16px"}}/>} className="copyButton">{configJSON.copy}</Button>
                            </Tooltip>
                        </Box>
                    </Box>
                </StyledDialog>
                }

            </StyledBox>
        );
        // Customizable Area End
    }
}

export default ProfileQRCode

// Customizable Area Start
const StyledBox = styled(Box)({
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
    "& .profileQRCodeTxtContainer": {
        display: "flex",
        justifyContent: "space-between",
        "& .profileQrCodeTxt": {
            color: "#555555",
            fontFamily: 'Poppins !important',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },
        "& .generateTxt": {
            color: "#555555",
            fontFamily: 'Poppins !important',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
            textDecoration: "underline",
            cursor: "pointer"
        },
        "& .generatingTxt": {
            textDecoration: "none",
            cursor: "none"
        }
    }
});
const StyledDialog = styled(Box)({
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.8)",
    width: "calc(100% - 288px)",
    right: 0,
    top: "20px",
    height: "100%",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
    "& .modalOverlay":{
        position: "absolute",
        height: "100%",
        width: "100%"
    },
    "& .profileQRModal": {
        width: "90%",
        maxWidth: "400px",
        padding: "1em",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        position: "relative",
        maxHeight: "430px",
        overflowY: "auto",
        top: "10px",
        "& .profileQrModalClose": {
            position: "absolute",
            right: "10px",
            top: "5px",
            cursor: "pointer"
        },
        "& .profileQRCodeWrapper": {
            marginTop: "10px",
            display: "flex",
            justifyContent: "center",
            "& .forQRDownload": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                backgroundColor: "#fff",
                position: "relative",
                height: "296px",
                "& img": {
                    width: "100%"
                },
                "& .flixooImage": {
                    width: "90px",
                    height: "35px"
                },
                "& .borderbox": {
                    borderTop: "2px dotted rgba(110, 110, 110, 0.5)",
                    width: "100%",
                    marginBottom: "20px",
                    display:"none"
                },
                "& .qrCodeDescBottom": {
                    color: "rgba(78, 78, 78, 1)",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "14px"
                }
            }
        },
        "& .profileQRcodeBox": {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            position: "absolute",
            top: "20px",
            "& .qrCodeTitle": {
                color: "rgba(46, 46, 46, 1)",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "20px"
            },
            "& .qrCodeDesc": {
                color: "rgba(78, 78, 78, 1)",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "14px"
            }
        },
        "& .flixooImage": {
            height: "30px",
            width: "75px"
        },
        "& .buttonContainer": {
            display: "flex",
            alignItems: "center",
            flexDirection: "column" as const,
            marginTop: "0.8em",
        },
        "& .downloadButton": {
            width: "100%",
            height: '2em',
            background: '#F5EA16',
            borderRadius: "25px",
            color: '#444',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
            pointer: "cursor",
            textTransform: "none",
            "&:hover": {
                background: '#F5EA16',
            }
        },
        "& .copyButton": {
            marginTop: "0.8em",
            width: '100%',
            height: '2em',
            background: 'none',
            border: '1px solid black',
            borderRadius: "22px",
            color: '#444',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: 'normal',
            pointer: "cursor",
            textTransform: "none" as const,
            "&:hover": {
                background: 'none'
            }
        }
    },
    "@media(max-width: 991px)":{
        width: "100%",
        "& .profileQRModal":{
            padding: "1em",
            height: "calc(100vh - 140px)",
        }
    }
})
// Customizable Area End