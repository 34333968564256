import React from "react";
// Customizable Area Start
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { gear } from "./assets";
import PackagingDetailsController, { Props } from "./PackagingDetailsController.web";
export const configJSON = require("./config");
// Customizable Area End

export class PackagingDetails extends PackagingDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const styles = this.props.classes;
        return (
            <Box className={styles.package_Main_Container}>
                <Typography className={styles.package_Heading}>{configJSON.packagingDetails}</Typography>
                <Box className={styles.shipment_Form_Container}>
                    <form data-testid="submitButtonTestId" className={styles.package_Form_Main_Container} onSubmit={this.handlePackageDetailsSubmit}>
                        <TextField
                            data-testid="packageWeightTestId"
                            id="outlined-required"
                            error={this.state.packageWeightRequire}
                            helperText={this.state.packageWeightRequire ? configJSON.packagingDetailsError : ""}
                            onChange={this.handlePackageWeight}
                            className={styles.formControl_Option_TextBox}
                            label="Package Weight"
                            placeholder="Kg"
                            variant="outlined"
                            value={this.state.packageWeight}
                        />
                        <TextField
                            data-testid="packageLenghtTestId"
                            id="outlined-required"
                            error={this.state.packageLenghtRequire}
                            helperText={this.state.packageLenghtRequire ? configJSON.packagingDetailsError : ""}
                            onChange={this.handlePackageLenght}
                            className={styles.formControl_Option_TextBox}
                            label="Package Length"
                            placeholder="CM"
                            variant="outlined"
                            value={this.state.packageLenght}
                        />
                        <TextField
                            data-testid="packageBreadthTestId"
                            id="outlined-required"
                            error={this.state.packageBreadthRequire}
                            helperText={this.state.packageBreadthRequire ? configJSON.packagingDetailsError : ""}
                            onChange={this.handlePackageBreadth}
                            className={styles.formControl_Option_TextBox}
                            label="Package Breadth"
                            placeholder="CM"
                            variant="outlined"
                            value={this.state.packageBreadth}
                        />
                        <TextField
                            data-testid="packageHeightTestId"
                            id="outlined-required"
                            error={this.state.packageHeightRequire}
                            helperText={this.state.packageHeightRequire ? configJSON.packagingDetailsError : ""}
                            onChange={this.handlePackageHeight}
                            className={styles.formControl_Option_TextBox}
                            label="Package Height"
                            placeholder="CM"
                            variant="outlined"
                            value={this.state.packageHeight}
                        />
                        <Typography className={styles.formControl_Charges_Heading}>{configJSON.taxDetails}</Typography>
                        <TextField
                            data-testid="packageHSNTestId"
                            id="outlined-required"
                            error={this.state.packageHSNRequire}
                            helperText={this.state.packageHSNRequire ? configJSON.packagingDetailsError : ""}
                            onChange={this.handlePackageHSN}
                            className={styles.formControl_Option_TextBox}
                            label="HSN"
                            variant="outlined"
                            value={this.state.packageHSN}
                        />
                        <Box className={styles.formControl_Middle_Heading_Container}>
                            <img src={gear} alt="gear" className={styles.gear} />
                            <Typography className={styles.formControl_Middle_Heading}>{configJSON.copy} <span className={styles.hsnCode}>{configJSON.hsnCode}</span> {configJSON.codeDetails}</Typography>
                        </Box>
                        <TextField
                            data-testid="packageTaxTestId"
                            id="outlined-required"
                            error={this.state.packageTaxRequire}
                            helperText={this.state.packageTaxRequire ? configJSON.packagingDetailsError : ""}
                            onChange={this.handlePackageTax}
                            className={styles.formControl_Option_TextBox}
                            label="Tax Code"
                            variant="outlined"
                            value={this.state.packageTax}
                        />
                        <Box className={styles.formControl_Button_Container}>
                            <Button type='submit' className={styles.formControl_Button}>{configJSON.save}</Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const styles = () =>
    createStyles({
        package_Main_Container: {
            padding: "40px 60px 70px 55px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "25px",
            flexDirection: "column",
            "@media (max-width: 560px)": {
                padding: "40px 24px 70px 24px",
            }
        },
        shipment_Form_Container: {
            width: "100%"
        },
        package_Form_Main_Container: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "26px",
            flexDirection: "column",
        },
        package_Heading: {
            color: "#555",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
        },
        formControl_Option_TextBox: {
            width: "50vw",
            height: "56px",
            color: "#444",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            '& .MuiInputBase-root': {
                '&.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '8px',
                    },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderRadius: '8px',
                },
            },
            "@media (max-width: 1000px)": {
                width: "100%",
            }
        },
        formControl_Middle_Heading_Container: {
            display: "flex",
            gap: "5px",
        },
        formControl_Middle_Heading: {
            color: "rgba(68, 68, 68, 0.70)",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "normal",
        },
        formControl_Charges_Heading: {
            fontFeatureSettings: "'clig' off, 'liga' off",
            color: "#555",
            fontSize: "16px",
            fontFamily: "Poppins",
            fontWeight: 600,
            fontStyle: "normal",
            lineHeight: "normal",
        },
        hsnCode: {
            textDecorationLine: "underline",
            fontWeight: 500,
        },
        formControl_Button: {
            height: "48px",
            padding: "0 10%",
            background: "#F5EA16",
            width: "fit-content",
            display: "flex",
            borderRadius: "30px",
            alignItems: "center",
            justifyContent: "center",
            fontFeatureSettings: "'clig' off, 'liga' off",
            color: "#555",
            fontSize: "16px",
            fontFamily: "Poppins",
            fontWeight: 500,
            fontStyle: "normal",
            cursor: "pointer",
            textTransform: "unset",
            lineHeight: "normal",
            "@media (max-width: 560px)": {
                padding: "0 6%",
            },
            "&:hover": {
                background: "#F5EA16",
            },
        },
        formControl_Button_Container: {
            display: "flex",
            width: "100%",
            "@media (max-width: 560px)": {
                justifyContent: "center",
            },
            justifyContent: "end",
        },
        gear: {
            width: "16px",
            height: "16px"
        }
    });
export default withStyles(styles)(PackagingDetails)
// Customizable Area End