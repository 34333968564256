import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    Box,
    Typography,
    styled,
    Button,
    Checkbox,
    IconButton
} from "@material-ui/core";
import { 
    liveEyeImg, 
    liveClockImg, 
    liveDotsImg, 
    liveMicImg, 
    liveVideoImg, 
    livePauseImg, 
    liveEndImg, 
    liveFullScreenImg, 
    plusNormalImg, 
    searchModalIcon, 
    deleteImg, 
    inventoryImg, 
    threeDotIC, 
    editImg, 
    profileImage, 
    liveChatEmogImg, 
    hamburgerIcon 
} from "./assets";

const theme = createTheme({});
import { 
    IAgoraRTCRemoteUser 
} from 'agora-rtc-sdk-ng';
export const configJSONBase = require("../../../framework/src/config.js");
// Customizable Area End

import GoLiveScreenController, {
    LiveInventoryListItem,
    Props,
    configJSON,
} from "./GoLiveScreenController.web";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";

export default class GoLiveScreen extends GoLiveScreenController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLocalUser = () => (
        <div>
            {this.localTracks.videoTrack && this.state.cameraOn && (
                <video
                data-testid="remote-user-video"
                    autoPlay
                    playsInline
                    
                />
            )}
            {this.localTracks.audioTrack && this.state.micOn && (
                <audio
                data-testid="remote-audio"
                    autoPlay
                    
                />
            )}
        </div>
    );

    renderRemoteUser = (user:IAgoraRTCRemoteUser) => (
        <div data-testid="render-remote-user">
            {user.videoTrack && (
                <video
                data-testid="renderremote-video"
                    autoPlay
                    playsInline
                    
                />
            )}
            {user.audioTrack && (
                <audio
                data-testid="renderremote-user-audio"
                    autoPlay
                    
                />
            )}
            </div>
    );
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box sx={webStyle.liveStreamingMainContainer}>
                    <Box sx={webStyle.liveStreamingSideMenu}>
                        <div className="dashboard-outer">
                            <div className="dashboard-inner">
                                <SellerSidebar
                                    navigation={"/SellerDashboard"}
                                    id={""}
                                    classes={undefined}
                                    isOpen={this.state.isSideBarOpen}
                                    data-test-id="sellerSidebar"
                                    activetabName="create"
                                    closeModal={() => this.handleSideBar()}
                                    data-testid="isOpenTestId"
                                />
                            </div>
                        </div>
                    </Box>
                    <MainContainer>
                    <Box className="hideSideBarHamburgerSection">
                                         <IconButton
                                             data-test-id="hamburger-btn"
                                             onClick={() => this.handleSideBar()}
                                         >
                                             <img src={hamburgerIcon} alt="hamburger" />
                                         </IconButton>
                     </Box>
                        <Typography className="testModeTxt">{configJSON.testMode}</Typography>
                        {this.state.isModalOpen && 
                            <StyledModal
                            data-test-id="modalID"
                        >
                        <Box className="liveModalOverlay" onClick={()=>this.handleModalClose("cancel")} data-test-id="modalOverlayID" />
                            <ModalBody>
                                <Typography className="endLiveModalTitle" data-test-id="endModalTxtID">{configJSON.endLiveStreamModalTitle}</Typography>
                                <Typography className="endLiveModalDesc">{configJSON.endLiveStreamModalDesc}</Typography>
                                <Box className="endLiveModalButtonBox">
                                    <Button className="endLiveModalCancelBtn" variant="text" disableElevation onClick={()=>this.handleModalClose("cancel")} data-test-id="cancelBtn">{configJSON.cancel}</Button>
                                    <Button data-testid="leave-channel-btn" className="endLiveModalEndBtn" variant="text" disableElevation onClick={() => {
                                            this.handleModalClose("delete")
                                            this.leaveChannel();
                                    }
                                    } data-test-id="deleteBtn">{configJSON.endStream}</Button>
                                </Box>
                            </ModalBody>
                        </StyledModal>
                        }
                        
                        <Box className="contentContainer">
                            <Box className="leftContainer">
                            <div className="liveContainer" ref={this.videoContainerRef} id="local-stream">
                                    {
                                        this.state.isPaused ? 
                                        <img 
                                        src={this.state.pausedImage} 
                                        alt="paused" className="pausedImg"/>
                                        :
                                    <div className="user-list">
                                        <div className="liveStreamingUser">
                                         {
                                            this.renderLocalUser()
                                         }
                                                
                                        </div>
                                        {this.state.remoteUsers.map((user) => (
                                            <div className="user" 
                                            key={user.uid}>
                                                {
                                                    this.renderRemoteUser(user)
                                                }
                                            </div>
                                        ))}
                                    </div>
                                     }
                                    <Box className="liveImgTop">
                                        <Typography>{configJSON.livePreview}</Typography>
                                        <Box className="liveImgTopRight">
                                            <Button disableElevation>{configJSON.live}</Button>
                                            <img src={liveEyeImg} alt="eye icon" />
                                            <Typography>{configJSON.liveCount}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="livePurchased">
                                        <img src={inventoryImg} alt="purchased item" />
                                        <Box className="livePurchasedDetails">
                                            <Typography className="livePurchasedItemName">{configJSON.livePurchasedItemName}</Typography>
                                            <Typography className="livePurchasedItemCustomer">{configJSON.livePurchasedItemCustomer}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="liveImgBottom">
                                        <Box className="liveImgBottomLeft">
                                            <img src={liveClockImg} alt="clock icon" />
                                            <Typography>{configJSON.liveTime}</Typography>
                                        </Box>
                                        <Box className="liveImgBottomCenter">
                                            <img src={liveDotsImg} alt="dots icon" />
                                            <img src={liveMicImg} alt="mic icon" onClick={this.toggleMic} data-test-id="micID" />
                                            <img src={liveVideoImg} alt="video icon" onClick={this.togglePause} data-test-id="cameraID" />
                                            <img src={livePauseImg} alt="pause icon" />
                                            <img src={liveEndImg} alt="end icon" onClick={this.handleModalOpen} data-test-id="endLiveImgID" />
                                        </Box>
                                        <img className="fullScreenImg" src={liveFullScreenImg} alt="full screen icon" onClick={this.toggleFullscreen} data-test-id="fullScreenID"/>
                                    </Box>

                                </div>
                                <LiveInventoryContainer>
                                    <Box className="liveInentoryTop">
                                        <Typography data-test-id="inventoryTxtID">{configJSON.inventoryTxt}</Typography>
                                        <img src={plusNormalImg} alt="plus normal" />
                                    </Box>
                                    <Box className="liveInventorySearchBox">
                                        <img src={searchModalIcon} alt="search" />
                                        <input type="text" placeholder={configJSON.inventorySearchPlacholder} value={this.state.searchInventoryProducts} onChange={this.handleInventoryProductSearch} data-test-id="searchID" />
                                    </Box>
                                    <table className="liveInventoryTable">
                                        <thead>
                                            <tr>
                                                <th>{configJSON.title}</th>
                                                <th>{configJSON.price}</th>
                                                <th>{configJSON.customer}</th>
                                                <th>
                                                    <img src={deleteImg} alt="delte" />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.liveInventoryList.map((liveInventory, index) => (
                                                    <tr key={liveInventory.catalogue_id}>
                                                        <td>
                                                            <Box className="liveInventoryTableTitle">
                                                                <Checkbox />
                                                                <img src={configJSONBase.baseURL + liveInventory.images[0].url} alt="product" />
                                                                <Box className="liveInventoryTableTitleDetails">
                                                                    <Typography>{liveInventory.catalogue_title}</Typography>
                                                                    <Typography className="liveInventoryTableTitleDetailsProductID">{liveInventory.catalogue_id}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </td>
                                                        <td>{liveInventory.catalogue_selling_price}</td>
                                                        <td>{liveInventory.customerName}</td>
                                                        <td>
                                                            <img src={threeDotIC} alt="three dots" />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </LiveInventoryContainer>
                            </Box>
                            <Box className="rightContainer">
                                <Box className="liveInfoContainer">
                                    <Box className="liveInfoTop">
                                        <Typography>{configJSON.liveInfo}</Typography>
                                        <img src={editImg} alt="edit" />
                                    </Box>
                                    <Box className="liveInfoBottom">
                                        <Box className="profileImgBox">
                                            <img src={profileImage} alt="live avatar" />
                                        </Box>
                                        <Box className="liveInfoDetails">
                                            <Typography className="liveStreamID">{configJSON.liveStreamId}</Typography>
                                            <Typography className="liveStreamDesc">{configJSON.liveInfoDesc}</Typography>
                                            <Box className="liveInfoHashTags">
                                                <Button variant="outlined" disableElevation>{configJSON.cameraHash}</Button>
                                                <Button variant="outlined" disableElevation>{configJSON.nikonHash}</Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="liveChatBox">
                                    <Typography className="noChat">{configJSON.noChat}</Typography>
                                </Box>
                                <Box className="chatInputBox">
                                    <input type="text" placeholder={configJSON.liveChatPlaceholder} />
                                    <img src={liveChatEmogImg} alt="emoji" />
                                </Box>
                            </Box>
                        </Box>
                    </MainContainer>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainContainer = styled(Box)({
    width: "100%",
    padding: "0 24px 40px 24px",
    position: "relative",
    "& .hideSideBarHamburgerSection":{
        display: "none",
        "@media(max-width: 991px)":{
            display: "block",
            position: "absolute",
            top: "70px",
            right: "18px"
        }
    },
    "& .testModeTxt": {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        color: "#fff",
        height: "34px",
        textAlign: "center",
        lineHeight: "34px",
        borderRadius: "0 0 8px 8px",
        margin: "0 auto 0 auto",
        width: "290px",

    },
    "@media(max-width:991px)":{
        marginTop: "-50px"
    },
    "& .contentContainer": {
        display: "flex",
        gap: "20px",
        marginTop: "40px",
        "@media(max-width:1230px)": {
            flexDirection: "column"
        }
    },
    "& .leftContainer": {
        width: "100%",
        maxWidth: "655px",
        "@media(max-width:1230px)": {
            maxWidth: "unset",
        },
        "& .liveContainer": {
            position: "relative",
            marginBottom: "30px",
            height: "436px",
            borderRadius: "10px",
            overflow: "hidden",
            "& video":{
                height: "100%",
                width: "100%"
            },
            "& > img": {
                width: "100%"
            },
            "& .liveImgTop": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "absolute",
                top: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "90%",
                color: "#fff",
                "& .liveImgTopRight": {
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    "& button": {
                        backgroundColor: "rgba(236, 32, 36, 1)",
                        width: "99px",
                        height: "24px",
                        borderRadius: "10px",
                        "& .MuiButton-label": {
                            color: "#fff"
                        }
                    }
                }
            },
            "& .livePurchased": {
                display: "flex",
                gap: "16px",
                position: "absolute",
                bottom: "80px",
                left: "35",
                backgroundColor: "#fff",
                borderRadius: "10px",
                padding: "20px",
                "& img": {
                    width: "46px",
                    height: "48px"
                },
                "& .livePurchasedDetails": {
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "18px",
                    "& .livePurchasedItemName": {
                        color: "rgba(68, 68, 68, 1)",
                    },
                    "& .livePurchasedItemCustomer": {
                        color: "rgba(68, 68, 68, 0.7)",
                    },
                }
            },
            "& .liveImgBottom": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                position: "absolute",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "90%",
                "& .liveImgBottomLeft": {
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    color: "#fff"
                },
                "& .liveImgBottomCenter": {
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    "& img": {
                        cursor: "pointer"
                    }
                },
                "& .fullScreenImg": {
                    width: "27px",
                    height: "27px",
                    cursor: "pointer",
                    alignSelf: "center"
                }
            }
        }
    },
    "& .rightContainer": {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        "& .liveInfoContainer": {
            boxShadow: "0 0 10px rgba(0,0,0,0.4)",
            borderRadius: "10px",
            padding: "20px",
            "& .liveInfoTop": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px"
            },
            "& .liveInfoBottom": {
                display: "flex",
                alignItems: "flex-start",
                gap: "20px",
                "& .profileImgBox": {
                    width: "40px",
                    height: "40px",
                    padding: "2px",
                    boxSizing: "border-box",
                    borderRadius: "40px",
                    background: "linear-gradient(rgba(236, 32, 36, 1), rgba(217, 199, 33, 1))",
                    "& img": {
                        width: "100%",
                        height: "100%"
                    }
                },
                "& .liveInfoDetails": {
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    fontSize: "14px",
                    lineHeight: "21px",
                    fontWeight: 500,
                    "& .liveStreamID": {
                        color: "rgba(85, 85, 85, 0.7)",
                    },
                    "& .liveStreamDesc": {
                        color: "rgba(85, 85, 85, 1)"
                    },
                    "& .liveInfoHashTags": {
                        display: "flex",
                        gap: "20px",
                        marginTop: "20px",
                        "& button": {
                            height: "24px",
                            borderRadius: "10px",
                            "& .MuiButton-label": {
                                color: "rgba(85, 85, 85, 0.7)",
                                textTransform: "capitalize"
                            }
                        }
                    }
                }
            }
        },
        "& .liveChatBox": {
            boxShadow: "0 0 10px rgba(0,0,0,0.4)",
            borderRadius: "10px",
            padding: "20px",
            position: "relative",
            boxSizing: "border-box",
            flex: 1,
            "@media(max-width: 1230px)": {
                minHeight: "400px"
            },
            "& .noChat": {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "rgba(68, 68, 68, 0.4)",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "21px",
                width: "max-content"
            }
        },
        "& .chatInputBox": {
            display: "flex",
            alignItems: "center",
            gap: "20px",
            boxShadow: "0 0 10px rgba(0,0,0,0.4)",
            padding: "20px",
            borderRadius: "10px",
            "& input": {
                border: "none",
                outline: "none",
                flex: 1
            }
        }
    },
    "@media (max-width: 991px)": {
        padding: "50px 24px"
    },
    "@media (max-width: 550px)": {
        padding: "50px 3% 50px"
    }
});

const LiveInventoryContainer = styled(Box)({
    boxShadow: "0 0 10px rgba(0,0,0,0.4)",
    borderRadius: "10px",
    padding: "20px",
    "& .liveInentoryTop": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "30px"
    },
    "& .liveInventorySearchBox": {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        border: "1px solid rgba(68, 68, 68, 0.1)",
        height: "32px",
        borderRadius: "10px",
        paddingLeft: "20px",
        marginBottom: "20px",
        "& input": {
            border: "none",
            outline: "none",
            flex: 1
        },
    },
    "& .liveInventoryTable": {
        width: "100%",
        "& th": {
            textAlign: "left"
        },
        "& th:last-child": {
            textAlign: "right"
        },
        "& .liveInventoryTableTitle": {
            display: "flex",
            alignItems: "center",
            gap: "12px",
            "& .MuiCheckbox-root": {
                padding: 0
            },
            "& .MuiCheckbox-colorSecondary.Mui-checked": {
                color: "rgba(85, 85, 85, 1)"
            },
            "& img": {
                width: "50px",
                height: "40px",
                borderRadius: "10px"
            },
            "& .liveInventoryTableTitleDetails": {
                "& .liveInventoryTableTitleDetailsProductID": {
                    color: "rgba(85, 85, 85, 0.5)",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "18px"
                }
            }
        }
    }
})
const webStyle = {
    liveStreamingMainContainer: {
        display: "flex",
        marginTop: "93px",
        "@media (max-width: 1399px)": {
            marginTop: "70px"
        }
    },
    liveStreamingSideMenu: {
        marginTop: "0px",
        "@media (max-width: 991px)": {
            marginTop: "20px"
        }
    }
};
const ModalBody = styled(Box)({
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "40px 20px",
    boxSizing: "border-box",
    maxWidth: "526px",
    width: "calc(100% - 60px)",
    backgroundColor: "#fff",
    borderRadius: "20px",
    "& .endLiveModalTitle": {
        color: "rgba(85, 85, 85, 1)",
        fontSize: "20px",
        lineHeight: "30px"
    },
    "& .endLiveModalDesc": {
        color: "rgba(85, 85, 85, 0.7)",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        marginTop: "20px"
    },
    "& .endLiveModalButtonBox": {
        textAlign: "right",
        marginTop: "40px",
        "& button": {
            height: "48px",
            width: "125px",
            borderRadius: "10px",
            "& .MuiButton-label": {
                textTransform: "capitalize",
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "24px"
            }
        },
        "& .endLiveModalCancelBtn":{
            "& .MuiButton-label":{
                color: "rgba(68, 68, 68, 1)",
            }
        },
        "& .endLiveModalEndBtn":{
            "& .MuiButton-label":{
                color: "rgba(228, 98, 98, 1)",
            }
        }
    }
});

const StyledModal = styled(Box)({
    position: "fixed",
    top: "70px",
    right: 0,
    width: "calc(100% - 290px)",
    height: "calc(100vh - 70px)",
    zIndex: 2,
    "@media(max-width: 991px)":{
        width: "100%"
    },
    "& .liveModalOverlay":{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
})
// Customizable Area End
