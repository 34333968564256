import React from "react";
// Customizable Area Start
import InstantGoLiveController,{Props, configJSON, Catalogue} from "./InstantGoLiveController.web";
import { createTheme,  withStyles, Theme ,createStyles} from "@material-ui/core/styles";
import {Box,IconButton,Typography,Grid,TextField,MenuItem,Switch,Button, styled, Checkbox, RadioGroup, Radio, FormControlLabel} from "@material-ui/core";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import {hamburgerIcon,backButton,notificationIc,calendarIc,removeTagIcon, infoIcon, searchModalIcon, profileImage, filterImg, minusImg, plusImg, plusSquareImg, filledCheckImg, disableMinusImg, checkImg} from "./assets";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import Loader from "../../../components/src/Loader.web"
let config = require('../../../framework/src/config')


const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    instantGoliveContainer:{
        display:"flex",
        margin:"5em 0em 0em"
    },
    instantGoliveInnerContainer:{
        paddingTop:"4%",
        width:"100%",
        padding:"0em 2em",
    },
  
    hideSideBarHamburgerSection:{
        display:"flex",
        justifyContent:"end" as const
    },
    pageTitle:{
        color: 'rgba(68, 68, 68, 0.80)',
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        marginLeft:"0.5em"
    },
    headerIcons:{
        width: '48px',
        height: '48px',
        borderRadius: '10px',
        border: '1px solid var(--0, #FFF)',
        background: 'var(--0, #FFF)',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        display:"flex",
        alignItems:"center"  as const,
        justifyContent:"center"  as const,
        margin:"0em 0.5em",
        flexShrink: 0
    },
    customTextField:{
        height:"3.5em",
        margin:"0.75em 0em",
        borderRadius:"15px",
        "& .MuiOutlinedInput-root":{
            borderRadius:"10px"
        }
    },
    customTextArea: {
        height: "165px",
        "& .MuiInputBase-root": {
          height: "100% !important",
           borderRadius:"10px",
          "& .MuiInputBase-input": {
            overflow: "auto !important",
            height: "100% !important",
          },
        },
    },
    tagsContainerInner: {
        marginBottom: "10px",
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        padding: "4px 10px",
        border: "1px solid #F5EA16",
        borderRadius: "20px",
        backgroundColor: "rgba(245, 234, 22, 0.10);"
     },
    tagText: {
        color: "#555",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        opacity: 0.6
    },
    tagClodeBtn: {
        width: "8px",
        height: "8px",
        cursor: "pointer"
    },
    addBtnStyle: {
        color: "#555",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        cursor: "pointer"
    },
    tagsContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start", 
         gap: "5px",
        flexWrap: "wrap" as const
    },
    addOrRemoveBtn: {
        color: "#555",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        cursor: "pointer",
        textDecoration: "underline",
        marginLeft: "auto"
    },
    moderatorAndCollaboratorContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "5px",
        width: "100%",
        height: "66px",
        color: "#444",
        border: "1px solid #44444480",
        margin:"0.75em 0em",
        borderRadius:"10px",
        "@media (max-width: 560px)": {
            width: "100%",
        }
    },
    moderatorAndCollaboratorLabel: {
        color: "#44444480",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "1.1876em",
        letterSpacing: "0.00938em",
        marginLeft: "16px",
        padding: "5px"
    },
    moderatorAndCollaboratorMainSection: {
        display: "flex",
        gap: "10px",
        height: "100%",
        marginTop: "-2px",
    },
    moderatorAndCollaboratorInnerSection: {
        display: "flex",
        gap: "4px",
        width: "fit-content"
    },
    imageSection: {
        border: "1px solid gray",
        width: "31px",
        borderRadius: "50%"
    },
    nameSection: {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
        alignItems: "flex-start" as const,
    },
    invitedText: {
        color: "#444",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "9px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    },
    invitedName: {
        color: "#444",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "9px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
    },
     settingsContainer:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginBottom:"1em",
        "& .MuiSwitch-switchBase": {
            top: "50%",
            left: "3px",
            transform: "translateY(-50%)"
          },
          "& .MuiSwitch-root": {
            width: "49px",
            height: "27px",
            padding: 0
          },
          "& .MuiSwitch-thumb": {
            width: "20px",
            height: "20px"
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translate(20px, -50%)"
          }
          
     },
     settingsTitle:{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
         margin:"0.2em 0em"
     },
     privateEventDescription:{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal'
     },
     settingsDescription:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        margin:"0.2em 0em"
     },
     contentSettingsHeading:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        margin:"1em 0em"
     },
     addedMutedWords: {
        color: "#555",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        cursor: "pointer"
    },
    addedMutedWordsContainer: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "5px",
        flexWrap: "wrap" as const
    },
    eachMutedWordContainer: {
        marginBottom: "10px",
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        padding: "4px 10px",
        border: "1px solid #F5EA16",
        borderRadius: "20px",
        backgroundColor: "rgba(245, 234, 22, 0.10)"
    },
    mutedWordText: {
        color: "#555",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        opacity: 0.6
    },
    mutedWordCloseBtn: {
        width: "8px",
        height: "8px",
        cursor: "pointer"
    },
    saveAndButton:{
        width: '30%',
        height: '3em',
        background: '#F5EA16',
        borderRadius:"25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer:"cursor",
        textTransform:"none" as const,
        "&:hover":{
            background: '#F5EA16',
        }
    },
    skipBtn:{
        width: "30%",
        height: "3em",
        textTransform:"none" as const,
        borderRadius:"25px",
    },
    saveAndButtonContainer:{
        display:"flex",
        flexDirection: "column" as const,
        alignItems: "flex-end",
        gap: "8px",
        width:"100%",
         marginBottom:"2em",
        [theme.breakpoints.down('md')]: {
          margin:"1em"
        },
    },
    spaceBetween:{
        display:"flex",
        justifyContent:"space-between"
    },
    backBtnNavigation:{
        display:"flex",
        alignItems:"center"
    },
    checkedInventory:{
        color: "rgba(68, 68, 68, 0.7)",
        fontSize: "14px",
        marginLeft: "0.5em"
    },
    flexContainer:{
      display:"flex"
    }, 
    space3:{
        marginTop:"2em",
        "& .noInventoryStyle":{
            height: "calc(100vh - 186px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        "& .MuiOutlinedInput-input":{
            paddingLeft: "34px"
        },
        "& .MuiOutlinedInput-inputMultiline":{
            paddingLeft: "20px !important"
        },
        "& .MuiInputLabel-formControl":{
            left: "20px"
        },
        "& .MuiOutlinedInput-notchedOutline":{
            paddingLeft: "28px"
        }
    },
    menuItem:{
        borderBottom:"1px rgba(68, 68, 68, 0.10) solid"
    },
    testModeSection:{
       display:'flex',
       justifyContent:"center",
       marginTop:"-26px",
       marginBottom: "20px",
       "@media(max-width: 991px)":{
        marginTop: "-50px"
       }
    },
    testMode:{
        width: "291px",
        height: "34px",
        borderRadius: '0px 0px 8px 8px',
        background: 'rgba(0, 0, 0, 0.50)',
        backdropFilter: 'blur(2px)',
        display:'flex',
        justifyContent:"center",
        color:"#FFF"
    },
    [`@media (min-width: 992px)`]: {
        hideHamburger: {
          display: 'none' 
        },
        instantGoliveInnerContainer:{
            paddingTop:"2%",
            width:"100%",
            padding:"0em 2em",
        },
        testModeSection:{
            display:'flex',
            justifyContent:"center",
            marginTop:"-37px",
         },

    },
    [`@media (min-width: 1400px)`]: {
        testModeSection:{
            display:'flex',
            justifyContent:"center",
            marginTop:"-13px",
         },

    },

});

export const RadioButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#FFFF00', 
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        backgroundColor: 'black', 
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
      backgroundColor: 'black !important', 
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


// Customizable Area End

export class InstantGoLive extends InstantGoLiveController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderModalList = () => {
        switch (this.state.liveStreamModal) {
            case 1: 
            case 2:
            return <>
                <ModalList>
                    {
                        this.state.buyersList.map((buyer: { [key: string]: string }) => (
                            <li key={buyer.userName}>
                                <img src={profileImage} alt="profile" />
                                <Box className="modalBuyerInfo">
                                    <Typography className="modalBuyerUsername">{buyer.userName}</Typography>
                                    <Typography className="modalBuyerName">{buyer.name}</Typography>
                                </Box>
                                <Checkbox />
                            </li>
                        ))
                    }
                </ModalList>
                <ModalButton>
                    <Button variant="contained" disableElevation>
                        {configJSON.inviteTxt}
                    </Button>
                    <Button variant="text" disableElevation className={this.props.classes.skipBtn}>
                        {configJSON.skipTxt}
                    </Button>
                </ModalButton>
            </>
            case 3: return <>
                <ModalList>
                    {
                        !!this.state.checkedInventoryList.length && this.state.checkedInventoryList.map((inventory: Catalogue) => (
                            <li key={inventory.id}>
                                <Box className="inventoryModalImg">
                                    <img src={config.baseURL + inventory.attributes.product_images[0]?.url} alt={inventory.attributes.title} />
                                </Box>
                                <Box className="modalBuyerInfo">
                                    <Typography className="modalBuyerUsername">{inventory.attributes.title}</Typography>
                                    <Typography className="modalBuyerName">{inventory.attributes.description}</Typography>
                                </Box>
                                <Box className="inventoryActionContainer">
                                    <Typography component={"span"} className="inventoryCount">{`${inventory.attributes.inventory_details.itemCount}/${inventory.attributes.inventory_details.stock_quantity}`}</Typography>
                                    <Box className="inventoryAction">
                                        <img src={inventory.attributes.inventory_details.itemCount > 0 ? minusImg : disableMinusImg} alt="minus" onClick={()=> this.updateLiveStreamingInventory(inventory.id, 0)} data-test-id="minusImgID" />
                                        <span className="inventoryCountAction" data-test-id="inventoryItemCountID">{inventory.attributes.inventory_details.itemCount}</span>
                                        <img src={plusImg} alt="plus" onClick={()=> {
                                            inventory.attributes.inventory_details.itemCount < inventory.attributes.inventory_details.stock_quantity && 
                                            this.updateLiveStreamingInventory(inventory.id, 1)
                                            
                                            }} data-test-id="plusImgID"/>
                                    </Box>
                                </Box>
                            </li>
                        ))
                    }
                </ModalList>
                <ModalButton>
                    <Button variant="contained" disableElevation onClick={this.postAddToLiveApi} data-test-id="addToLiveBtnID">
                        {configJSON.addToLive}
                    </Button>
                    <Button variant="text" disableElevation onClick={this.goToGoLiveScreen}>
                        {configJSON.skipTxt}
                    </Button>
                </ModalButton>
            </>
            default:
                return;

        }
    }
    renderLiveStreamModal = () => {
        const { privateLiveEventModalTxt: { searchPlaceholder } } = configJSON;
        return <>{ Boolean(this.state.liveStreamModal) && <StyledModal>
            <Box className="liveModalOverlay" onClick={this.handleModalClose} data-test-id="modalOverlayID" />
            <ModalBody>
                {
                    this.state.liveStreamModal === 4 ?

                        <Box className="inventorySuccess">
                            <img src={filledCheckImg} alt="filled check" />
                            <Typography className="inventorySuccessTitle" data-test-id="modalSuccessTxtID">{this.state.liveModalTitle}</Typography>
                            <Typography className="inventorySuccessSubTitle">{this.state.liveModalSubTitle}</Typography>
                        </Box>
                        :
                        <>
                            <ModalHeader>
                                {
                                    (this.state.liveStreamModal === 1 || this.state.liveStreamModal === 2) && 
                                    <img src={backButton} alt="back" onClick={this.handleModalClose} data-test-id="backArrowID"/>
                                }
                                <Typography className="modalTitle modalTitleOption" data-test-id="inventoryModalTxtID">{this.state.liveModalTitle}</Typography>
                            </ModalHeader>
                            <ModalHeader>
                                {
                                    (this.state.liveStreamModal === 1 || this.state.liveStreamModal === 2 || this.state.liveStreamModal === 3) &&
                                    <img src={infoIcon} alt="info" />
                                }
                                <Typography className="modalDesc">{this.state.liveModalSubTitle}</Typography>
                            </ModalHeader>
                            {
                                this.state.liveStreamModal === 1 || this.state.liveStreamModal === 2 && 
                            <ModalSearch>
                                <img src={searchModalIcon} alt="search" />
                                <input type="text" placeholder={searchPlaceholder} />
                            </ModalSearch>
                            }
                            {this.renderModalList()}
                        </>
                }

            </ModalBody>
        </StyledModal>
            }
            </>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const {classes} = this.props
        const {loader,testLive} = this.state
        return (
            <Box className={classes.instantGoliveContainer} >
                <MessageModalWeb displayPopup={this.state.displayPopup} errorMessage={this.state.errorMessage} closeErrorPopup={this.closeErrorPopup}/>
                {this.renderLiveStreamModal()}
            <div className="dashboard-outer">
                         <div className="dashboard-inner">
                           <SellerSidebar
                             navigation={""}
                             id={""}
                             classes={undefined}
                             isOpen={this.state.isSideBarOpen}
                             data-test-id="sellerSidebar"
                             activetabName= "create"
                             closeModal={this.handleSideBarVisbility}
                             />
                         </div>
             </div>
             <Loader loading={loader} />
             <Box className={classes.instantGoliveInnerContainer} data-test-id="instantGoLiveMainContainer">
                     {testLive && <Box className={classes.testModeSection}><Typography className={classes.testMode}>{configJSON.testMode}</Typography></Box>}
                     <Box className={classes.hideSideBarHamburgerSection}>
                                         <IconButton
                                             className={`${classes.hideHamburger}`}
                                             data-test-id="hamburger-btn"
                                             onClick={() => this.handleSideBarVisbility()}
                                         >
                                             <img src={hamburgerIcon} alt="hamburger" />
                                         </IconButton>
                     </Box>
                     <Box className={classes.spaceBetween}>
                         <Box className={classes.backBtnNavigation} m={0}>
                             <img data-test-id="backButton" src={backButton} alt="backButton" onClick={()=>this.handleBackButton()}/>
                             {
                                this.state.showLiveInventory ? <Box>
                                    <Typography className={classes.pageTitle} data-test-id="inventoryTxtLiveID">{configJSON.inventoryTxt}</Typography>
                                    <Typography className={classes.checkedInventory}>{this.state.checkedInventoryList.length} of {this.state.liveInventoryList?.length}</Typography>
                                </Box> : 
                             <Typography className={classes.pageTitle} data-test-id="inventoryTxtId">{configJSON.instantLive}</Typography> 
                             }
                         </Box>
                         <Box component='span' className={classes.flexContainer}>
                            {
                                this.state.showLiveInventory && 
                         <ModalSearch className="inventorySearch">
                         <img src={searchModalIcon} alt="search" />
                            <input type="text" placeholder={configJSON.inventorySearchPlacholder} onChange={this.handleInventorySearch} value={this.state.inventorySearch} data-test-id="inventorySearchID" />
                            <img src={filterImg} alt="filter" className="inventoryFilter"/>
                            </ModalSearch>
                            }
                            {
                               this.state.showLiveInventory &&  <Box  component="span" className={classes.headerIcons}><img src={plusSquareImg} alt="plus square icon"/></Box>
                                  
                            }
                                 <Box  component="span" className={classes.headerIcons}><img src={calendarIc} alt="calendarIcon"/></Box>
                                 <Box  component="span" className={classes.headerIcons}><img src={notificationIc} alt="bellIcon"/></Box>
                         </Box>
                     </Box>
                     <Box className={classes.space3}>
                        {
                            this.state.showLiveInventory ? 
                            <>
                            {
                                this.state.liveInventoryList.length ? 
                                <InventoryContainer>
                                    <img src={checkImg} alt="check" />
                                    <InventoryList>
                                        {
                                            Boolean(this.state.liveInventoryList.length)  && this.state.liveInventoryList.filter((inventory:Catalogue)=> inventory.attributes?.title?.toLowerCase().includes(this.state.inventorySearch.toLowerCase())).map((inventory: Catalogue) => (
                                                <li key={inventory.id}>
                                                    <StyledCheckBox htmlFor={"check"+inventory.id} className={this.handleCheckboxCheckedStyle(inventory.id) ? "checkedStyle" : ""}>
                                                        <input type="checkbox" id={"check"+inventory.id} onChange={(event)=> this.handleInventoryChecked(event, inventory.id)} data-test-id="inventoryItemCheckboxID" value={inventory.id}/>
                                                        <span></span>
                                                    </StyledCheckBox>
                                                    <Box className="inventoryImage">
                                                    <img src={config.baseURL + inventory.attributes.product_images[0]?.url} alt={inventory.attributes.title} />
                                                    </Box>
                                                    <Box className="inventoryDetails">
                                                        <Typography className="inventoryName"  data-test-id="inventoryTitleID">{inventory.attributes.title}</Typography>
                                                        <Typography className="inventoryDesc">{inventory.attributes.description}</Typography>
                                                    </Box>
                                                    <Typography className="inventoryPrice">₹ {inventory.attributes.mrp}</Typography>
                                                </li>
                                            ))
                                        }
                                    </InventoryList>
                                    <Button variant="contained" onClick={()=> this.handleLiveStreamModal(3, configJSON.inventoryModalTitleTxt, configJSON.inventoryModalSubTitleTxt)} disabled={!Boolean(this.state.checkedInventoryList.length > 0)} disableElevation data-test-id="continueBtnID">{configJSON.continueTxt}</Button>
                                </InventoryContainer>
                            :
                            <Box className="noInventoryStyle">
                                <Typography>{configJSON.noInventoryTxt}</Typography>
                            </Box>
                            }
                            </> 
                            :
                     <Grid container spacing={4}>
                         <Grid item  xs={12} md={12} lg={8}>
                         <TextField
                             fullWidth
                             data-test-id="showTitle"
                             InputLabelProps={{ shrink: true }}
                             label={configJSON.showTitle}
                             variant="outlined" 
                             className={classes.customTextField}
                             value={this.state.showTitle}
                             onChange={this.handleTextFieldChange("showTitle")}
                          />
                          <Box mt={2} mb={2}>
                           <TextField
                             fullWidth
                             data-test-id="description"
                             InputLabelProps={{ shrink: true }}
                             label={configJSON.description}
                             variant="outlined" 
                             className={classes.customTextArea}
                             value={this.state.description}
                             onChange={this.handleTextFieldChange("description")}
                             multiline
                         
                          />
                          </Box>
                          <Box>
                         
                          </Box>
                         <TextField
                             select
                             fullWidth
                             InputLabelProps={{ shrink: true }}
                             data-test-id="primaryCategory"
                             label={configJSON.selectYourPrimaryCategory}
                             value={this.state.primaryCategory}
                             onChange={this.handleSelectFieldChange("primaryCategory")}
                             variant="outlined"
                             className={classes.customTextField}
                             SelectProps={{
                                MenuProps: {
                                  getContentAnchorEl: null,
                                  anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                  transformOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  },
                                },
                              }}
                     >
                         <MenuItem disabled value="default">
                         {configJSON.selectAnyOne}
                         </MenuItem>
                             {this.state.categoriesList?.map(category => (
                                 <MenuItem key={category.id} value={category.id}  data-test-id="categoryItem" className={classes.menuItem}>
                                     {category.attributes.name}
                                 </MenuItem>
                             ))}
                         </TextField>
                         <TextField
                             fullWidth
                             data-test-id="subCategory"
                             label={configJSON.selectYourSubCategory}
                             value={this.state.subCategory}
                             onChange={this.handleSelectFieldChange("subCategory")}
                             variant="outlined"
                             select
                             className={classes.customTextField}
                             disabled={this.state.primaryCategory ==="default"}
                         >
                             <MenuItem disabled value="default">
                             {configJSON.selectAnyOne}
                             </MenuItem>
                             {this.state.subCategoriesList?.map(subCategory => (
                                 <MenuItem key={subCategory.id} value={subCategory.id} data-test-id="subCategoryItem" className={classes.menuItem}>
                                     {subCategory.attributes.name}
                                 </MenuItem>
                             ))}
                         </TextField>
                       
                         
                         <>
                             <TextField
                                 data-test-id="addTag"
                                 fullWidth
                                 label={configJSON.addTagsLabel}
                                 InputLabelProps={{ shrink: true }}
                                 variant="outlined"
                                 value={this.state.addTagsValue}
                                  className={classes.customTextField}
                                 onChange={this.handleAddTagsValue}
                                 InputProps={{
                                     endAdornment: (
                                         <Typography  onClick={this.handleAddTagsIntoList} className={classes.addBtnStyle}>
                                           {configJSON.add}
                                         </Typography>
                                     )
                                 }}
                             />
                             <Box className={classes.tagsContainer}>
                                 {this.state.addTags.map((item, index) => {
                                     return (
                                         <Box key={index} className={classes.tagsContainerInner} data-test-id="eachTag">
                                             <img onClick={() => this.handleRemoveTag(index)} className={classes.tagClodeBtn} src={removeTagIcon} alt="close"  data-test-id={`eachTagCross${index}`}/>
                                             <Typography className={classes.tagText}>{item}</Typography>
                                         </Box>
                                     );
                                 })}
                             </Box>

                         </>

                         <Box>
                         <Box className={classes.moderatorAndCollaboratorContainer} component="fieldset" >
                             <legend className={classes.moderatorAndCollaboratorLabel}>{configJSON.moderators}</legend>
                                      <Typography className={classes.addOrRemoveBtn}>{configJSON.add}</Typography>
                                 </Box>
                                 <Box className={classes.moderatorAndCollaboratorContainer} component="fieldset">
                                     <legend className={classes.moderatorAndCollaboratorLabel}>{configJSON.collaborators}</legend>
                                     <Typography className={classes.addOrRemoveBtn} onClick={()=> this.handleLiveStreamModal(2, configJSON.privateLiveEventModalTxt.title, configJSON.privateLiveEventModalTxt.desc)} data-test-id="collaboratorsAddBtnID" >{configJSON.add}</Typography>
                                 </Box>
                         </Box>

                         
                         </Grid>
                         <Grid item  xs={12} md={12} lg={4}>
                         <Box className={classes.settingsContainer}>
                             <Box>
                             <Typography className={classes.privateEventDescription}>
                                 {configJSON.switchDescriptions.privateEvent}
                             </Typography>
                             </Box>
                             <RadioButton
                             checked={this.state.privateEvent}
                             onChange={this.handleRadioBtnOnandOff("privateEvent", 1)}
                             data-test-id="privateRadioID"
                             />
                         </Box>

                         <Typography className={classes.contentSettingsHeading}>{configJSON.contentSettings}</Typography>

                         <Box className={classes.settingsContainer}>
                             <Box>
                             <Typography className={classes.settingsTitle}>
                                 {configJSON.switchTitles.explicitContent}
                             </Typography>
                             <Typography className={classes.settingsDescription}>
                                 {configJSON.switchDescriptions.explicitContent}
                             </Typography>
                             </Box>
                             <RadioButton
                             checked={this.state.explicitContent}
                             onChange={this.handleRadioBtnOnandOff("explicitContent", 0)}
                            
                             />
                         </Box>
                         <Box className={classes.settingsContainer}>
                             <Box>
                             <Typography className={classes.settingsTitle}>
                                 {configJSON.switchTitles.muteWords}
                             </Typography>
                             <Typography className={classes.settingsDescription}>
                                 {configJSON.switchDescriptions.muteWords}
                             </Typography>
                             </Box>
                             <RadioButton
                             data-test-id="muteWords"
                             checked={this.state.muteWords}
                             onChange={this.handleRadioBtnOnandOff("muteWords", 0)}
                             />
                         </Box>
                         {
                             this.state.muteWords &&
                             <Box>
                                         <TextField
                                             fullWidth
                                             data-test-id="mutewordsField"
                                             InputLabelProps={{ shrink: true }}
                                             label={configJSON.muteWords}
                                             variant="outlined"
                                             value={this.state.mutedWordsValue}
                                             className={classes.customTextField}
                                             onChange={this.handleAddMutedWordsText}
                                             InputProps={{
                                                 endAdornment: (
                                                     <Typography className={classes.addedMutedWords} onClick={this.handleAddMutedWords}>
                                                       {configJSON.add}
                                                     </Typography>
                                                 )
                                             }}
                                         />
                                         <Box className={classes.addedMutedWordsContainer}>
                                             {this.state.muteWordsList.map((item, index) => {
                                                 return (
                                                     <Box key={index} className={classes.eachMutedWordContainer} data-test-id="eachmuteWord">
                                                         <img onClick={() => this.handleCloseMutedWords(index)} 
                                                          className={classes.mutedWordCloseBtn}
                                                          src={removeTagIcon} alt="close"
                                                          data-test-id={`eachMuteWord${index}`}
                                                         />
                                                         <Typography className={classes.mutedWordText}>{item}</Typography>
                                                     </Box>
                                                 )
                                             })}
                                         </Box>
                                     </Box>
                         }


                         </Grid>
                         <Box className={classes.saveAndButtonContainer}> 
                             <Button data-test-id="save" className={classes.saveAndButton} onClick={()=>this.handleSaveButton(false)}>{configJSON.save}</Button>
                             <Button data-test-id="skip"  variant="text" className={classes.skipBtn} disableElevation onClick={()=>this.handleShowLiveInventory()}>
                                 {configJSON.skipTxt}
                             </Button>
                         </Box>
                     </Grid>
                        
                    }
                     </Box>
                     

             </Box>
       </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
const 
ModalBody = styled(Box)({
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "40px 20px",
    boxSizing: "border-box",
    maxWidth: "573px",
    width: "calc(100% - 60px)",
    backgroundColor: "#fff",
    borderRadius: "12px",
    "& .MuiFormControlLabel-label":{
        fontSize: "14px",
        fontWeight: 500
    },
    "& .inventorySuccess":{
        textAlign: "center",
        "& img":{
            marginBottom: "20px"
        },
        "& .inventorySuccessTitle":{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "30px",
            textAlign: "center",
            color: "rgba(85, 85, 85, 1)"
        },
        "& .inventorySuccessSubTitle":{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "24px",
            color: "rgba(85, 85, 85, 0.6)",
            width: "80%",
            margin: "auto"
        }
    },
    "& .MuiButton-contained":{
        backgroundColor: "rgba(245, 234, 22, 1)",
        height: "47px"
    },
    "& .MuiButton-label":{
        textTransform: "capitalize"
    },
    "& button":{
        borderRadius: "40px"
    },
    "& .MuiFormGroup-root":{
        marginTop: "40px",
        gap: "20px"
    },
    "& .MuiRadio-root":{
        color: "rgba(245, 234, 22, 1)"
    },
    "& .MuiFormControlLabel-root":{
        border: "1px solid rgba(245, 234, 22, 1)",
        borderRadius: "10px",
        margin: 0
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked":{
        color: "rgba(245, 234, 22, 1)"
    }
});

const ModalHeader = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "12px",
    marginBottom: "20px",
    "& .modalTitleOption":{
        fontSize: "20px",
        fontWeight: 600
    },
    "& img":{
        cursor: "pointer"
    },
    "& .modalTitle":{
        color: "#444444",
        fontWeight: 500,
        lineHeight: "27px"
    },
    "& .modalDesc":{
        color: "rgba(68, 68, 68, 0.7)",
        fontWeight: 300,
        lineHeight: "21px"
    }
});

const ModalSearch = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "12px",
    backgroundColor: "#fff",
    borderRadius: "12px",
    padding: "12px",
    boxShadow: "0 0 25px rgba(0,0,0,0.2)",
    "&.inventorySearch":{
        width: "482px",
        height: "48px",
        margin: "0 0.5em",
        "@media(max-width: 1200px)":{
            maxWidth: "460px",
            width: "100%"
        }
    },
    "& img":{
        cursor: "pointer"
    },
    "& input":{
        border: "none",
        outline: "none",
        flex: 1
    },
    "& .inventoryFilter":{
        borderLeft: "1px solid rgba(68, 68, 68, 0.1)",
        paddingLeft: "12px"
    }
});

const ModalList = styled("ul")({
    listStyle: "none",
    margin: 0,
    marginTop: "40px",
    padding: "0 20px 0 0",
    maxHeight: "265px",
    overflowY: "auto",
    "& li":{
        display: "flex",
        gap: "12px",
        marginBottom: "20px",
        "& .modalBuyerInfo":{
            flex: 1,
            "& .modalBuyerUsername":{
                color: "rgba(68, 68, 68, 1)",
                fontWeight: 500,
                lineHeight: "21px",
                fontSize: "14px"
            },
            "& .modalBuyerName":{
                color: "rgba(68, 68, 68, 0.5)",
                fontWeight: 400,
                lineHeight: "21px",
                fontSize: "14px"
            }
        },
        "& .inventoryModalImg":{
            backgroundColor: "rgba(246, 246, 246, 1)",
            borderRadius: "8px",
            width: "74px",
            height: "74px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            "& img":{
                width: "48px",
                height: "48px",
                objectFit: "cover"
            },
        },
        "& .inventoryActionContainer":{
            textAlign: "right",
            "& .inventoryCount":{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "21px",
                color: "rgba(78, 78, 78, 0.7)",
            },
            "& .inventoryAction":{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "12px",
                border: "1px solid rgba(68, 68, 68, 0.5)",
                borderRadius: "12px",
                padding: "8px",
                width: "110px",
                position: "relative",
                "& img":{
                    cursor: "pointer",
                    width: "26px",
                    height: "26px"
                },
                "& .inventoryCountAction":{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }
            }
        }
    }
});

const ModalButton = styled(Box)({
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    "& button":{
        display: "block",
        width: "100%"
    },
    "& .goLiveBtn .MuiButton-label":{
        fontSize: "16px",
        fontWeight: 600
    }
});

const InventoryList = styled("ul")({
    margin: 0,
    marginTop: "20px",
    padding: 0,
    "& .custom-checkbox-pseudo input": {
        position: "absolute",
        opacity: 0,
        cursor: "pointer",
      },
      
    "& li":{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        padding: "20px",
        borderRadius: "20px",
        marginBottom: "20px",
        boxShadow: "0 0 25px rgba(0,0,0,0.2)",
        "& .inventoryImage":{
            width: "106px",
            height: "106px",
            borderRadius: "8px",
            boxShadow: "0 0 5px rgba(0,0,0,0.4)",
            padding: "8px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& img":{
                width: "81px",
                height: "84px",
                objectFit: "cover"
            },
        },
        "& .inventoryDetails":{
            flex: 1,
            "& .inventoryName":{
                color: "rgba(68, 68, 68, 1)",
                fontWeight: 500,
                lineHeight: "24px",
                fontSize: "16px"
            },
            "& .inventoryDesc":{
                color: "rgba(68, 68, 68, 0.7)",
                fontWeight: 400,
                lineHeight: "21px",
                fontSize: "14px"
            }
        },
        "& .inventoryPrice":{
                color: "rgba(68, 68, 68, 1)",
                fontWeight: 700,
                lineHeight: "30px",
                fontSize: "20px"
        }
    }
});

const InventoryContainer = styled(Box)({
    paddingBottom: "20px",
    "& img":{
        marginLeft: "auto",
        cursor: "pointer",
        display: "block"
    },
    "& .MuiButton-contained":{
        backgroundColor: "rgba(245, 234, 22, 1)",
        width: "342px",
        display: "block",
        marginLeft: "auto",
        marginTop: "40px",
        borderRadius: "40px",
        height: "47px"
    },
    "& .MuiButton-label":{
        textTransform: "capitalize"
    },
});

const StyledCheckBox = styled("label")({
    border: "1px solid rgba(161, 161, 161, 1)",
    borderRadius: "4px",
    position: "relative",
    "&.checkedStyle":{
        border: "1px solid transparent"
    },
  "& input": {
    visibility: "hidden"
  },
  "& input:checked + span":{
    backgroundColor: "rgba(245, 234, 22, 1)"
  },
  "& input:checked + span::before":{
    content: "''",
    border: "1px solid",
    borderWidth: "0px 2px 2px 0px",
    transform: "rotate(45deg)",
    width: "6px",
    height: "14px",
    position: "absolute",
    top: "2px",
    left: "7px"
  },
  "& span": {
    position: "absolute",
    width: "108%",
    height: "108%",
    top: "-1px",
    left: "-1px",
    borderRadius: "4px"
  }
});

const StyledModal = styled(Box)({
    position: "fixed",
    top: "70px",
    right: 0,
    width: "calc(100% - 290px)",
    height: "calc(100vh - 70px)",
    zIndex: 2,
    "@media(max-width: 991px)":{
        width: "100%"
    },
    "& .liveModalOverlay":{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
})

export default withStyles(webStyles)(InstantGoLive)
// Customizable Area End