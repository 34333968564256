// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

interface Category {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  admin_user_id: number | null;
  rank: number | null;
  light_icon_active: Icon;
  light_icon_inactive: Icon;
  dark_icon: Icon;
  dark_icon_active: Icon;
  dark_icon_inactive: Icon;
  identifier: string | null;
  slug: string;
}

interface SubCategory {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  rank: number | null;
}

interface Brand {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface Account {
  id: number;
  first_name: string | null;
  full_phone_number: string;
  country_code: number;
  phone_number: number;
  email: string;
  activated: boolean;
  device_id: string;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string;
  platform: string | null;
  user_type: string | null;
  app_language_id: number | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  stripe_id: string | null;
  stripe_subscription_id: string | null;
  stripe_subscription_date: string | null;
  role_id: number;
  full_name: string;
  email_verified: boolean;
  phone_verified: boolean;
  business_name: string;
  business_type: string;
  admin_verified: boolean;
  updated_mobile_number: string | null;
  is_disabled: boolean;
  my_bio: string | null;
  updated_email: string | null;
  business_popup: boolean;
  password_digests: [];
  shipment_type: string;
  same_drop_off: boolean;
  marked_destroy: boolean;
  upi_id: string;
  qr_generated: boolean;
  latitude: number | null;
  longitude: number | null;
  current_city: string | null;
}

interface Icon {
  url: string | null;
}

interface Image {
  id: number;
  url: string;
}

interface Specification {
  id: number;
  specification: SpecificationDetail;
  specification_text: string;
}

interface SpecificationDetail {
  id: number;
  specification_type: string;
  name: string;
  sub_category_id: number;
  specification_data: string;
  created_at: string;
  updated_at: string;
}


	export interface InventoryDetails{
		id: number;
		fullfilment_by: string;
		procurement_type: string;
		procurement_sla: number;
		account_id: number;
    itemCount: number;
		stock_quantity: number;
		catalogue_id: number;
		created_at: string;
		updated_at: string
	}

export interface Catalogue {
  id: string;
  type: string;
  attributes: {
    category: Category;
    sub_category: SubCategory;
    brand: Brand;
    title: string;
    description: string;
    status: string;
    mrp: number;
    selling_price: number;
    ask_price: number | null;
    bargain: boolean;
    on_order: boolean;
    is_ask_price: boolean;
    is_brand: boolean;
    tags: string[];
    reviews: string[];
    sku: string;
    account: Account;
    images: Image[] | null;
    host_name: string;
    host_bio: string | null;
    host_image: string;
    product_images: Image[];
    inventory_details: InventoryDetails;
    shipment_charge: number | null;
    packaging_detail: null;
    manufacturing_detail: {
      data: null;
    };
    product_detail: null;
    product_videos: null;
    average_rating: number;
    catalogue_variants: [];
    catalogues_specifications: Specification[];
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
   
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    loader:boolean
    isSideBarOpen:boolean
    showTitle:string,
    description:string
    primaryCategory: string;
    subCategory: string,
    categoriesList:Array<any>
    subCategoriesList:Array<any>
    addTags: string[],
    addTagsValue: string,
    muteWordsList:string[],
    mutedWordsValue:string,
    explicitContent: boolean,
    muteWords: boolean,
    privateEvent: boolean,
    testLive:boolean,
    liveStreamModal: number,
    buyersList: Array<{userName: string; name: string}>,
    liveInventoryList: Array<Catalogue>
    showLiveInventory: boolean,
    checkedInventoryList: Array<Catalogue>,
    inventorySearch: string,
    liveModalTitle: string,
    liveModalSubTitle: string,
    liveOption: string,
    liveStreamID: number,
    itemCount: number,
    checkedInventory: string;
    displayPopup: boolean;
    errorMessage: string;
    isSkip: boolean;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class InstantGoLiveController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCategoryId: string =""
    getSubCategoryId: string =""
    createLiveId:string=""
    getLiveStreamingInventoryListApiID: string = "";
    postAddToLiveStreamApiID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            loader:false,
            isSideBarOpen:true,
            showTitle:"",
            description:"",
            primaryCategory: "default",
            subCategory: "default",
            categoriesList:[],
            subCategoriesList:[],
            addTags: [],
            addTagsValue: "",
            muteWordsList:[],
            mutedWordsValue:"",
            explicitContent: false,
            muteWords: false,
            privateEvent: false,
            testLive:false,
            liveStreamModal: 0,
            buyersList: [
              {
                userName: "RohitChandra54",
                name: "Rakesh verma"
              },
              {
                userName: "VikramAditi77",
                name: "Rakesh verma"
              },
            ],
            showLiveInventory: false,
            liveInventoryList: [],
            checkedInventoryList: [],
            inventorySearch: "",
            liveModalTitle: "",
            liveModalSubTitle: "",
            liveOption: "agora",
            liveStreamID: 0,
            itemCount: 0,
            checkedInventory: "",
            displayPopup: false,
            errorMessage: "",
            isSkip: false
            // Customizable Area End
        };

    }


    // Customizable Area Start

    async componentDidMount() {
      this.getLiveStreamingInventoryApi();
      const array = window.location.pathname.split("/");
      const route = array[array.length - 1];
      if(route === "TestLive"){
        this.setState({testLive:true})
      }
        this.getPrimaryCategory()
      }


    async receive(from: string, message: Message) {
      this.handleLiveStreamingInventoryResponse(from, message)
        this.handlegetCategoryResponse(from, message)
        this.handleSubCategoryResponse(from, message)
        this.handleSaveResponse(from, message)
        
      }

      handlegetCategoryResponse=(from: string, message: Message)=>{
        if (this.getCategoryId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (apiResponse) {
            this.setState({
              categoriesList: apiResponse?.data
            })
          }
        }
      }

      handleSubCategoryResponse=(from: string, message: Message)=>{
        if (this.getSubCategoryId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (apiResponse) {
            this.setState({
              subCategoriesList: apiResponse?.data
            })
          }
        }
      }

      handleSaveResponse=(from: string, message: Message)=>{
        if (this.createLiveId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (apiResponse) {
            this.setState({loader:false})
            this.setState({liveStreamID: apiResponse.data.id})
          }
        }
      }


      handleLiveStreamingInventoryResponse = (from: string, message: Message) => {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
          if(apiResponse){
            this.handleLiveStreamingInventorySuccessApi(message.getData(getName(MessageEnum.RestAPIResponceDataMessage)), apiResponse )
          }
    }



    handleSideBarVisbility = () => {
        this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
    }

      handleBackButton=()=>{
          const message = new Message(getName(MessageEnum.NavigationMessage));
          message.addData(getName(MessageEnum.NavigationTargetMessage),"LiveStreaming");
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          this.send(message)
      }   


      handleSelectFieldChange = (fieldName: keyof S) => (
          event: React.ChangeEvent<{ value: unknown }>
        ) => {
          const selectedValue = event.target.value as string;
          if (fieldName === "primaryCategory") {
            this.getSubCategory(selectedValue);
            this.setState({subCategory:"default"})
          }
          this.setState({[fieldName]: event.target.value as string,} as unknown as Pick<S, keyof S>);
      };

      handleTextFieldChange = (fieldName: keyof S) => (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          this.setState({
              [fieldName]: event.target.value,
          } as unknown as Pick<S, keyof S>);
      };


      handleAddTagsValue = (event: React.ChangeEvent<HTMLInputElement>) => {
          this.setState({
            addTagsValue: event.target.value,
          })
        };
      
      handleAddTagsIntoList = () => {
        const trimmedValue = this.state.addTagsValue.trim();
        if (trimmedValue.length !== 0) {
            this.setState(state => ({
                addTags: [...state.addTags, trimmedValue],
                addTagsValue: ""
            }));
        }
        };

      
        handleRemoveTag = (index: number): void => {
          this.setState(state => {
            const newArray = state.addTags.filter((_, eachindex) => eachindex !== index);
            return { addTags: newArray };
          });
        };
    
      handleRadioBtnOnandOff = (fieldName: keyof S, modalNum:number) => (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        this.setState({
          [fieldName]: event.target.checked,
        } as unknown as Pick<S, keyof S>, ()=>{
          this.setState({
            liveStreamModal: this.state.privateEvent ? modalNum : 0,
            liveModalTitle: configJSON.privateLiveEventModalTxt.title,
            liveModalSubTitle: configJSON.privateLiveEventModalTxt.desc
          })
        });
        if(fieldName==="muteWords" && !event.target.checked){
          this.setState(state => ({
            muteWordsList: [],
      
          }))
        }
      };


      handleAddMutedWords = () => {
        const value = this.state.mutedWordsValue.trim();
          if (value.length !== 0) {
              this.setState(state => ({
                  muteWordsList: [...state.muteWordsList, value],
                  mutedWordsValue: ""
          }));
        }
      };
    
      handleCloseMutedWords = (index: number): void => {
        
        this.setState(state => {
          const newArray = state.muteWordsList.filter((_, eachindex) => eachindex !== index);
          return { muteWordsList: newArray };
        });        
      };

      handleAddMutedWordsText = (event: React.ChangeEvent<HTMLInputElement>) => {

        this.setState({
          mutedWordsValue: event.target.value,
        })
        
      };


      handleSaveButton=async(isSkip:boolean = false)=>{
        this.setState({
          loader: true
        });
        let endPoint = `${configJSON.createSheduleLiveEndpoint}`
        const { showTitle,description,subCategory,primaryCategory,addTags,explicitContent,muteWords,muteWordsList,testLive} = this.state

        const formData = new FormData()
        formData.append(`data[category_id]`,primaryCategory);
        formData.append(`data[sub_category_id]`,subCategory);
        formData.append(`data[title]`,showTitle);
        formData.append(`data[description]`,description);
        addTags.forEach((eachTag:string) => {
        formData.append(`data[tag_list][]`, eachTag)});
        formData.append(`data[explicit_content]`,explicitContent +"");
        formData.append(`data[mute_words]`,muteWords + "");
        muteWordsList.forEach((eachWord:string) => {
        formData.append(`data[mute_words_arr][]`, eachWord)});
        formData.append(`data[stream_type]`,"instant_live"); 
        formData.append(`data[status]`,"live"); 
         if(testLive){
           formData.append(`data[stream_type]`,"test_live"); 
           formData.append(`data[status]`,"live");
         }
         
        const sellerLoginToken =  await getStorageData("singupLogin");

        const headers = {
            token: sellerLoginToken,
          };

        const createScheduleLiveMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createLiveId = createScheduleLiveMsg.messageId;
      
        createScheduleLiveMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        createScheduleLiveMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          createScheduleLiveMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postMethodType
        );

        createScheduleLiveMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      
        runEngine.sendMessage(createScheduleLiveMsg.id, createScheduleLiveMsg);
      }

      getPrimaryCategory(){
        const singupLogin =  getStorageData("singupLogin");
        const headers = {
            token: singupLogin,
          };
        const getcategoryMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCategoryId = getcategoryMsg.messageId;
      
        getcategoryMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getCategoriesEndpoint 
        );
        getcategoryMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
        getcategoryMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(getcategoryMsg.id, getcategoryMsg);
    
      }
    
      getSubCategory(selectedValue:string){   
        const singupLogin =  getStorageData("singupLogin");
        const endPoint = `${configJSON.getSubCategoriesEndpoint }?category_id=${selectedValue}`
        const headers = {
            token: singupLogin,

          };
        const getSubCategoryMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSubCategoryId = getSubCategoryMsg.messageId;
      
        getSubCategoryMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        getSubCategoryMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          getSubCategoryMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(getSubCategoryMsg.id, getSubCategoryMsg);
    
      }

  async handleLiveStreamingInventoryApi(config:{method: string; endPoint:string; body?:{}}) {
    const {method, endPoint, body} = config;    
    const singupLoginToken = await getStorageData("singupLogin");
    const headers = {
      "Content-Type": "application/json",
      token: singupLoginToken,
    };
    const liveStreamingInventoryMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    liveStreamingInventoryMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    liveStreamingInventoryMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    liveStreamingInventoryMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), method);

     liveStreamingInventoryMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(liveStreamingInventoryMsg.id, liveStreamingInventoryMsg);
    return liveStreamingInventoryMsg.messageId;
  }

      handleModalClose = () => {
        this.setState({
          liveStreamModal: this.state.liveStreamModal === 4 ? 4: 0,
          liveModalTitle: "",
          liveModalSubTitle: "",
          privateEvent: false
        }, ()=>{
          if(this.state.liveStreamModal === 4){
           this.goToGoLiveScreen();
          }
        })
      };
   
      handleLiveStreamModal = (modalNum:number, title:string, desc:string) => {
        this.setState({
          liveStreamModal: modalNum,
          liveModalTitle: title,
          liveModalSubTitle: desc
        })
      };

      handleShowLiveInventory = async () => {
        try {
          const endPoint = configJSON.createSheduleLiveEndpoint;
          const formData = new FormData();
          formData.append('data[skip]', 'true');
      
          const sellerLoginToken = await getStorageData('singupLogin');
      
          const headers = {
            token: sellerLoginToken,
          };
      
          const createScheduleLiveMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
          this.createLiveId = createScheduleLiveMsg.messageId;
      
          createScheduleLiveMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
          createScheduleLiveMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
          createScheduleLiveMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethodType);
          createScheduleLiveMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
      
          runEngine.sendMessage(createScheduleLiveMsg.id, createScheduleLiveMsg);
          this.setState({
            showLiveInventory: true,
          });
        } catch (error) {
        }
      };

      handleInventoryChecked = (event: ChangeEvent<HTMLInputElement>, inventoryId:number | string) => {
        if(event.target.checked){
          this.setState({
            checkedInventoryList: [...this.state.liveInventoryList.filter((inventory:Catalogue) => inventory.id === inventoryId), ...this.state.checkedInventoryList].map((inventory:Catalogue)=>{
              return {
                ...inventory,
                attributes: {
                  ...inventory.attributes,
                  inventory_details: {
                    ...inventory.attributes.inventory_details,
                    itemCount: 0
                  }
                }
              }
            }),
          })
        } else {
          this.setState({
            checkedInventoryList: this.state.checkedInventoryList.filter((inventory:Catalogue) => inventory.id !== inventoryId),
          })
        }
      }

      handleInventorySearch = (event:ChangeEvent<HTMLInputElement>) => {
        this.setState({
          inventorySearch: event.target.value
        })
      }

      getLiveStreamingInventoryApi = async() => {
        this.getLiveStreamingInventoryListApiID = await this.handleLiveStreamingInventoryApi({
          endPoint: configJSON.getLiveStreamingInventoryEndPoint,
          method: configJSON.validationApiMethodType
        })
      }

      updateLiveStreamingInventory = (inventoryID:string, actionType: number) => {
        const tempList = this.state.checkedInventoryList.slice();
        const matchedProductIdx = tempList.findIndex((inventory:Catalogue)=> 
          inventory.id == inventoryID);
        if(actionType === 1 && 
          tempList[matchedProductIdx].attributes.inventory_details.itemCount <= 49)
          {tempList[matchedProductIdx].attributes.inventory_details.itemCount += 1
          this.setState({
            checkedInventoryList: tempList
          })
        }
      }

      handleLiveStreamingInventorySuccessApi = (apiID:string, response: {catalogues:{
        data:Array<Catalogue>}} ) => {
        if(this.getLiveStreamingInventoryListApiID === apiID){
          this.setState({
            liveInventoryList: response.catalogues.data
          })
        };
        if(this.postAddToLiveStreamApiID === apiID){
          this.handleLiveStreamModal(4, configJSON.successfullyAdded, configJSON.successfullyAddedDesc)
        }
      }

      postAddToLiveApi = async() => {
        const parentArray:Array<Array<number>> = [];
        this.state.checkedInventoryList.forEach((inventory:Catalogue)=>{
          const childArray = [];
          childArray[0] = +inventory.id;
          childArray[1] = inventory.attributes.inventory_details.itemCount;
          parentArray.push(childArray);
        });
        this.postAddToLiveStreamApiID = await this.handleLiveStreamingInventoryApi({
          endPoint: configJSON.addToLiveEndPont,
          method: configJSON.exampleAPiMethod,
          body: {
            catalogues: parentArray,
            id: Number(this.state.liveStreamID)
          }
        })
        
      }

      goToGoLiveScreen = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage),"GoLiveScreen");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            message.addData(getName(MessageEnum.NavigationScreenNameMessage), this.state.liveStreamID);
            this.send(message)
      };

      handleCheckboxCheckedStyle = (checkedID:string) => {
        return this.state.checkedInventoryList.find((inventory: Catalogue) => inventory.id === checkedID)
      };

      closeErrorPopup = () => {
        this.setState({
          displayPopup: false
        });
      }

    // Customizable Area End
}