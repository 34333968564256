import React from "react";

import {
  // Customizable Area Start
  Button,
  Drawer,
  Box,
  Avatar,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  styled,
  Modal,
  TextField,
  InputAdornment,
  IconButton
  // Customizable Area End
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// Customizable Area Start

import { createTheme, ThemeProvider, withStyles, StyleRules } from "@material-ui/core/styles";
import { arrowDown, live, liveRadar, liveArchive, liveArchiveBlank, hamburger, checkbox, noRecord, noLiveEventsFound, uploadIcon, calendarIc, cancelIcon, checkIcon } from './assets'
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import StayTuned from "../../../components/src/StayTuned.web";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const styles: StyleRules = {
  contentBlockContent: {
    display: "flex",
    alignItems: "center",
    gap: '50px',
    marginTop: "20px",
    overflowX: "auto",
    paddingBottom: "25px",
    "&::-webkit-scrollbar": {
      height: "6px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      background: "#D9D9D955"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "10px",
    },
  },
  flexWrapTrue:{
    flexWrap:"wrap"
  },
  flexWrapFalse:{
    flexWrap:"nowrap"
  },
  overflowTrue:{
    overflow:"unset"
  },
  overflowFalse:{
    overflow:"auto"
  },
  storyLiveIcon: {
    position: "relative",
    top: "-90px",
    left: "75px",
    [theme.breakpoints.down("xs")]: {
      top: "-60px",
      left: "45px",
      width: "20px",
      height: "20px",
    },
  },
  contentBlockNumberContainer: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "12px",
    left: "12px",
    borderRadius: "15px",
    background: "#FF1F7140",
    padding: "4px 10px"
  },
  contentBlockNumber: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#FFFFFF",
    marginLeft: "10px"
  },
  contentBlockElement: {
    position: "relative",
    maxWidth: '190px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    animation: 'fadeIn 2s',
    MozAnimation: 'fadeIn 2s',
    WebkitAnimation: 'fadeIn 2s',
    msAnimation: 'fadeIn 2s',
    OAnimation: 'fadeIn 2s',
  },
  contentBlockArchive: {
    position: "absolute",
    top: "12px",
    right: "12px",
    borderRadius: "25px",
    background: "#00000099",
    padding: "6px 5px 5px 5px",
    cursor: 'pointer'
  },
  prodImgBox: {
    cursor:"pointer",
  },
  imageStyle:{
    height: '263px', 
    width: '190px', 
    borderRadius: '10px', 
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', 
    objectFit: 'cover' 
  },
  avatarStyle:{
    height: '24px', 
    width: '24px' 
  },
  hostNameText:{
    fontSize: "12px", 
    fontWeight: 500,
  },
  contentTitle:{
    fontSize: "12px", 
    fontWeight: 500,
  },
  contentSubtitle:{
    fontSize:"12px",
    fontWeight:400,
    color:"#A5A4A4"
  },
  sendRequestBtn:{
    width:"140px",
    height:"28px",
    borderRadius:"50px",
    background:"#F5EA16",
    textTransform:"none",
    marginTop:"6px",
    '&:hover': {
      backgroundColor: '#F5EA16',
      cursor:"pointer"
    },
  },
  contentBlockDesc: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '12px'
  },
  contentBlockUser: {
    display: "flex",
    alignItems: "center",
    gap: '8px'
  },
  customScrollbar: {
    "&::-webkit-scrollbar": {
      height: "6px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      background: "#D9D9D955"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "10px",
    },
  },
  landingPage: {
    display: 'flex',
    minHeight: '100vh',
    "&::-webkit-scrollbar": {
      width: "7px",
      position: 'absolute',
      left: 0,
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "3px",
      background: "#D9D9D955"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "3px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "100px"
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "start",
      marginTop: "100px",
    },
    '& .hamburger': {
      display: 'none',
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    }
  },
  asideMenu: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "block",
      width: "240px"
    },
  },
  leftSidebar: {
    '@media(max-width: 600px)': {
      display: "none",
    },
    '@media(min-width: 600px)': {
      display: "block",
      minWidth: "240px"
    },
    minHeight: "100vh",
    borderRight: "5px solid #D9D9D94D",
  },
  contentHeader: {
    display: "flex",
    padding: "0 20px 0 0",
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100vw"
    },
    [theme.breakpoints.up("sm")]: {
      width: "calc(100vw - 310px)"
    },
    "&::-webkit-scrollbar": {
      height: "6px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      background: "#D9D9D955"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#D9D9D9",
      borderRadius: "10px",
    },
  },
  feedBtn:{ margin: "0 40px 0 0", 
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    margin: "0 10px 0 0",
  },
},
  contentBlockContainer: {
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100vw"
    },
    [theme.breakpoints.up("sm")]: {
      width: "calc(100vw - 290px)"
    },
  },
  contentBlockHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 50px 0 0"
  },
  onOrderText:{
    fontSize: "22px", 
    fontWeight: 400 
  },
  viewAllText:{
    fontSize: "14px", 
    fontWeight: 400, 
    cursor: 'pointer' 
  },
  asideMenuSmallScreen: {
    minWidth: "200px",
    width: "200px",
    padding: "20px",
  },
  asideMenuHamburger: {
    width: "70px",
    marginTop: "70px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  contentContainer: {
    padding: "30px 0 30px 30px",
    flex: 1,
    overflow: "hidden",
    '@media (max-width: 320px)': {
      padding: "10px 5px 5px 5px",
    },
  },
  storyBorder: {
    width: "100px",
    height: "100px",
    borderRadius: "60px",
    display: "flex",
    padding: "3.46px",
    background: "linear-gradient(to bottom, #F5EA16 0%, #FF0000 100%)",
    [theme.breakpoints.down("xs")]: {
      width: "70px",
      height: "70px",
    },
  },
  story: {
    borderRadius: "60px",
    padding: "3.46px",
    background: "#FFFFFF",
    width: "94px",
    height: "94px",
    [theme.breakpoints.down("xs")]: {
      width: "64px",
      height: "64px",
    },
  },
  asideMenuTitle: {
    padding: "10px",
    borderBottom: "1px solid #F2F3F6",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: 400,
    color: "#9695A1"
  },
  asideMenuElement: {
    padding: "10px 0px 10px 12px",
    fontSize: "14px",
    color: "black"
  },
  menuElementTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer"
  },
  rotateArrow: {
    transform: "rotate(180deg)"
  },
  menuChildElement: {
    padding: "10px 0px",
    cursor: 'pointer',
    fontSize: '14px',
  },
  goToFeedContainer: {
    display: "flex",
    alignItems: "center",
    margin: '20px 12px 0px 12px'
  },
  goToFeedButton: {
    color: "black",
    backgroundColor: "#F5EA16",
    width: "100%",
    borderRadius: "25px",
    border: "1px solid black",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: "4px",
    '&:hover': {
      backgroundColor: '#F5EA16'
    },
  },
  textDescription: {
    WebkitLineClamp: 2,
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
  },
  sidebar: {
    '@media(min-width: 600px)': {
      borderRight: '5px solid #D9D9D9',
      marginRight: '-5px',
      paddingLeft: '16px',
      paddingRight: '8px'
    },
  },
  popupContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: "1.5px solid #e9e9e9",
    paddingBottom: "10px",
    margin: "20px 20px 0 20px"
  },
  gridLayout: {
    maxHeight: '410px',
    display: 'grid',
    gridTemplateColumns: 'repeat(4 , 1fr)',
    gridRowGap: '20px',
    gridColumnGap: '10px',
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: 'repeat(3 , 1fr)',
    },
    [theme.breakpoints.down(500)]: {
      maxHeight: '390px',
      gridTemplateColumns: 'repeat(2 , 1fr)',
    },
    [theme.breakpoints.down(400)]: {
      maxHeight: '350px',
    },
    [theme.breakpoints.down(376)]: {
      maxHeight: '330px',
    },
    [theme.breakpoints.down(281)]: {
      maxHeight: '250px',
      gridTemplateColumns: 'repeat(1 , 1fr)',
    },
    '& .gridImg': {
      height: '185px',
      [theme.breakpoints.down(281)]: {
        maxWidth: '100% !important',
      },
    }
  },
  selectCategoris: {
    '& .MuiBox-root': {
      borderBottom: 'none',
    },
  },
  categorySubmitBtn: {
    left: '20%',
    transform: 'translateX(-50%)',
    display: 'flex',
    width: '220px',
    height: '58px',
    maxHeight: '58px',
    padding: '0px',
    maxWidth: 'maxContent',
    margin: '0 auto',
    backgroundColor: '#F5EA16',
    fontSize: '22px',
    lineHeight: '33px',
    fontWeight: 600,
    letterSpacing: '0.42px',
    textTransform: 'initial',
    borderRadius: '35px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#F5EA16',
      boxShadow: 'none',
    },
  },
  categoryName: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    color: 'white',
    padding: '8px',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.3px',
    textAlign: 'center',
  },
  noDataFoundImg: {
    maxWidth: '200px',
    width: '100%',
    animation: 'fadeIn 2s',
    MozAnimation: 'fadeIn 2s',
    WebkitAnimation: 'fadeIn 2s',
    OAnimation: 'fadeIn 2s',
    msAnimation: 'fadeIn 2s',
  },
  noDataFoundImgBox: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down(350)]: {
      textAlign: 'left',
      marginLeft: '-8px'
    },
  },
  selectAllChecbox:{
      color:"#f5ea16"
  },
  sellectAllButtonContainer:{
    display:"flex",
    justifyContent:"flex-end",
    alignItems:"center"
  },
  selectAllText:{
    color: 'rgba(68, 68, 68, 0.70)',
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: 'Poppins !important',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal'
  }
};

import { Category as CategoryI, OnOrderData } from "./LandingPageController";
let config = require('../../../framework/src/config')
// Customizable Area End

import LandingPageController, {
  Props,configJSON
} from "./LandingPageController";
import Loader from "../../../components/src/Loader.web";
class BuyerHome extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  sidebar() {
    const { classes } = this.props;
    const { chooseCatergoriesData, getSubCategoriesdata, openItemIndex, showLoader, subCategoryIDs } = this.state;
    return (
      <Box className={classes.sidebar}>
        <Typography className={classes.asideMenuTitle}>Categories</Typography>
        {chooseCatergoriesData?.map((items: any, index: any, item: any) => (
          <div className={classes.asideMenuElement} key={index}>
            <div data-test-id="sideBarToggle" className={classes.menuElementTitle} onClick={() => this.toggleAsideMenuItem(items?.attributes?.id, index)}>
              <div>{items?.attributes?.name}</div>
              <img src={arrowDown} alt="arrow down" className={index === openItemIndex ? classes.rotateArrow : undefined} />
            </div>
            {index === openItemIndex &&
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '10px' }}>
                {showLoader ? <CircularProgress style={{ color: '#F5EA16', height: '14px', width: '14px' }} />
                  : <React.Fragment>
                    {getSubCategoriesdata?.map((item: any,) => (
                      <Box style={{ width: '140px' }}>
                        <Typography
                          className={classes.menuChildElement}
                          data-test-id="sideBarSubCategories"
                          style={{ color: subCategoryIDs.includes(item.id) ? "#9695A1" : "black" }}
                          onClick={
                            () => this.getTopSearchDataWithSubCategory(items, item)
                          }
                        >{item.attributes.name}
                        </Typography>
                        <Divider />
                      </Box>
                    ))}
                  </React.Fragment>}
              </div>
            }
          </div>
        ))}
        <Box className={classes.goToFeedContainer}>
          <Button className={classes.goToFeedButton} onClick={this.goToFeed}>{configJSON.goToFeedTxt}</Button>
        </Box>
      </Box>
    )
  }

  renderSideBars = () => {
    if (this.state.viewType ===  "categories") {
        return this.renderCategoriesSidebar();
      }
    return this.sidebar();
  }

  renderCategoriesSidebar = () => {
    const { classes } = this.props;
    return (
      <Box className={classes.sidebar}>
        <Typography className={classes.asideMenuTitle}>{configJSON.sortTxt}</Typography>
        <StyledRadioGroup aria-label="category" name="category" value={this.state.categorySortValue} onChange={this.handleCategorySort} data-test-id="radioId">
          {
            this.state.categoriesSidbarData.map((category: { [key: string]: string }) => {
              return <FormControlLabel value={category.name} control={<Radio />} label={category.name} />
            })
          }
        </StyledRadioGroup>
        <Box className={classes.goToFeedContainer}>
          <Button className={classes.goToFeedButton} onClick={this.goToFeed}>GO TO FEED</Button>
        </Box>
      </Box>
    )
  }

  chooseCategotyDialog = () => {
    const { classes } = this.props;
    const { chooseCatergoriesData } = this.state;
    return (
      <Box className={classes.selectCategoris}>
        <Dialog
          open={this.state.chooseCategoriesOpenClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              borderRadius: 25,
              width: '695px',
              height: '660px',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <Box className={classes.popupContainer} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', borderBottom: 'none' }}>
              <h4 style={{ display: 'flex', margin: '0 auto', fontSize: '20px', lineHeight: '30px' }}>
                {'Select your preferences'}
              </h4>
              <h5 style={{ display: 'flex', margin: '0 auto' }}>
                {'Choose 5 or more categories to continue'}
              </h5>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={classes.sellectAllButtonContainer}>
              <Checkbox
                data-test-id="selectAll"
                color="default"
                inputProps={{ 'aria-label': 'checkbox with default color' }}
                onChange={this.handleSelectAllCategories}
                value={this.state.seletAllCategories}
                className={classes.selectAllChecbox}
              />
                 <Typography component="span" className={classes.selectAllText}>{configJSON.selectAll}</Typography>    
            </Box>
            <DialogContentText className={classes.gridLayout} id="alert-dialog-description" style={{ overflowY: 'scroll' }} >
              {chooseCatergoriesData?.map((items: any, index: number) => (
                <label htmlFor={`index-${index + 1}`} style={{ position: 'relative' }}>
                  <input id={`index-${index + 1}`} type="checkbox" data-test-id='chooseCategories' onChange={(event) => this.handleOnChangeChecbox(items, event)} value={items.attributes.id} hidden />
                  <Box className="gridImg" style={{ maxWidth: '135px', position: 'relative' }} key={index}>
                    <img
                      src={`${baseURL}${items.attributes.light_icon}`}
                      style={{ maxWidth: '100%', minHeight: '100%', borderRadius: '10px', objectFit: 'cover', width: '100%', maxHeight: '100%' }}
                      alt=""
                    />
                    <Box className={classes.categoryName} >
                      {items.attributes.name}
                    </Box>
                    <Box style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      padding: '8px',
                      color: 'white',
                    }}>
                      {this.state.checkedDataPost.includes(items?.id) ? <img src={checkbox} data-test-id='checked'/> : ''}
                    </Box>
                  </Box>
                </label>
              ))}
            </DialogContentText>
            <Button
              data-test-id='submitBtn'
              onClick={this.chooseCategoryDataUpdated}
              disabled={this.state.checkedDataPost.length < 5}
              className={classes.categorySubmitBtn}>
              Submit
            </Button>
          </DialogContent>
        </Dialog>
      </Box>
    )
  }

  liveEvent = () => {
    const { classes } = this.props;
    const { getLiveEventsSearchData, wishListLiveStream } =this.state
    return (
      <Box className={classes.contentBlockContainer}>
        <Box className={classes.contentBlockHeading}>
          <Typography style={{ fontSize: "22px", fontWeight: 400 }}>Live Events</Typography>
          <Typography onClick={this.showALL} style={{ fontSize: "14px", fontWeight: 400, cursor: 'pointer' }}>{this.state.showingAll ? `View Less >` : `View All >`}</Typography>
        </Box>
        <Box className={classes.contentBlockContent} style={{ flexWrap: this.state.showingAll ? "wrap" : "nowrap", overflowX: this.state.showingAll ? 'unset' : 'auto' }}>
          {getLiveEventsSearchData.length > 0 ? getLiveEventsSearchData.map((item: any, index: any) => (
            <Box className={classes.contentBlockElement} key={index}>
              <Box className={classes.contentBlockNumberContainer}>
                <img src={liveRadar} />
                <Typography className={classes.contentBlockNumber}>24</Typography>
              </Box>
              {
                this.checkWishStatus(wishListLiveStream, parseInt(item.id))
                  ? (
                    <Box className={classes.contentBlockArchive} data-test-id={`removeWishItem-${index}`} onClick={() => this.deleteWishItem(wishListLiveStream, parseInt(item.id))}>
                      <img src={liveArchive} alt="wished" />
                    </Box>
                  ) : (
                    <Box className={classes.contentBlockArchive} data-test-id={`addWishItem-${index}`} onClick={() => this.updateWishListItem(item.id, item.type)}>
                      <img src={liveArchiveBlank} alt="not-wished" />
                    </Box>
                  )
              }
              <Link to={`BuyersLiveStreaming?id=${item.id}`}>
                <img src={item.attributes.thumbnail} style={{ height: '263px', width: '190px', borderRadius: '10px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', objectFit: 'cover' }} />
              </Link>
              <Box className={classes.contentBlockDesc}>
                <Box className={classes.contentBlockUser}>
                  <Avatar src={item.attributes.host_image} style={{ height: '24px', width: '24px' }} />
                  <Typography style={{ fontSize: "12px", fontWeight: 500 }} >{item.attributes.host_name}</Typography>
                </Box>
                <Typography className={classes.textDescription} style={{ fontSize: "12px", fontWeight: 500 }} >{item.attributes.title}</Typography>
                <Typography className={classes.textDescription} style={{ fontSize: "12px", color: "#A5A4A4" }} >{item.attributes.description}</Typography>
              </Box>
            </Box>
          ))
            : <Box className={classes.noDataFoundImgBox}>
              <img src={noLiveEventsFound} className={classes.noDataFoundImg} />
              <Typography><strong>No scheduled Live Events Found</strong></Typography>
              <Typography color="textSecondary">Uh-ho! it seems there's no one live at the moment</Typography>
            </Box>}
        </Box>
      </Box>
    )
  }

  topProduct = () => {
    const { classes, history } = this.props;
    const { getTopSearchData, wishListProduct } =this.state
        return (
      <Box className={classes.contentBlockContainer} >
        <Box className={classes.contentBlockHeading}>
          <Typography style={{ fontSize: "22px", fontWeight: 400 }} >Top Selling</Typography>
          <Typography onClick={this.showAllTopSelling} style={{ fontSize: "14px", fontWeight: 400, cursor: 'pointer' }}>{this.state.showingAll ? `View Less >` : `View All >`}</Typography>
        </Box>
        <Box className={classes.contentBlockContent} style={{ flexWrap: this.state.showingAll ? "wrap" : "nowrap", overflowX: this.state.showingAll ? 'unset' : 'auto' }} >
          {getTopSearchData.length > 0 ? getTopSearchData.map((item: any, index: any) => (
            <Box className={classes.contentBlockElement} key={index}>
              {
                this.checkProductStatus(wishListProduct, parseInt(item.id))
                  ? (
                    <Box className={classes.contentBlockArchive} data-test-id={`removeWishItemProduct-${index}`} onClick={() => this.deleteProductItem(wishListProduct, parseInt(item.id))}>
                      <img src={liveArchive} alt="wished" />
                    </Box>
                  ) : (
                    <Box className={classes.contentBlockArchive} data-test-id={`addWishItemProduct-${index}`} onClick={() => this.updateWishListItem(item.id, item.type)}>
                      <img src={liveArchiveBlank} alt="not-wished" />
                    </Box>
                  )
              }
              <Box data-test-id="navigateButtonTestId" className="prodImgBox" onClick={() => this.handleNavigate(item.id)}
                style={{ cursor: 'pointer' }}>
                <img src={`${item.attributes.product_images[0].url}`} className={classes.imageStyle} />
              </Box>
              <Box className={classes.contentBlockDesc}>
                <Box className={classes.contentBlockUser}>
                  <Avatar src={item.attributes.host_image} style={{ height: '24px', width: '24px' }} />
                  <Typography style={{ fontSize: "12px", fontWeight: 500 }} >{item.attributes.host_name}</Typography>
                </Box>
              </Box>
            </Box>
          ))
            : <Box className={classes.noDataFoundImgBox}>
              <img src={noRecord} className={classes.noDataFoundImg} />
              <Typography><strong>No Record was Found</strong></Typography>
              <Typography color="textSecondary">Uh-ho! it seems there's nothing in the wardrobe at the moment</Typography>
            </Box>}
        </Box>
      </Box>
    )
  }

  onOrderView = () => {
    const { classes } = this.props;
    const { onOrderData, wishListOnOrder, showingAll } = this.state
    return (
      <Box className={classes.contentBlockContainer} >
        <Box className={classes.contentBlockHeading}>
          <Typography className={classes.onOrderText} >
            {configJSON.onOrderTxt}
          </Typography>
          <Typography onClick={this.handleShowAllOnOrders} 
            data-test-id="viewAllBtnTestid"
            className={classes.viewAllText}>
            {this.state.showingAll ? `View Less >` : `View All >`}
          </Typography>
        </Box>
        <Box className={`${classes.contentBlockContent} 
          ${showingAll ? classes.flexWrapTrue : classes.flexWrapFalse} ${showingAll ? classes.overflowTrue : classes.overflowFalse}`} >
          {onOrderData.length > 0 ? onOrderData.map((item: OnOrderData, index: number) => (
            <Box className={classes.contentBlockElement} key={index}>
              {
                this.checkProductStatus(wishListOnOrder, parseInt(item.productId))
                  ? (
                    <Box 
                      className={classes.contentBlockArchive} 
                      data-test-id={`removeWishItemProduct-${index}`} 
                      onClick={() => this.deleteProductItem(wishListOnOrder, parseInt(item.productId))}>
                      <img src={liveArchive} alt="wished" />
                    </Box>
                  ) : (
                    <Box 
                      className={classes.contentBlockArchive} 
                      data-test-id={`addWishItemProduct-${index}`} 
                      onClick={() => this.updateWishListItem(parseInt(item.productId), "product")}>
                      <img src={liveArchiveBlank} alt="not-wished" />
                    </Box>
                  )
              }
              <Box data-test-id="navigateButtonTestId" className={classes.prodImgBox} onClick={() => this.handleNavigate(parseInt(item.productId))}>
                <img src={`${item.productImageUrl}`} className={classes.imageStyle} />
              </Box>
              <Box 
                className={classes.contentBlockDesc}>
                <Box 
                  className={classes.contentBlockUser}>
                  <Avatar src={item.hostImage} 
                    className={classes.avatarStyle} />
                  <Typography 
                    className={classes.hostNameText}>
                      {item.accountFullName}
                  </Typography>
                </Box>
                <Typography className={classes.contentTitle}>{this.truncatedTitle(item.title)}</Typography>
                <Typography className={classes.contentSubtitle}>{this.truncatedDescription(item.description)}</Typography>
                <Button data-test-id="sendRequestTestId" className={classes.sendRequestBtn} onClick={() => this.handleOnOrderRequestModal(item)}>{configJSON.sendRequestTxt}</Button>
              </Box>
            </Box> 
          ))
            : <Box className={classes.noDataFoundImgBox}>
              <img src={noRecord} className={classes.noDataFoundImg} />
              <Typography><strong>No Record was Found</strong></Typography>
              <Typography color="textSecondary">Uh-ho! it seems there's nothing in the wardrobe at the moment</Typography>
            </Box>}
        </Box>
      </Box>
    )
  };

  renderMainView = () => {
    if (this.state.viewType === "categories") {
      return this.renderCategoriesView();
    }
    return this.renderHomeView();
  }

  renderHomeView = () => {
    const {classes} = this.props;
    return <>
    <Box className={classes.contentHeader}>
              {this.headerData.map(item => (
                <Box className={classes.feedBtn} onClick={this.goToFeed}>
                  <Box className={classes.storyBorder}>
                    <img className={classes.story} src={item.img} />
                  </Box>
                  {item.live && <img className={classes.storyLiveIcon} src={live} />}
                </Box>
              ))}
            </Box>
           
      {!this.state.showAllLiveEvents && !this.state.showAllOnOrders &&
        this.topProduct()
      }
      {!this.state.showAllTopSelling && !this.state.showAllOnOrders &&
        this.liveEvent()
      }
      {!this.state.showAllLiveEvents && !this.state.showAllTopSelling &&
        this.onOrderView()
      }
    </>
  }

  renderCategoriesView = () => {
    return <Box>
      <Title data-test-id="categoriesTxtId">Categories</Title>
      <ScrollContainer className="categoryScroll">
          <CategoryContainer>
            {
              this.state.categoryData?.map((category: CategoryI) => (
                <Category key={category.id}>
                  <img src={config.baseURL + category.attributes.light_icon} alt={category.attributes.name} className="categoryImg" />
                  <Typography className="categoryName"  data-test-id="categoryId">{category.attributes.name}</Typography>
                </Category>
              ))
            }
          </CategoryContainer>
        </ScrollContainer>
    </Box>
  }

  renderOnOrderRequestModal = () => {
    const { selectedFiles, imgUploadErr, quantity, descriptionErr } = this.state;
    return(
      <CustomModal
        open={this.state.isRequestModal}
        onClose={this.toggleOnOrderModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="mainModalContainer">
          <Box className="modalHeaderBox">
            <Box className="modaltitle">
              <Typography className="orderDetailsText">{configJSON.orderDetailsTxt}</Typography>
            </Box>
              <img src={cancelIcon} data-test-id="cancelBtnTestId" onClick={this.toggleOnOrderModal}/>
          </Box>
          <Typography className="selectedTitlText">{configJSON.selectedItemTxt}</Typography>
          <Box className="productDetailsBox">
            <Box className="productImageBox"><img src={`${this.state.selectedOnOrder?.productImageUrl}`} className="prodImg" /></Box>
            <Box className="productRightBox">
              <Typography className="modalTitleTxt">{this.truncatedTitle(String(this.state.selectedOnOrder?.title))}<span className="orderIdTxt">#{this.state.selectedOnOrder?.productId}</span></Typography>
              <Typography className="prodSubtitleText">{this.truncatedDescription(String(this.state.selectedOnOrder?.description))}</Typography>
            </Box>
          </Box>
          <Typography className="selectedTitlText">{configJSON.detailsTxt}</Typography>
          <TextField multiline maxRows={2} minRows={2} className="detailsTextField" placeholder="A customised abstract effect on the head phone" InputProps={{disableUnderline: true}} value={this.state.description} onChange={this.handleDescriptionChange} data-test-id="descriptionInputTestId" />
          {descriptionErr && <Typography className="imgUploadingError">{"*Description cannot be empty."}</Typography>}
          <Typography className="selectedTitlText">{configJSON.attachmentTxt}</Typography>
          <Box className="attachmentMainContainer">
            <input type="file" multiple hidden accept="image/*" data-test-id="fileInputTestId" className="fileInput" ref={this.fileInputRef} onChange={this.handleFileSelect} />
            <Box className="attachmentBox" onClick={this.handleUploadClick} data-test-id="attachmentBoxTestId">
              <img className="uploadImgIcon" src={uploadIcon} />
              <Typography className="uploadTxt">{configJSON.uploadImgTxt}</Typography>
              <Typography className="sizeLimitTxt">{configJSON.maxMbTxt}</Typography>
            </Box>
            {selectedFiles.length > 0 && <Box className="selectedImagesBox">
              {selectedFiles.map((imgUrl, imgIndex) => (
                <Box className="imgPreviewBox" key={imgIndex}>
                  <img src={URL.createObjectURL(imgUrl)} className="previewImg" />
                </Box>
              ))}
            </Box>}
          </Box>
          {imgUploadErr && <Typography className="imgUploadingError">{imgUploadErr}</Typography>}

          <Box className="quantityContainer">
            <Typography className="selectedTitlText">{"Quantity"}</Typography>
            <Box className="quantityBox">
              <Box className="quantityBtnBox">
                <RemoveCircleOutlineIcon className="quantityIcons" data-test-id="deleteIconTestId" onClick={() => this.updateOrDeleteItem(quantity, false)} />
                <Typography data-test-id="quantityTxtTestId">{quantity}</Typography>
                <AddCircleOutlineIcon data-test-id="addQuantityBtnTestId" className="quantityIcons" onClick={() => this.updateOrDeleteItem(quantity, true)} />
              </Box>
            </Box>
          </Box>

          <Typography className="selectedTitlText">{configJSON.expectedDatetxt}</Typography>
          <DatePicker
            selected={this.state.expectedDate}
            onChange={this.handleDateChange}
            minDate={new Date()}
            dateFormat="dd/MM/yyyy"
            customInput={
              <TextField
                variant="outlined"
                placeholder="Select date"
                className="inputDateField"
                value={this.state.expectedDate ? this.state.expectedDate.toLocaleDateString() : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton >
                        <img src={calendarIc} alt="" className="calenderIcon" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
            popperPlacement="top-start"
            placeholderText="Select Date"
          />
          <Box className="submitBtnBox">
            <Button className="submitOrderBtn" data-test-id="submitTestId" onClick={this.handleOnClickSubmit}>{configJSON.submitTxt}</Button>
          </Box>
        </Box>
      </CustomModal>
    )
  };

  renderSuccessModal = () => {
    return (
      <SuccessCustomModal
        open={this.state.isSuccessModal}
        onClose={this.toogleSuccessModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="mainSuccessModalContainer">
          <Box className="successModalHeader">
            <Box>
              <Typography className="modalTitle">{configJSON.requestSuccessTxt}</Typography>
            </Box>
            <IconButton className="cancelIconBox">
              <img src={cancelIcon} data-test-id="cancelBtnTestId" onClick={this.toogleSuccessModal}/>
            </IconButton>
          </Box>
          <Box className="bottomModalBox">
            <Box className="tickBox"><img src={checkIcon} /></Box>
            <Box className="modalBodyBox">
              <Typography className="modalBodyTxt">{configJSON.modalBodyTxt}</Typography>
            </Box>
            <Button className="goToChatBtn" data-test-id="navigateToChatTestId" onClick={()=> this.handleNavigateToChat()}>{configJSON.goToChatTxt}</Button>
          </Box>
        </Box>
      </SuccessCustomModal>
    )
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { openTunedBox, loading, message, messageModel } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div id="myref"></div>
        <Loader loading={loading} />
        {this.chooseCategotyDialog()}
        <Box className={classes.asideMenu}>
          <Box className={classes.asideMenuHamburger}>
            <Drawer anchor={'left'} open={this.state.showSidebar} onClose={this.toggleDrawer(false)}>
              <Box className={classes.asideMenuSmallScreen}>
                {this.renderSideBars()}
              </Box>
            </Drawer>
          </Box>
        </Box>
        <StayTuned openTunedBox={openTunedBox} closeTunedBox={this.closeTunedBox} />
        <Box className={classes.landingPage}>
          <Button className={'hamburger'} onClick={this.toggleDrawer(true)}>
            <img src={hamburger} />
          </Button>

          <Box className={classes.leftSidebar}>
            {this.renderSideBars()}
          </Box>

          <Box className={classes.contentContainer} data-test-id="mainContainer">
            {
              this.renderMainView()
            }
            {this.renderOnOrderRequestModal()}
            {this.renderSuccessModal()}
          </Box>
        </Box>
        <MessageModalWeb
          displayPopup={messageModel}
          closeErrorPopup={this.closeMessageModal}
          errorMessage={message} />
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomModal = styled(Modal)({
  "& .mainModalContainer": {
    position: "absolute",
    top: "50%",
    left: "50%",
    background: "white",
    border: "none",
    maxWidth: "350px",
    width:"100%",
    alignItems: "center",
    transform: "translate(-50%, -50%)",
    borderRadius: "14px",
    padding: "10px 24px",
    height: "610px",
    overflowY: "scroll",
    scrollbarWidth: "none"
  },
  "& .mainModalContainer::-webkit-scrollbar": {
    width: "0px"
  },
  "& .modalHeaderBox":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    marginTop:"10px"
  },
  "& .orderDetailsText":{
    fontSize:"16px",
    fontWeight:600
  },
  "& .productDetailsBox":{
    display:"flex",
    gap:"12px",
    marginTop:"16px"
  },
  "& .productRightBox":{
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    gap:"6px"
  },
  "& .productImageBox":{
    width:"100%",
    height:"100%",
    maxWidth:"100px",
    maxHeight:"100px"
  },
  "& .prodImg":{
    width:"100%",
    height:"100%",
    objectFit:"contain"
  },
  "& .modalTitleTxt":{
    fontWeight: 500,
  },
  "& .selectedTitlText":{
    fontWeight: 500,
    marginTop:"16px"
  },
  "& .orderIdTxt":{
    fontWeight:400,
    color: "#55555580",
    marginLeft:"22px",
    fontSize:"12px",
  },
  "& .prodSubtitleText":{
    color:" #8A8A8A",
    fontWeight:400,
    fontSize:"12px"
  },
  "& .detailsTextField":{
    borderRadius:"8px",
    border: "0.5px solid #000000",
    width:"100%",
    marginTop:"10px",
    padding:"6px 8px",
    "& .MuiInputBase-inputMultiline":{
      color: "#444444CC",
      fontSize:"12px",
    },
    outline:"none"
  },
  "& .attachmentBox":{
    border: "0.5px dashed #000000",
    maxWidth:"107px",
    minHeight:"101px",
    borderRadius:"8px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    gap: "6px",
    marginTop: "10px",
    width: "100%",
  },
  "& .attachmentMainContainer": {
    display: "flex",
    alignItems: "center",
    gap: "28px"
  },
  "& .selectedImagesBox": {
    display: "flex",
    gap: "14px",
    flexWrap: "wrap",
    height: "103px",
    overflowY: "scroll",
    width: "fit-content",
  },
  "& .previewImg": {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  },
  "& .imgPreviewBox": {
    width: "60px",
    height: "60px",
    borderRadius: "4px",
  },
  "& .imgUploadingError": {
    color: "red",
    fontSize: "12px",
    marginTop: "6px"
  },
  "& .quantityContainer": {
    display: "flex",
    alignItems: "center",
    padding: "4px 0px",
    justifyContent: "space-between"
  },
  "& .quantityBtnBox": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    borderRadius: '35px',
    maxWidth: '100px',
    width: '100%',
    marginTop: "13px",
    gap: "6px"
  },
  "& .uploadTxt":{
    fontSize:"12px",
    fontWeight:600,
    color:"#555555",
    textDecoration:"underline",
    cursor:"pointer"
  },
  "& .sizeLimitTxt":{
    fontSize:"10px",
    fontWeight:500,
    color:"#555555",
    textDecoration:"underline",
    cursor:"pointer"
  },
  "& .uploadImgIcon":{

  },
  "& .submitOrderBtn":{
    minWidth:"187px",
    minHeight:"40px",
    borderRadius:"50px",
    background:"#F5EA16",
    textTransform:"none",
    marginTop:"6px",
    '&:hover': {
      backgroundColor: '#F5EA16',
      cursor:"pointer"
    },
  },
  "& .submitBtnBox":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    marginTop:"16px",
    marginBottom:"6px"
  },
  "& .inputDateField":{
    marginTop:"10px",
    border: "1px solid #000000",
    borderRadius:"8px",
    minHeight:"48px",
    '&:hover': {
      border: "1px solid #000000",
      cursor:"pointer"
    },
  },
  "& .calenderIcon":{
    width:"24px",
    height:"24px"
  }
});
const SuccessCustomModal = styled(Modal)({
  "& .mainSuccessModalContainer": {
    position: "absolute",
    top: "50%",
    left: "50%",
    background: "white",
    border: "none",
    maxWidth: "331px",
    width: "100%",
    alignItems: "center",
    transform: "translate(-50%, -50%)",
    borderRadius: "14px",
    padding: "20px 30px",
  },
  "& .successModalHeader": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderBottom: "1px solid #E9E9E9",
    paddingBottom: "18px"
  },
  "& .modalTitle": {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "27px",
  },
  "& .cancelIconBox": {
    marginLeft: "30px"
  },
  "& .bottomModalBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    gap: "22px"
  },
  "& .tickBox": {
    width: "42px",
    height: "42px",
    borderRadius: "100px",
    backgroundColor: "#F5EA16",
    marginTop: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .modalBodyTxt": {
    fontWeight: 400,
    fontSize: "14px",
    color: "#000000",
    margin: "0px 10px"
  },
  "& .goToChatBtn": {
    maxWidth: "167px",
    maxHeight: "40px",
    width: "100%",
    height: "100%",
    borderRadius: "50px",
    background: "#F5EA16",
    textTransform: "none",
    marginTop: "6px",
    '&:hover': {
      backgroundColor: '#F5EA16',
      cursor: "pointer"
    },
  },
});
// @ts-ignore
const StyledRadioGroup = styled(RadioGroup)({
  paddingBottom: "20px",
  borderBottom: "1px solid #F2F3F6",
  "& .MuiFormControlLabel-root":{
    justifyContent: "space-between",
    marginRight: "0"
  },
  "& .MuiFormControlLabel-label":{
    flex: 1
  },
  "& .MuiFormControlLabel-label:first-letter":{
    textTransform: "capitalize",
    whiteSpace: "nowrap"
  },
  margin: "0 12px",
  "& .MuiRadio-root":{
    order: 2,
    color: "#000",
    paddingRight: "0px"
  }
});
const Title = styled(Typography)({
  fontSize: "20px"
});
const ScrollContainer = styled(Box)({
  overflow: "auto",
  marginTop: "20px",
})

const CategoryContainer = styled(Box)({
  display: "grid",
  columnGap: "40px",
  rowGap: "20px",
  gridTemplateColumns: "repeat(4 , 1fr)",
  paddingBottom: "20px",
  width: "1165px",
  paddingRight: "40px",
  "@media(max-width: 1140px)":{
    maxWidth: "unset",
    minWidth: "1140px",
  }
})

const Category = styled(Box)({
  width: "100%",
  marginBottom: "15px",
  "& .categoryImg":{
    width: "100%",
    height: "140px",
    borderRadius: "8px"
  },
  "& .categoryName":{
    textAlign: "center",
    marginTop: "12px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "#4E4E4E"
  }
})

export default withStyles(styles)(withRouter(BuyerHome))
export { BuyerHome }
// Customizable Area End
