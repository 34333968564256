import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
let config = require('../../../framework/src/config');
import firebase from 'firebase'
import { Base64 } from 'base64-string';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  sellerEmailUser: string;
  sellerEmailUserError: string
  sellerPassword: string;
  sellerPasswordError: string
  isLoginSuccessModalOpen: boolean,
  message: any;
  checkbox: boolean
  isSelect: boolean;
  selectedValue: any;
  dataSource: any;
  mobileNumber: string
  phoneError: any
  otp: any
  otpStateMobile: any
  otpError: string
  emailModal: boolean
  resendModal: boolean;
  emailModalNavigate: boolean
  showPassword: boolean;
  copyPassword: string;
  displayPopup: boolean;
  SuccessResponsePopup: boolean
  errorMessage: string;
  loginFormInitialValues: any;
  userLoginError: string,
  userPasswordError: string,
  resultState: any;

  isChecked: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  selllerLoginApiCallId: string = "";
  countryCodeApiCallId: string = "";
  sellerLoginMobileApiCallId: string = "";
  sellerLoginMobileOtpApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  loginApiCallId: string = "";
  loginGoogleCallId: any;
  getBuyerProfileApiId: string = ""  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      sellerPassword: "",
      sellerPasswordError: "",
      sellerEmailUser: "",
      sellerEmailUserError: "",
      isLoginSuccessModalOpen: false,
      message: "",
      checkbox: false,
      isSelect: false,
      selectedValue: {},
      dataSource: [],
      mobileNumber: '',
      phoneError: '',
      otp: '',
      otpStateMobile: '',
      otpError: '',
      emailModal: false,
      resendModal: false,
      emailModalNavigate: false,
      showPassword: false,
      copyPassword: '',
      displayPopup: false,
      SuccessResponsePopup: false,
      errorMessage: '',
      resultState: {},
      loginFormInitialValues: {
        user: '',
        password: '',
        rememberMe: false
      },
      userLoginError: "",
      userPasswordError: "",
      isChecked: false
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    if (firebase.apps.length === 0) {
      const firebaseConfig = {
        apiKey: "AIzaSyDhTvxhzlBzlSDc8ZBuaLtQYZ0JQ1V4Dzg",
        authDomain: "flixoolive.firebaseapp.com",
        projectId: "flixoolive",
        storageBucket: "flixoolive.appspot.com",
        messagingSenderId: "748958312232",
        appId: "1:748958312232:web:eb5f0e3ccd09d04372d21d",
        measurementId: "G-RHKYGJSV6N"
      };
      firebase.initializeApp(firebaseConfig);
    }
    this.makeRemoteRequest();
    this.checkSellerDataFromLocalStorage();
    this.checkBuyerDataFromLocalStorage();
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  handleSubmit = async () => {
    if (this.handleValidate()) {
      const enc = new Base64();
      const encodeUserName = enc.encode(this.state.loginFormInitialValues.user);
      const encodePassword = enc.encode(this.state.copyPassword);
      const deviceToken = await getStorageData("device_token")
      if (this.state.loginFormInitialValues.rememberMe) {
        setStorageData("BuyerUserEmail", encodeUserName)
        setStorageData("BuyerUserPassword", encodePassword)
      } else {
        removeStorageData("BuyerUserEmail")
        removeStorageData("BuyerUserPassword")
      }
      const body = {
        key: this.state.loginFormInitialValues.user,
        password: this.state.copyPassword,
        device_id : deviceToken
      };
      const header = {
        "Accept": configJSON.validationApiContentType,
        "Content-Type": configJSON.validationApiContentType,
        // token: await getStorageData('authToken')
      };
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage),);
      this.loginApiCallId = requestMessage.messageId;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_login/logins/buyer_login");
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }


  handleValidate = () => {
    let isValid = true;
    if (this.state.loginFormInitialValues.user === "") {
      this.setState({ userLoginError: "please enter Email/Mobile/Username" })
      isValid = false;
    } else {
      this.setState({ userLoginError: "" })
    }

    if (this.state.loginFormInitialValues.password === "") {
      this.setState({ userPasswordError: "please enter password" })
      isValid = false;
    } else {
      this.setState({ userPasswordError: "" })
    }
    return isValid;
  }

  handleUserInputChange = (event: any) => {
    this.setState({ 
      loginFormInitialValues: { ...this.state.loginFormInitialValues, user: event.target?.value }
    });
  }

  handlePasswordChange = (event: any) => {
    const value = event.target?.value;
    const { showPassword, copyPassword } = this.state;

    this.setState(prevState => ({
      loginFormInitialValues: {
        ...prevState.loginFormInitialValues,
        password: value
      }
    }));

    let updatedCopyPassword = '';
    if (showPassword) {
      updatedCopyPassword = value;
    } else {
      if (value) {
        updatedCopyPassword = copyPassword + value.split("*").join("");
      } else {
        updatedCopyPassword = "";
      }
    }
    this.setState({
      copyPassword: updatedCopyPassword,
    });
  };


  handlePasswordKey = (event: any) => {
    const { showPassword, copyPassword } = this.state;
    const keyCode = event.keyCode || event.which;
    if (keyCode == 8) {
      const position1 = event.target.selectionStart;
      if (!showPassword) {
        let value = copyPassword.split("")
        value.splice(position1 - 1, 1)
        this.setState({ copyPassword: value.join("") })

      }
    }
  }

  handleRememberMeChange = (event: any) => {
    this.setState({
      loginFormInitialValues: { ...this.state.loginFormInitialValues, rememberMe: !this.state.loginFormInitialValues.rememberMe }
    });
  }


  goToSignupPage = () => {
    this.props.navigation.navigate('AccountRegistration');
  }

  goToBuyerLogin = () => {
    this.props.navigation.navigate('Login');
  }

  goToSellerLogin = () => {
    this.props.navigation.navigate('GetStartedLogin');
  }

  navigateToBuyerLogin = () => {
    this.props.navigation.navigate('BuyerLogin');
  }

  navigateToSellerLogin = () => {
    this.props.navigation.navigate('SellerLogin');
  }

  navigateToSignupPage = () => {
    this.props.navigation.navigate('GetStartedSignup');
  }

  closeErrorPopup = () => {
    this.setState({ displayPopup: false });
    this.setState({ SuccessResponsePopup: false })
  }

  connectGoogle = () => {
    const googleResult = new firebase.auth.GoogleAuthProvider();
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)
    googleResult.setCustomParameters({signInFlow: 'forceRefresh'});
    googleResult.addScope("profile");
    googleResult.addScope("email");
    firebase
      .auth()
      .signInWithPopup(googleResult)
      .then((result) => {
        if (result.credential && result.user && result.additionalUserInfo) {
          this.googleSignupApi(result)
        }
      })      
  };



  // on signup click
  googleSignupApi = async(response: any) => {
    const deviceToken = await getStorageData("device_token")
    const header = {
      "Content-Type": 'application/json',
    };
    const raw = {
      "access_token": `${response.credential.accessToken}`,
      "role_id": 1,
      device_id : deviceToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.loginGoogleCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleLoginEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(raw)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleGoogleSignupResponse = async (responseJson: any) => {
    if (responseJson.meta && responseJson.meta.token) {
      await setStorageData('buerLoginToken', responseJson.meta.token);
      this.getBuyerProfileData()
    } else if (responseJson && responseJson?.error ) {
      this.setState({ displayPopup: true });
      this.setState({ errorMessage: responseJson?.error });
      this.sendLoginFailMessage();
    }
  }  

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (apiRequestCallId) {
        case this.loginApiCallId:
          this.setLoginApiCall(responseJson)
          break;
        case this.loginGoogleCallId:
          this.setLoginGoogleCall(responseJson)
          break;
        case this.getBuyerProfileApiId:
          this.setBuyerProfileCall(responseJson)
          break;
      }

      this.handleAPIcalls(apiRequestCallId, responseJson, errorReponse)
      this.handleOtherAPIcalls(apiRequestCallId, responseJson, errorReponse)
      this.handleAssemblerAPIcalls(apiRequestCallId, responseJson, errorReponse)
    }
    // Customizable Area End
  }

  // Customizable Area Start

  setLoginApiCall = async(responseJson: any) => {
    if (responseJson && responseJson?.errors && responseJson?.errors[0]?.failed_login) {
      this.setState({ displayPopup: true });
      this.setState({ errorMessage: responseJson?.errors[0]?.failed_login });
      this.sendLoginFailMessage();
    }
    else {
      this.setState({ SuccessResponsePopup: true })
      this.saveLoggedInUserData(responseJson);
      await setStorageData('buerLoginToken', responseJson?.meta?.token);
      localStorage.setItem('Buyer_Data', JSON.stringify(responseJson));
      this.props.navigation.navigate("BuyerDashboard");
    }
  }

  setLoginGoogleCall = (responseJson: any) => {
    this.handleGoogleSignupResponse(responseJson)
  }

  setBuyerProfileCall = async(responseJson: any) => {
    if (responseJson && responseJson.account && responseJson.account.data) {
      const data = {account: responseJson.account.data.attributes}
      await setStorageData('Buyer_Data', JSON.stringify(data));
      this.props.navigation.navigate("BuyerDashboard");
    }
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  sendByEmailLoginFailMessage() {
    this.setState({ emailModal: true })
  }

  sendByMobileOtpLoginFailMessage() {
    this.setState({ emailModal: true })
  }

  sendByEmailLoginSuccessMessage(responseJson: any) {
    if(responseJson.meta.token){
      this.setSellerLoginToken(responseJson.meta.token);
    }
    if(responseJson?.sendbird_credential !== null && responseJson.meta.admin_verified !== false){
      this.setSendBirdCredentials(responseJson.sendbird_credential.user_id,responseJson.sendbird_credential.access_token,responseJson.meta.token);
    }
    if (responseJson.meta.email_verified && responseJson.meta.phone_verified) {
      this.props.navigation.navigate("SellerDashboard");
    } else {
      this.handleSuccessfulResponseEmail(responseJson);
    }
  }
  setSellerLoginToken = async (token: string) => {
    await setStorageData("singupLogin", token)
  }

  setSendBirdCredentials = async (userId:string,accessToken:string, token:string) =>{
    await setStorageData("sbUserId",userId);
    await setStorageData("sbToken",accessToken);
    await setStorageData("singupLogin",token)
  };

  sendByMobileLoginSuccessMessage(responseJson: { token: string; }) {
    this.setMobileNumberToken(responseJson.token)
    this.props.navigation.navigate("OtpVerify")
  }

  setMobileNumberToken = async (token: string) => {
    await setStorageData("mobileToken", token)
  }
  

  sendByMobileOtpLoginSuccessMessage(responseJson: any) {
    if(responseJson.meta.token){
      this.setSellerLoginToken(responseJson.meta.token);
    }
    if(responseJson?.sendbird_credential !== null && responseJson.meta.admin_verified !== false){
      this.setSendBirdCredentials(responseJson.sendbird_credential.user_id,responseJson.sendbird_credential.access_token,responseJson.meta.token);
    }
    if (responseJson.meta.email_verified && responseJson.meta.phone_verified) {
      this.props.navigation.navigate("SellerDashboard")
    } else {
      this.handleSuccessfulResponseMobile(responseJson);
    }
  }

  sendByMobileLoginFailMessage() {
    this.setState({ emailModal: true })
  }

  handleAPIcalls = (apiRequestCallId: any, responseJson: any, errorReponse: any) => {
    if (apiRequestCallId === this.selllerLoginApiCallId) {
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        this.sendByEmailLoginSuccessMessage(responseJson);
      } else {
        this.sendByEmailLoginFailMessage();
      }
    }
    if (apiRequestCallId === this.sellerLoginMobileApiCallId) {
      if (responseJson && responseJson.token) {
        this.sendByMobileLoginSuccessMessage(responseJson);
      } else {
        this.sendByMobileLoginFailMessage();
      }
    }
    if (apiRequestCallId === this.sellerLoginMobileOtpApiCallId) {
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        this.sendByMobileOtpLoginSuccessMessage(responseJson);
      } else {
        this.sendByMobileOtpLoginFailMessage();
      }
    }
  }

  handleOtherAPIcalls = (apiRequestCallId: any, responseJson: any, errorReponse: any) => {
    if (apiRequestCallId === this.countryCodeApiCallId) {
      this.setState({
        dataSource: responseJson.data,
        selectedValue: this.countryCodesSelected(responseJson.data)
      });
    }
    if (apiRequestCallId === this.apiEmailLoginCallId) {
      if (responseJson && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        this.openInfoPage();
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
        this.sendLoginFailMessage();
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

  handleAssemblerAPIcalls = async(apiRequestCallId: any, responseJson: any, errorReponse: any) => {
    if (apiRequestCallId === this.loginApiCallId) {
      if (responseJson && responseJson?.errors && responseJson?.errors[0]?.failed_login) {
        this.setState({ displayPopup: true });
        this.setState({ errorMessage: responseJson?.errors[0]?.failed_login });
        this.sendLoginFailMessage();
      }
      else {
        this.setState({ SuccessResponsePopup: true })
        this.saveLoggedInUserData(responseJson);
        await setStorageData('buerLoginToken', responseJson?.meta?.token);
        localStorage.setItem('Buyer_Data', JSON.stringify(responseJson));
        this.props.navigation.navigate("BuyerDashboard");
      }
    }
    if (apiRequestCallId === this.validationApiCallId) {
      let arrayholder = responseJson.data;

      if (arrayholder && arrayholder.length !== 0) {
        let regexData = arrayholder[0];

        if (regexData && regexData.email_validation_regexp) {
          this.emailReg = new RegExp(regexData.email_validation_regexp);
        }
      }
    }
  }

  navigateMobileNumber = () => {
    this.props.navigation.navigate('LoginWithMobile')
  }

  handleIsSelect = () => {
    this.setState({ isSelect: !this.state.isSelect })
  }

  selectCountryFlag = (item: any) => {
    let selectedValue = {
      label: item.attributes.emoji_flag,
      value: item.attributes.country_code
    }
    this.setState({ isSelect: !this.state.isSelect, selectedValue })
  }

  countryCodesSelected = (data: any[]) => {
    let defaultCountryCode = {
      label: "",
      value: "",
    }
    if (data.length) {
      const resultData = data.find((item: any) => item.attributes.country_code == "91")
      if (resultData && resultData.attributes.country_code) {
        defaultCountryCode.label = resultData.attributes.emoji_flag
        defaultCountryCode.value = resultData.attributes.country_code
      }
    }
    return defaultCountryCode;
  };

  setError = (stateKey: keyof S, errorMsg: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [stateKey]: errorMsg,
    }));
  };

  handleMobileNumberChange = (event: any) => {
    const { value } = event.target;
    const limitedValue = value.slice(0, 10);
    this.setState({ mobileNumber: limitedValue.trim() }, () => {
      this.validatePhone()
    })
  }

  handleOTPStateMobile = (val: any) => {
    this.setState({ otpStateMobile: val })
    this.setState({ otp: val }, () => {
      this.validateOtp()
    })
  }

  handleEmailUserChange = (event: any) => {
    const { value } = event.target;
    this.setState({ sellerEmailUser: value.trim() }, () => {
      this.validateEmailUser()
    })
  };

  handlePassowrdChange = (event: any) => {
    const { value } = event.target;
    this.setState({ sellerPassword: value.trim() }, () => {
      this.validatePassword()
    })
  };

  validatePhone = (): boolean => {
    const { mobileNumber } = this.state;
    if (!mobileNumber || mobileNumber.length == 0) {
      this.setError("phoneError", configJSON.phoneBalnkError);
      return false;
    } else if (!mobileNumber || mobileNumber.trim().length !== 10) {
      this.setError("phoneError", configJSON.phoneError);
      return false;
    }
    this.setState({ phoneError: "" });
    return true;
  };

  validateOtp = (): boolean => {
    const { otp } = this.state;
    if (!otp || otp.length !== 6) {
      this.setError("otpError", configJSON.otpError);
      return false;
    }
    this.setState({ otpError: "" });
    return true;
  }

  validateEmailUser = (): boolean => {
    const { sellerEmailUser } = this.state;
    if (!sellerEmailUser || sellerEmailUser.length == 0) {
      this.setError("sellerEmailUserError", configJSON.sellerEmailUserBalnkError);
      return false;
    } else if (!sellerEmailUser || sellerEmailUser.length < 3) {
      this.setError("sellerEmailUserError", configJSON.sellerEmailUserError);
      return false;
    }
    this.setState({ sellerEmailUserError: "" });
    return true;
  }

  validatePassword = (): boolean => {
    const { sellerPassword } = this.state;
    if (!sellerPassword || sellerPassword.length == 0) {
      this.setError("sellerPasswordError", configJSON.sellerPasswordBalnkError);
      return false;
    }
    this.setState({ sellerPasswordError: "" });
    return true;
  };

  checkValidationMobile = async (e: any) => {
    e.preventDefault();
    let phone = this.validatePhone();
    const phoneNumber = this.state.selectedValue.value + this.state.mobileNumber;
    if (phone) {
      if (this.state.mobileNumber && this.state.selectedValue) {
        await setStorageData("phoneNumber", phoneNumber);
        this.sellerLoginMobileApi()
      }
    }
  }

  checkValidationOtpMobile = () => {
    let otp = this.validateOtp();
    if (otp) {
      this.sellerLoginMobileOtpApi();
    }
  }

  checkValidationEmailLogin = (e: any) => {
    e.preventDefault()
    let emailUser = this.validateEmailUser();
    let password = this.validatePassword();
    const enc = new Base64();
    const encodeUserName = enc.encode(this.state.sellerEmailUser);
    const encodePassword = enc.encode(this.state.sellerPassword);
    if (this.state.isChecked) {
      setStorageData("SellerUserEmail", encodeUserName)
      setStorageData("SellerUserPassword", encodePassword)
    } else {
      removeStorageData("SellerUserEmail")
      removeStorageData("SellerUserPassword")
    }
    if (emailUser && password) {
      this.sellerLoginApiCall()
    }
  }

  checkSellerDataFromLocalStorage = async () => {
    await removeStorageData("sellerLoginToken");
    await removeStorageData("successMessages");
    await removeStorageData("successMessagesEmail");
    const SellerUserEmail = await getStorageData("SellerUserEmail")
    const SellerUserPassword = await getStorageData("SellerUserPassword")
    const enc = new Base64();
    if (SellerUserEmail) {
      const sellerEmail = enc.decode(SellerUserEmail);
      this.setState({
        sellerEmailUser: sellerEmail
      })
    }
    if (SellerUserPassword) {
      const sellerPassword = enc.decode(SellerUserPassword);
      this.setState({
        sellerPassword: sellerPassword
      })
    }
    if (SellerUserEmail && SellerUserPassword) {
      this.setState({
        isChecked: true
      })
    }
  }

  checkBuyerDataFromLocalStorage = async () => {
    const BuyerUserEmail = await getStorageData("BuyerUserEmail")
    const BuyerUserPassword = await getStorageData("BuyerUserPassword")
    const enc = new Base64();
    if (BuyerUserEmail) {
      const buyerEmail = enc.decode(BuyerUserEmail);
      this.setState({ 
        loginFormInitialValues: { ...this.state.loginFormInitialValues, user: buyerEmail }
      });
    }
    if (BuyerUserPassword) {
      const buyerPassword = enc.decode(BuyerUserPassword);
      this.setState({ 
        loginFormInitialValues: { ...this.state.loginFormInitialValues, password: buyerPassword },
        copyPassword: buyerPassword
      });
    }
    if (BuyerUserEmail && BuyerUserPassword) {
      this.setState({ 
        loginFormInitialValues: { ...this.state.loginFormInitialValues, rememberMe: true}
      });
    }
  }

  emailModalClose = () => {
    this.setState({
      emailModal: false
    })
  }

  resendModalClose = () => {
    this.setState({
      resendModal: false
    })
  }

  emailModalCloseNavigate = async () => {
    this.setState({
      emailModalNavigate: false
    })
    await setStorageData("loginRedirectVerification", "Seller Succefully Redirect to verify Mobile");
    this.props.navigation.navigate('SellerEmailMobileVarification');
  }

  handleIsChecked = () => {
    this.setState({ isChecked: !this.state.isChecked })
  }

  handleSuccessfulResponseEmail = async (responseJson: any) => {
    const singupLogin = responseJson.meta.token;
    const PhoneToken = responseJson.meta.phone_token;
    const EmailToken = responseJson.meta.email_token;
    const full_mobile_number = responseJson.account.full_phone_number;
    const Email = responseJson.account.email;
    const email_verified = responseJson.meta.email_verified;
    const phone_verified = responseJson.meta.phone_verified;

    this.setLocalStorageItems(singupLogin, PhoneToken, EmailToken, full_mobile_number, Email, email_verified, phone_verified);
    await setStorageData("loginRedirectVerification", "Seller Succefully Redirect to verify Mobile");
    this.props.navigation.navigate('SellerEmailMobileVarification');
  };

  reSendSellerMobileOtp = async () => {
    const phoneNmber = await getStorageData("phoneNumber");
    this.setState({ otpStateMobile: "", otp: "" })
    if (phoneNmber) {
      this.sellerLoginMobileApi()
      this.setState({
        resendModal: true
      })
    }
  }

  handleSuccessfulResponseMobile = async (responseJson: any) => {
    const singupLogin = responseJson.meta.token;
    const PhoneToken = responseJson.meta.phone_token;
    const EmailToken = responseJson.meta.email_token;
    const full_mobile_number = responseJson.meta.full_phone_number;
    const Email = responseJson.meta.email;
    const email_verified = responseJson.meta.email_verified;
    const phone_verified = responseJson.meta.phone_verified;

    this.setLocalStorageItems(singupLogin, PhoneToken, EmailToken, full_mobile_number, Email, email_verified, phone_verified);
    await setStorageData("loginRedirectVerification", "Seller Succefully Redirect to verify Mobile");
    this.props.navigation.navigate('SellerEmailMobileVarification');
  };

  setLocalStorageItems = async (singupLogin: any, PhoneToken: any, EmailToken: any, phoneNumber: any, Email: any, email_verified: any, phone_verified: any) => {
    await setStorageData("singupLogin", singupLogin);
    await setStorageData("mobileTokenSeller", PhoneToken);
    await setStorageData("EmailTokenSeller", EmailToken);
    await setStorageData("phoneNumber", phoneNumber);
    await setStorageData("email", Email);
    if (email_verified) {
      await setStorageData("email_verified", '1');
      await setStorageData("successMessagesEmail", 'Email verified Successfully')
    } else {
      await setStorageData("email_verified", '0');
      await removeStorageData("successMessagesEmail")
    }
    if (phone_verified) {
      await setStorageData("phone_verified", '1');
      await setStorageData("successMessages", 'Phone Number Confirmed Successfully')
    } else {
      await setStorageData("phone_verified", '0');
      await removeStorageData("successMessages")
    }
  };

  makeRemoteRequest = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountryCodes
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetCountryCodes
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetCountryCodesType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sellerLoginApiCall = async() => {
    const deviceToken = await getStorageData("device_token")
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
      key: this.state.sellerEmailUser,
      password: this.state.sellerPassword,
      device_id : deviceToken
    }

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.selllerLoginApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sellerLoginApiUrl
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  sellerLoginMobileApi = async () => {
    const phoneNumber = await getStorageData("phoneNumber")
    if (phoneNumber) {
      const deviceToken = await getStorageData("device_token")
      const headers = {
        "Content-Type": configJSON.validationApiContentType,
      };
      const full_mobile_number = phoneNumber;
      const body = {
        "data": {
          "attributes": {
            "full_phone_number": full_mobile_number,
            "device_id" : deviceToken
          }
        }
      }

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.sellerLoginMobileApiCallId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerLoginMobileApi
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
  }

  sellerLoginMobileOtpApi = async () => {
    const mobileTokenValue = await getStorageData("mobileToken")
    if (mobileTokenValue) {

      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        "token": mobileTokenValue
      };
      const body = {
        "otp_code": this.state.otpStateMobile
      }

      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.sellerLoginMobileOtpApiCallId = getValidationsMsg.messageId;

      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sellerLoginMobileOtpApi
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.loginAPiMethod
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }
  }

  getBuyerProfileData: any = async () => {
    const buyertoken = await getStorageData("buerLoginToken");
    if (buyertoken) {
      const header = { "token": buyertoken };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getBuyerProfileApiId = requestMessage.messageId;

      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.buyerProfileAPIEndpoint
      );

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  // Customizable Area End
}
