// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "framework/src/Utilities";
import { Message } from "../../../framework/src/Message";

export interface ProductId {
    favourite_id: number
    product_id: number
  }

export interface Disclaimer {
    disclaimer: string
    status: string
}

export interface LiveStreamData {
    data: Data
  }
  
  export interface Data {
    id: string
    type: string
    attributes: Attributes
  }
  
  export interface Attributes {
    id: number
    title: string
    description: string
    explicit_content: boolean
    mute_words: boolean
    token: any
    channel_name: any
    u_id: any
    comment_section: any
    tags: Tag[]
    mute_words_arr: string[]
    account_id: number
    stream_url: string
    stream_key: string
    stream_type: string
    status: string
    start_date: string
    start_time: string
    created_at: string
    updated_at: string
    start_date_time: string
    thumbnail: string
    host_name: string
    host_bio: string
    host_image: string
    video: string
  }
  
  export interface Tag {
    id: number
    name: string
    created_at: string
    updated_at: string
    taggings_count: number
  }
  
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
   
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    subCategoryData: any
    subCategoryID: string
    wishListProduct: ProductId[]
    message: string;
    messageModel: boolean;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    liveEventModal: boolean;
    stayTunedBox: boolean;
    notifyDialog: boolean;
    disclaimerData: Disclaimer;
    hostName: string;
    hostProfileImage: string;
    hostBio: string;
    startdateTime: string;
    productTitle: string;
    productDescription: string;
    thumbnail: string;
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class BuyersLiveStreamingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getSubCategoryApiId: string = ""
    apiAddToWishList: string = '';
    getSubCategoryDataApiId: string = ""
    apiDeleteFromWishList: string = ''
    timerId: any;
    getDisclaimerApiCallId: string = '';
    getLiveStreamDataApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.timerId = null
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            subCategoryData: [],
            subCategoryID: '',
            wishListProduct: [],
            message: '',
            messageModel: false,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 5,
            liveEventModal: false,
            stayTunedBox: false,
            notifyDialog: false,
            disclaimerData: {
                disclaimer: '',
                status: ''
            },
            hostBio: '',
            hostName: '',
            hostProfileImage: '',
            productDescription: '',
            productTitle: '',
            startdateTime: '',
            thumbnail: ''
            // Customizable Area End
        };

    }


    // Customizable Area Start

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            switch (apiRequestCallId) {
                case this.getSubCategoryApiId:
                    this.setSubCategory(responseJson);
                    break;
                case this.getSubCategoryDataApiId:
                    this.setSubCategoryData(responseJson);
                    break;
                case this.apiAddToWishList:
                    this.checkItemAddedWishList(responseJson);
                    break;
                case this.apiDeleteFromWishList:
                    this.checkItemRemovedWishList(responseJson);
                    break;
                case this.getDisclaimerApiCallId:
                    this.setDisclaimerData(responseJson);
                    break;
                case this.getLiveStreamDataApiCallId:
                    this.setLiveStreamData(responseJson);
                    break;
                default:
                    break;
            }

        }
    }

    async componentDidMount() {
        this.getBuyerSubcategoryData();
        this.getDisclaimer();
        this.getLiveStreamData();
        this.timerId = setInterval(() => {
            this.updateTimer();
          }, 1000);
    }

    async componentWillUnmount() {
       if (this.timerId) {
        clearInterval(this.timerId)
       }
    }    

    setSubCategory = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.setState({ subCategoryID: responseJson.data.map(
                (item: any) => item.id).join(',')
            }, () => { this.getSuggestionData(); })
        }
    }

    setSubCategoryData = (responseJson: any) => {
        if (responseJson && responseJson.catalogues && responseJson.catalogues.data) {
            this.setState({ subCategoryData: responseJson.catalogues.data
            })
        }
        if (responseJson && responseJson.wishlist_items && responseJson.wishlist_items.product_ids) {
            this.setState({ wishListProduct: responseJson.wishlist_items.product_ids
            })
        }
    }

    checkItemAddedWishList = (responJson: any) => {
        if (responJson.message) {
            this.setState({
                messageModel: true,
                message: responJson.message,
            })
            this.getSuggestionData();
        } else {
            this.setState({
                message: responJson.errors,
                messageModel: true
            })
        }
    }

    checkItemRemovedWishList = (responJson: any) => {
        if (responJson.message) {
            this.setState({
                message: responJson.message,
                messageModel: true
            })
            this.getSuggestionData();
        } else {
            this.setState({
                message: responJson.errors[0], messageModel: true
            })
        }
    }

    setDisclaimerData = (responJson: Disclaimer) => {
        if (responJson.disclaimer) {
            this.setState({ disclaimerData: { disclaimer: responJson.disclaimer[0], status: responJson.status } })
        }
    }

    setLiveStreamData = (responJson: LiveStreamData) => {
        if (responJson.data) {
            this.setState({
                hostBio: responJson.data.attributes.host_bio,
                hostName: responJson.data.attributes.host_name,
                hostProfileImage: responJson.data.attributes.host_image,
                productDescription: responJson.data.attributes.title,
                productTitle: responJson.data.attributes.description,
                startdateTime: responJson.data.attributes.start_date_time,
                thumbnail:responJson.data.attributes.thumbnail
            },()=>{
                this.setTimerforSchedule()
            })
        }
    }

    setTimerforSchedule = () => {
        const currentDate = new Date();
        const givenDate = new Date(this.state.startdateTime);
        const differenceInMilliseconds:number = Number(givenDate) - Number(currentDate);
        const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        const hours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);

        this.setState({
            days, hours, minutes, seconds
        })
    }

    closeMessageModal = () => {
        this.setState({
            messageModel: false
        })
    }

    handleStatTunedBox = () => {
        this.setState({
            stayTunedBox: !this.state.stayTunedBox
        })
    }

    handleNotifyDialogBox = () => {
        this.setState({
            notifyDialog: !this.state.notifyDialog
        })
    }

    checkProductStatus = (producArray: any, ID: any) => {
        const index = producArray.findIndex(
            (item: any) => item.product_id === ID);
        if (index !== -1) {
            return true;
        } else {
            return false;
        }
    }

    deleteProductItem = (producArray: any, ID: any) => {
        const index = producArray.find((item: any) => 
            item.product_id === ID).favourite_id;

        if (index) {
            this.deleteWishListItem(index)
        } else {
            return false;
        }
    }

    updateTimer() {
        const { days, hours, minutes, seconds } = this.state;
        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            clearInterval(this.timerId);
            this.handleLiveEventModal();
        } else {
            this.setState(prevState => {
                let newDays = prevState.days;
                let newHours = prevState.hours;
                let newMinutes = prevState.minutes;
                let newSeconds = prevState.seconds - 1;

                if (hours>=24) {
                    newDays = Math.floor(hours/24)
                    newHours = newHours - (24*newDays)
                }

                if (newSeconds < 0) {
                    newSeconds = 59;
                    newMinutes -= 1;
                }
                if (newMinutes < 0) {
                    newMinutes = 59;
                    newHours -= 1;
                }
                if (newHours < 0) {
                    newHours = 23;
                    newDays -= 1;
                  }

                return {
                    days: newDays,
                    hours: newHours,
                    minutes: newMinutes,
                    seconds: newSeconds
                };
            });
        }
    }

    handleLiveEventModal = () => {
        this.setState({ liveEventModal: !this.state.liveEventModal })
    }

    getBuyerSubcategoryData: any = async () => {
        const buyertoken = await getStorageData("buerLoginToken");
        if (buyertoken) {
            const header = { "token": buyertoken,
            };

            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

            this.getSubCategoryApiId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
            );

            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.getSubCategoryAPIEdPoint
            );

            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.validationApiMethodType
            );

            runEngine.sendMessage(requestMessage.id, 
                requestMessage);
        }
    }

    getSuggestionData: any = async () => {
        const buyertoken = await getStorageData("buerLoginToken");
        if (buyertoken) {
            const header = {
                "token": buyertoken,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getSubCategoryDataApiId = requestMessage.messageId;

            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSubCategoryDataAPIEdPoint}${this.state.subCategoryID}`
            );

            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType);

            runEngine.sendMessage(requestMessage.id, 
                requestMessage);
        }
    }

    updateWishListItem = async (id: number, type: string) => {
        const buyertoken = await getStorageData("buerLoginToken");
        if (buyertoken) {
            const header = {
                "Content-Type": "application/json",
                "token": buyertoken,
            };
            if (type == "catalogue") { type = "product" }
            const body = {
                "data": {
                    "favouriteable_id": id,
                    "type": type,
                }
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage));

            this.apiAddToWishList = requestMessage.messageId;


            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage), 
                JSON.stringify(body));

            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),configJSON.favouriteItemEndPoint
            );
            runEngine.sendMessage(
                requestMessage.id, 
                requestMessage
            );
        }
    }

    deleteWishListItem = async (id: number) => {
        const buyertoken = await getStorageData("buerLoginToken");
        if (buyertoken) {
            const header = {
                "Content-Type": "application/json",
                "token": buyertoken,
            };

            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage)
            );

            this.apiDeleteFromWishList = requestMessage.messageId;

            requestMessage.addData(getName(
                MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage), 
                configJSON.delete
            );

            requestMessage.addData(getName(
                MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.favouriteItemEndPoint}/${id}`);

            runEngine.sendMessage(
                requestMessage.id, requestMessage
            );
        }
    }

    getDisclaimer = () => {
        const header = {
            "Content-Type": "application/json"
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getDisclaimerApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.disclaimerEndpoint);

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header));

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getLiveStreamData = async() => {
        const buyertoken = await getStorageData("buerLoginToken");
        const header = {
            "Content-Type": "application/json",
            "token": buyertoken,
        };
        const queryParameters = new URLSearchParams(window.location.search)
        const searchName = queryParameters.get("id")
        if (searchName !== null) {
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getLiveStreamDataApiCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_livestreaming/live_streams/${searchName}/get_buyer_stream`);

            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header));

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.validationApiMethodType);

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    // Customizable Area End
}