import React from "react";
// Customizable Area Start
import BuyersLiveStreamingController,{Props,configJSON} from "./BuyersLiveStreamingController.web";
import { createTheme,  withStyles, Theme } from "@material-ui/core/styles";
import { Box,TextField,InputAdornment,Grid,Typography,Button,IconButton,Divider} from "@material-ui/core";
import {searchIcon,backGroundImage,profileImage,archiveIc,NotifyIc,archiveBlankIc,CloseIc,shareIC,sendIC,rightArrow,rightWithCircle,chatIc,star,tShirt,facebookIC,whatsappIC,twitterIC,threeDotIC} from "./assets";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import StayTuned from "../../../components/src/StayTuned.web";
const baseURL = require("../../../framework/src/config.js").baseURL

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    searchInventory:{
        width:"479px"
    },
    buyersLiveStreaming:{
        marginTop:"10em"
    },
    searchContainer:{
        display:"flex",
        margin: '0 10px'
    },
    streamMainContainer:{
        margin:"2em 0em"
    },
    streamContainer:{
        justifyContent: "center",
        position: "relative" as const, 
        margin: '0 auto',
        maxWidth: '640px'
    },
    countDown:{
        position: "absolute" as const,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    liveWrap: {
        position: "absolute",
        top: "50%",
        borderRadius: '12px',
        background: 'rgba(62, 62, 62, 0.62)',
        padding: '20px 64px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        '@media (max-width: 676px)': {
            padding: '12px 30px',
        }
    },
    noLiveImg:{
        position: "unset",
        transform: "unset"
    },
    hoursWrap: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: "12px"
    },
    daysWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    liveText: {
        color: '#FFF',
        fontFamily: 'Poppins !important',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: '0.25px',
    },
    timerClock: {
        color: '#F5EA16',
        fontFamily: 'Digital Numbers !important',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: '0.25px',
    },
    hostInfoMainContainer:{
        display:"flex",
        justifyContent:"center",
        margin:"2em 0"
    },
    hostInfoContainer:{
        borderRadius: '10px',
        border: '2px solid #D4D4D4',
        background: '#FFF',
        width: '100%', 
        maxWidth: '633px',
        height:"100%",
        flexShrink: 0,
        padding:"1em 1.5em"
    },
    title:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    profileSection: {
        display: "flex"
    },
    profileImage: {
        width: '48px',
        height: '48px',
        borderRadius: '24px',
        objectFit: 'cover'
    },
    hostName: {
        marginLeft: '1em',
        color: '#000',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: " 0.25px"
    },
    starRating: {
        marginLeft: '1em',
        display: "flex",
        alignItems: 'center',
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: " 0.25px"
    },
    hostBio: {
        margin: "1em 0em",
        overflow: "hidden" as const,
        textOverflow: "ellipsis",
        display: "-webkit-box" as const,
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical" as const,
        color: '#000',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: " 0.25px"
    },
    schedueledtimeConatiner: {
        borderRadius: "4px",
        background: "#616161",
        marginTop: "1em",
        display: "inline",
        padding: "0.2em 0.2em"
    },
    scheduledDateText: {
        color: '#FFF',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: " 0.25px"
    },
    productName: {
        marginTop: "1em",
        color: '#000',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: " 0.25px"
    },
    productDescription: {
        marginTop: "1em",
        overflow: "hidden" as const,
        textOverflow: "ellipsis",
        display: "-webkit-box" as const,
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical" as const,
        color: '#000',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: " 0.25px"
    },
    notifyMe: {
        width: '10em',
        height: '3em',
        background: '#F5EA16',
        borderRadius: "25px",
        color: '#000',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        cursor: "pointer",
        textTransform: "none" as const,
        marginTop: "2em",
        "&:hover": {
            background: '#F5EA16',
        }
    },
    addTocartBtn: {
        width: '10em',
        height: '3em',
        background: '#F5EA16',
        borderRadius: "25px",
        color: '#000',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '11px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        cursor: "pointer",
        textTransform: "none" as const,
        marginTop: "0.5em",
        "&:hover": {
            background: '#F5EA16',
        }
    },
    followBtn: {
        padding: '3px 14px',
        background: '#F5EA16',
        borderRadius: "25px",
        color: '#000',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        cursor: "pointer",
        textTransform: "none" as const,
        "&:hover": {
            background: '#F5EA16',
        }
    },
    liveStreamModalText: {
        color: '#000',
        fontFamily: 'Poppins !important',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: '0.25px',
        marginLeft: '15px'
    },
    liveStreamModalTextWrap: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '30px'
    },
    productLeftSide: {
        maxWidth: '296px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '@media (max-width: 960px)': {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            height: '100%'
        },
        '@media (max-width: 1220px)': {
            width: '50%',
            maxWidth: '50%',
            flexDirection: 'column',
            flex: '0 0 50%',
            order: 1,
        },
        '@media (max-width: 676px)': {
            width: '100%',
            maxWidth: '100%',
            flex: '0 0 100%',
        }
    },
    mainProductWrap: {
        maxWidth: '269px',
        width: '100%',
        maxHeight: '205px',
        height: '100%',
        border: '1px solid #C8C8C8',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        marginBottom: '12px',
        '@media (max-width: 960px)': {
            marginRight: '12px',
        },
    },
    productWrap: {
        display: 'flex',
        padding: '10px'
    },
    innerImgWrap: {
        width: '125px',
        height: '165px',
        position: 'relative'
    },
    productImg: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        display: 'block',
        objectPosition: 'center',
        borderRadius: '15px'
    },
    productTitle: {
        color: '#000',
        fontFamily: 'Poppins !important',
        fontSize: '9.467px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '11.045px',
        letterSpacing: '0.197px',
    },
    liveproductDescription: {
        color: '#AAA',
        fontFamily: 'Poppins !important',
        fontSize: '9.467px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '11.834PX',
        letterSpacing: '0.197px',
        maxWidth: '100px',
        width: '100%'
    },
    socialIconInnerWrap: {
        padding: '6px',
        borderRadius: '50%',
        backgroundColor: '#E4E4E4',
        height: '25px',
        width: '25px',
        margin: '0 3px',
        marginTop: "2em",
    },
    archievBtn: {
        position: 'absolute',
        width: '18px',
        height: '18px',
        borderRadius: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        top: '5px',
        right: '5px',
        padding: '5px'
    },
    contentWrap: {
        width: '100px',
        margin: '0 10px'
    },
    chatWrap: {
        maxWidth: '386px',
        maxHeight: '563px',
        width: '100%',
        height: '100%',
        border: '2px solid #D4D4D4',
        borderRadius: '10px',
        overflow: 'auto',
        padding: '20px 32px'
    },
    centerImgMainWrap: {
        maxWidth: '663px',
        width: '100%',
        '@media (max-width: 1220px)': {
            width: '100%',
            maxWidth: '100%',
            flex: '0 0 100%',
            padding: '0 15px',
            order: 2
        }
    },
    chatRightSide: {
        maxWidth: '412px',
        width: '100%',
        '@media (max-width: 1220px)': {
            width: '50%',
            maxWidth: '50%',
            flex: '0 0 50%',
            order: 1,
            display: 'flex',
            justifyContent: 'center',
        },
        '@media (max-width: 676px)': {
            width: '100%',
            maxWidth: '100%',
            flex: '0 0 100%',
        }
    },
    chatTitleWrap: {
        display: 'flex',
        justifyContent: "space-between",
    },
    chatTitle: {
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins !important',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    chatItemWrap:{
        display: 'flex',
        borderBottom: '2px solid #DCDCDC',
        paddingBottom: '22px',
        paddingTop: '10px',
    },
    chatImg: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
    },
    chatContentWrap:{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '15px',
    },
    chatMessage: {
        paddingTop: '10px',
        color: '#646464',
        fontFamily: 'Poppins !important',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    chatUsername: {
        color: '#000',
        fontFamily: 'Poppins !important',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
    },
    commentWrap: {
        maxWidth: '386px',
        width: '100%',
        border: '2px solid #D4D4D4',
        borderRadius: '10px',
        overflow: 'auto',
        margin: '20px 0'
    },
    commentInventory:{
        maxWidth:"350px",
        width: '100%',
        padding: '16px 15px 20px',
        '& .MuiInput-underline:before': {
            borderBottom: 'none'
          },
          '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none'
          },
          '& .MuiInput-underline:after': {
            borderBottom: 'none'
          },
    },
    dialogBackground: {
        "& .MuiBackdrop-root": {
            background: '#0000004D',
            backdropFilter: "blur(2px)"
        }
    },
    closeBtn: {
        cursor: 'pointer',
        position: 'absolute',
        right: '32px',
        '@media(max-width: 697px)': {
            right: '12px',
        },
    },
    vectorBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '8px'
    },
    notifyDialog: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '32px',
        '@media(max-width: 697px)': {
            padding: '16px',
        },
        height: '260px',
    },
    offerTitleBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
});
// Customizable Area End

export class BuyersLiveStreaming extends BuyersLiveStreamingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes } = this.props
        const { days, hours, minutes, seconds, hostBio, hostName, hostProfileImage, productDescription, productTitle, startdateTime, thumbnail, disclaimerData } = this.state
        let settings = {
            dots: false,
            infinite: true,
            slidesToScroll: 1,
            centerMode: true,
            variableWidth: true,
        };
        return (
            <Box className={classes.buyersLiveStreaming} data-test-id="buyersLiveStreaming">
                <Grid container style={{ justifyContent: 'space-evenly' }}>
                    <Grid item xs={12} md={3} className={classes.productLeftSide}></Grid>
                    <Grid item xs={12} md={6} className={classes.centerImgMainWrap}>
                        <Box className={classes.searchContainer}>
                            <TextField
                                variant="outlined"
                                data-test-id="searchBox"
                                className={classes.searchInventory}
                                placeholder={configJSON.searchFlixoo}
                                onClick={()=>this.handleStatTunedBox()}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment data-test-id="searchIcon"
                                            position="start" >
                                            <img
                                                style={{ cursor: "pointer" }}
                                                src={searchIcon}
                                                alt="searchIcon"
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.chatRightSide}></Grid>
                </Grid>
                
                <Box className={classes.streamMainContainer}>
                    <Grid container style={{justifyContent: 'space-evenly'}}>
                        <Grid item xs={12} md={3} className={classes.productLeftSide}>
                            <Box className={classes.mainProductWrap}>
                                <Box className={classes.productWrap}>
                                    <Box style={{ borderRadius: '15px' }}>
                                        <Box className={classes.innerImgWrap}>
                                            <div>
                                                <img className={classes.productImg} src={tShirt} alt="t-shirt" />
                                            </div>
                                            <IconButton
                                                className={classes.archievBtn}>
                                                <img style={{width: '12px', height: '12px'}} src={archiveIc} alt="archive" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box className={classes.contentWrap}>
                                        <Typography className={classes.productTitle}>Furniture & Interior</Typography>
                                        <Typography className={classes.liveproductDescription}>Premium Furniture & Interior, Suitable for Drawing room and king size bed room.</Typography>
                                        <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Box className={classes.socialIconInnerWrap}>
                                                <img style={{ width: '12px', height: '12px' }} src={facebookIC} alt="" />
                                            </Box>
                                            <Box className={classes.socialIconInnerWrap}>
                                                <img style={{ width: '12px', height: '12px' }} src={whatsappIC} alt="" />
                                            </Box>
                                            <Box className={classes.socialIconInnerWrap}>
                                                <img style={{ width: '12px', height: '12px' }} src={twitterIC} alt="" />
                                            </Box>
                                        </Box>
                                        <Box>
                                        <Button data-test-id="addToCart-1" onClick={()=>this.handleStatTunedBox()} className={classes.addTocartBtn}>Add to cart</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={classes.mainProductWrap}>
                                <Box className={classes.productWrap}>
                                    <Box 
                                        style={{
                                            borderRadius: '15px'
                                            }}>
                                        <Box className={classes.innerImgWrap}>
                                            <div><img className={classes.productImg} 
                                                src={tShirt} alt="t-shirt" />
                                            </div>
                                            <IconButton className={classes.archievBtn}>
                                                <img style={{width: '12px', 
                                                    height: '12px'}} src={archiveIc} alt="archive" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box 
                                        className={classes.contentWrap}>
                                        <Typography className={classes.productTitle}>Furniture & Interior</Typography>
                                        <Typography
                                            className={
                                                classes.liveproductDescription
                                                }>Premium Furniture & Interior, Suitable for Drawing room and king size bed room.</Typography>
                                        <Box style={{display: 'flex', 
                                            justifyContent: 'space-between'}}>
                                            <Box className={classes.socialIconInnerWrap}>
                                                <img 
                                                    style={{ width: '12px', height: '12px' }} src={facebookIC} alt="" />
                                            </Box>
                                            <Box className={classes.socialIconInnerWrap}>
                                                <img style={{ width: '12px', 
                                                    height: '12px' }} src={whatsappIC} alt="" />
                                            </Box>
                                            <Box className={classes.socialIconInnerWrap}>
                                                <img style={{ width: '12px', height: '12px' }} 
                                                    src={twitterIC} 
                                                    alt="" />
                                            </Box>
                                        </Box>
                                        <Box>
                                        <Button 
                                            data-test-id="addToCart-2"
                                            onClick={() => this.handleStatTunedBox()}
                                            className={classes.addTocartBtn}>Add to cart</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={classes.mainProductWrap}>
                                <Box className={classes.productWrap}>
                                    <Box style={{ borderRadius: '15px' }}>
                                        <Box className={classes.innerImgWrap}>
                                            <div>
                                                <img 
                                                    className={classes.productImg} 
                                                    src={tShirt} 
                                                    alt="t-shirt" />
                                            </div>
                                            <IconButton
                                                className={classes.archievBtn}>
                                                <img style={{width: '12px', 
                                                    height: '12px'}} src={archiveIc} alt="archive" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box className={classes.contentWrap}>
                                        <Typography 
                                            className={classes.productTitle}>Furniture & Interior</Typography>
                                        <Typography 
                                            className={
                                                    classes.liveproductDescription}>Premium Furniture & Interior, Suitable for Drawing room and king size bed room.</Typography>
                                        <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                                            <Box className={classes.socialIconInnerWrap}>
                                                <img style={{ width: '12px', height: '12px' }} 
                                                    src={facebookIC} alt="" />
                                            </Box>
                                            <Box className={classes.socialIconInnerWrap}>
                                                <img
                                                 style={{ width: '12px', height: '12px' }} src={whatsappIC} alt="" />
                                            </Box>
                                            <Box className={classes.socialIconInnerWrap}>
                                                <img style={{
                                                    width: '12px',
                                                    height: '12px'
                                                }} src={twitterIC}
                                                    alt="" />
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Button data-test-id="addToCart-3" onClick={()=>this.handleStatTunedBox()} 
                                                className={classes.addTocartBtn}>
                                                Add to cart
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.centerImgMainWrap}>
                            <Box className={classes.streamContainer}>
                               {thumbnail && <img style={{width: '100%', filter: "blur(5px)"}} src={thumbnail} alt="Background" />} 
                                <div className={thumbnail ? classes.liveWrap : classes.liveWrap + " " + classes.noLiveImg}>
                                    <Typography className={classes.liveText}>Live event starts in</Typography>
                                    
                                        {days >= 2 ?
                                            <div className={classes.daysWrap}>
                                                <Typography className={classes.timerClock}>{days}</Typography>
                                                <Typography style={{marginLeft: '15px'}} className={classes.liveText}>Days</Typography>
                                            </div>
                                            :
                                            <div className={classes.hoursWrap}>
                                                <Typography className={classes.timerClock}>{hours}:{minutes}:{seconds}</Typography>
                                                <Typography className={classes.liveText}>Hours</Typography>
                                            </div>
                                        }
                                </div>
                            </Box>
                            <Box className={classes.hostInfoMainContainer}>
                                <Box className={classes.hostInfoContainer}>
                                    <Typography className={classes.title} >{configJSON.aboutTheHost}</Typography>
                                    <Box className={classes.profileSection}>
                                        <img src={hostProfileImage} alt="profileImg" className={classes.profileImage} />
                                        <div>
                                            <Typography className={classes.hostName}>{hostName}</Typography>
                                            <span className={classes.starRating}>
                                                <i style={{ marginRight: '4px' }}><img src={star} alt="Review" /></i>
                                                4.5
                                            </span>
                                        </div>
                                        <div style={{marginLeft: '16px'}}>
                                            <Button data-test-id="folloingBtn" onClick={()=>this.handleStatTunedBox()} className={classes.followBtn}>Following</Button>
                                        </div>
                                    </Box>
                                    <Typography className={classes.hostBio}>{hostBio}</Typography>
                                    <Typography className={classes.schedueledtimeConatiner}><Typography component={"span"} className={classes.scheduledDateText}>{startdateTime}</Typography></Typography>
                                    <Typography className={classes.productName}>{productTitle}</Typography>
                                    <Typography className={classes.productDescription}>{productDescription}</Typography>
                                    <Button data-test-id="notifyBtn" onClick={()=>this.handleNotifyDialogBox()} className={classes.notifyMe}>{configJSON.notifyMe}</Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={3} className={classes.chatRightSide}>
                            <Box>
                                <Box className={classes.chatWrap}>
                                    <Box className={classes.chatTitleWrap}>
                                        <Typography className={classes.chatTitle}>Chat</Typography>
                                        <img src={threeDotIC} alt="menu-icon" />
                                    </Box>
                                    <Box>
                                        <Box className={classes.chatItemWrap}>
                                            <img className={classes.chatImg} src={chatIc} alt="chat-icon" />
                                            <Box className={classes.chatContentWrap}>
                                                <Typography className={classes.chatUsername}>Mike</Typography>
                                                <Typography className={classes.chatMessage}>Hi...</Typography>
                                            </Box>
                                        </Box>
                                        <Box 
                                            className={classes.chatItemWrap}>
                                            <img 
                                                className={classes.chatImg} src={chatIc} alt="chat-icon" />
                                            <Box 
                                                className={classes.chatContentWrap}>
                                                <Typography 
                                                    className={classes.chatUsername}>Mike</Typography>
                                                <Typography 
                                                    className={classes.chatMessage}>Hi...</Typography>
                                            </Box>
                                        </Box>
                                        <Box className={
                                            classes.chatItemWrap}>
                                            <img className={
                                                classes.chatImg} src={chatIc} alt="chat-icon" />
                                            <Box className={
                                                classes.chatContentWrap}>
                                                <Typography className={
                                                    classes.chatUsername}>Mike</Typography>
                                                <Typography className={
                                                    classes.chatMessage}>Hi...</Typography>
                                            </Box>
                                        </Box>
                                        <Box className={classes.chatItemWrap}>
                                            <img className={classes.chatImg}
                                                src={chatIc}
                                                alt="chat-icon" />
                                            <Box className={classes.chatContentWrap}>
                                                <Typography className={classes.chatUsername}>Mike</Typography>
                                                <Typography className={classes.chatMessage}>Hi...</Typography>
                                            </Box>
                                        </Box>
                                        <Box className={classes.chatItemWrap}>
                                            <img
                                                className={classes.chatImg}
                                                src={chatIc}
                                                alt="chat-icon" />
                                            <Box
                                                className={
                                                    classes.chatContentWrap
                                                }>
                                                <Typography
                                                    className={
                                                        classes.chatUsername}>Mike</Typography>
                                                <Typography
                                                    className={
                                                        classes.chatMessage}>Hi...</Typography>
                                            </Box>
                                        </Box>
                                        <Box className={classes.chatItemWrap}>
                                            <img
                                                className={classes.chatImg} src={chatIc}
                                                alt="chat-icon" />
                                            <Box className={classes.chatContentWrap}>
                                                <Typography
                                                    className={classes.chatUsername}>Mike
                                                </Typography>
                                                <Typography
                                                    className={classes.chatMessage}>Hi...
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            className={
                                                classes.chatItemWrap
                                            }>
                                            <img
                                                className={classes.chatImg} src={chatIc} alt="chat-icon" />
                                            <Box className={classes.chatContentWrap}>
                                                <Typography
                                                    className={classes.chatUsername}>Mike</Typography>
                                                <Typography className={classes.chatMessage}>Hi...</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={classes.commentWrap}>
                                    <TextField
                                        data-test-id="commentBox"
                                        className={classes.commentInventory}
                                        placeholder="Add comment..."
                                        onClick={()=>this.handleStatTunedBox()}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    <InputAdornment data-test-id="searchIcon" 
                                                        onClick={()=>this.handleStatTunedBox()}
                                                        position="end" >
                                                        <img
                                                            style={{ cursor: "pointer" }}
                                                            src={shareIC}
                                                            alt="shareIcon"
                                                        />
                                                    </InputAdornment>
                                                    <InputAdornment data-test-id="searchIcon"
                                                        onClick={()=>this.handleStatTunedBox()}
                                                        position="end" >
                                                        <img
                                                            style={{ cursor: "pointer" }}
                                                            src={sendIC}
                                                            alt="sendIcon"
                                                        />
                                                    </InputAdornment>
                                                </>
                                            ),
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <section className="you-may-also-like-section">
                    <div className="container">
                        <div className="title-wrap">
                            <span className="title">
                                You May Also Like</span>
                            <Link className="see-all-link" 
                                to={'BuyermarketPlace'}>
                                See all
                                <i><img src={rightArrow} alt="Arrow" />
                                </i>
                            </Link>
                        </div>
                    </div>
                    <div className="slider-wrap">
                        <Slider 
                            {...settings}>
                            {
                                this.state.subCategoryData.map((item: any, index: number) => (
                                    <div 
                                        className="post-card" key={item.id}>
                                        <div className="inner-wrap">
                                            <div className="img-wrap">
                                                <div className="inner-img-wrap">
                                                    <Link 
                                                        to={`ProductDescription?id=${item.id}`}>
                                                        <img 
                                                            src={`${baseURL}${item.attributes.product_images[0].url}`} alt="Card img" />
                                                    </Link>
                                                </div>
                                                {
                                                    this.checkProductStatus(this.state.wishListProduct, parseInt(item.id))
                                                        ? (
                                                            <IconButton 
                                                                className="archive-icon-btn" 
                                                                data-test-id={`removeWishItemEvent-${index}`} 
                                                                onClick={() => this.deleteProductItem(this.state.wishListProduct, parseInt(item.id))}>
                                                            <img src={archiveIc} alt="archive" />
                                                        </IconButton>
                                                        ) : (
                                                             <IconButton 
                                                                data-test-id={`addWishItemEvent-${index}`} 
                                                                className="archive-icon-btn" 
                                                                onClick={() => this.updateWishListItem(item.id, item.type)}>
                                                            <img 
                                                                src={archiveBlankIc} alt="archive" />
                                                        </IconButton>
                                                        )
                                                }
                                            </div>
                                            <div 
                                                className="detail-wrap">
                                                <div className="img">
                                                    <img 
                                                        src={item.attributes.host_image} 
                                                        alt="Person" />
                                                </div>
                                                <div className="content">
                                                    <span className="title">
                                                        {item.attributes.host_name}
                                                    </span>
                                                    <span 
                                                        className="detail">{item.attributes.description}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                </section>
                <Dialog
                    open={this.state.liveEventModal}
                    data-test-id="eventClose"
                    onClose={() => this.handleLiveEventModal()}
                    className={`custom-dialog other-dialog fixWidth rounded-border ${classes.dialogBackground}`}
                >
                    <DialogTitle style={{margin: '30px 6px 0 6px'}} className="title-wrap no-bottom-border">
                        <span>Disclaimer</span>
                    </DialogTitle>
                    <DialogContent className="dialog-content">
                        <div className="inner-wrap">
                            <div className={classes.liveStreamModalTextWrap}>
                                <img src={rightWithCircle} alt="right-with-circle" />
                                <span className={classes.liveStreamModalText}>
                                    <Typography className={classes.content}
                                        dangerouslySetInnerHTML={{ __html: disclaimerData.disclaimer }}></Typography>
                                </span>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions className="btn-wrap">
                        <Button
                            data-test-id="dialogContinueBtn"
                            onClick={() => this.handleLiveEventModal()}
                            className="custom-button primary-yellow large full-width"
                        >
                            Continue
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.notifyDialog}
                    onClose={this.handleNotifyDialogBox}
                    PaperProps={{
                        style: {
                            borderRadius: 23,
                            maxWidth: '425px',
                            width: '100%'
                        },
                    }}
                    className={classes.dialogBackground}
                >
                    <DialogContent className={classes.notifyDialog}>
                        <Box className={classes.offerTitleBox}>
                            <Typography style={{ fontSize: '18px', fontWeight: 500 }}>Notified Successfully</Typography>
                            <IconButton className={classes.closeBtn} onClick={() => this.handleNotifyDialogBox()} >
                                <img src={CloseIc} alt="Close" />
                            </IconButton>
                        </Box>
                        <Divider />
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
                            <Box className={classes.vectorBox} style={{ height: '64px', width: '64px' }}>
                                <img src={NotifyIc} alt="Close" />
                            </Box>
                            <Typography style={{ fontSize: '16px', maxWidth: '172px', textAlign: 'center', fontWeight: 600 }}>You'll be notified Stay tuned!</Typography>
                        </Box>
                    </DialogContent>
                </Dialog>
                <MessageModalWeb
                    displayPopup={this.state.messageModel}
                    data-test-id = "message-modal"
                    closeErrorPopup={()=>this.closeMessageModal()}
                    errorMessage={this.state.message} />
                <StayTuned 
                    openTunedBox={this.state.stayTunedBox} 
                    data-test-id="closeStayTuned" 
                    closeTunedBox={this.handleStatTunedBox} />
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
// @ts-ignore
export default withStyles(webStyles)(BuyersLiveStreaming)
// Customizable Area End