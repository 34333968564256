import React from "react";
// Customizable Area Start
import ScheduleLiveController,{Props, configJSON} from "./ScheduleLiveController.web";
import { createTheme,  withStyles, Theme ,createStyles} from "@material-ui/core/styles";
import {Box,IconButton,Typography,Grid,TextField,MenuItem,Switch,Button} from "@material-ui/core";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import {hamburgerIcon,backButton,notificationIc,calendarIc,uploadIcon,crossIcon,tickIcon,removeTagIcon} from "./assets";
import MessageModalWeb from "../../../components/src/MessageModal.web";
import SuccessDialog from "../../../components/src/SuccesDailogMessage.web"
import Loader from "../../../components/src/Loader.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";





const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export const webStyles = (theme: Theme) => ({
    scheduleLiveContainer:{
        display:"flex",
        margin:"5em 0em 0em"
    },
    scheduleLiveInnerContainer:{
        paddingTop:"4%",
        width:"100%",
        padding:"0em 2em",
    },
    [`@media (min-width: 992px)`]: {
        hideHamburger: {
          display: 'none' 
        },
    },
    hideSideHamburgerSection:{
        display:"flex",
        justifyContent:"end" as const
    },
    backButton:{
        display:"flex",
        alignItems:"center" as const
    },
    screenTitle:{
        color: 'rgba(68, 68, 68, 0.80)',
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        marginLeft:"0.5em"
    },
    iconStyle:{
        width: '48px',
        height: '48px',
        borderRadius: '10px',
        border: '1px solid var(--0, #FFF)',
        background: 'var(--0, #FFF)',
        boxShadow: '0px 0px 20px 1px rgba(0, 0, 0, 0.10)',
        display:"flex",
        alignItems:"center"  as const,
        justifyContent:"center"  as const,
        margin:"0em 0.5em"
    },
    textFieldStyle:{
        height:"3.5em",
        margin:"0.75em 0em",
        borderRadius:"15px",
        "& .MuiOutlinedInput-root":{
            borderRadius:"10px"
        }
    },
    uploadContainer: {
        height: "142px",
        border: "2px dashed #ccc",
        display: "flex",
        alignItems: "center" as const,
        justifyContent: "center" as const,
        position: "relative" as const,
        borderRadius:"10px"
    },
    thumbnailContainer: {
        height: "auto",
        border: "2px dashed #ccc",
        display: "flex",
        justifyContent: "space-between" ,
        padding:"1em 2em",
        alignItems:"center",
        borderRadius:"10px"
    },
    uploadedFileName:{
        display:"flex",
        alignItems:"center"
    },
    uploadIcon: {
        width: "48px",
        height: "48px"
    },
    crossIcon: {
        width: "24px",
        height: "24px",
        cursor: "pointer",
        marginLeft: "8px"
    },
    addThumbnail:{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    fileName:{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        marginLeft:"0.5em"
    },
    fileSize:{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: 'normal',
        marginLeft:"0.5em"
    },
    tickIcon:{
       width:"32px",
       height:"32px"
    },
    uploadIconContainer:{
        display:"flex",
        flexDirection:"column" as const,
        alignItems:"center"
    },
    uploadHereText:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
    },
    uploadText:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        textDecorationLine: 'underline',
    },
    validationSize:{
        color: '#555',
        textAlign: 'center' as const,
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        textDecorationLine: 'underline' as const
    },
    textAreaField: {
        height: "165px",
        "& .MuiInputBase-root": {
          height: "100% !important",
           borderRadius:"10px",
          "& .MuiInputBase-input": {
            overflow: "auto !important",
            height: "100% !important",
          },
        },
      },
      addTagsContainerInner: {
        marginBottom: "10px",
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        padding: "4px 10px",
        border: "1px solid #F5EA16",
        borderRadius: "20px",
        backgroundColor: "rgba(245, 234, 22, 0.10);"
    },
    addTagText: {
        color: "#555",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        opacity: 0.6
    },
    addTagClodeBtn: {
        width: "8px",
        height: "8px",
        cursor: "pointer"
    },
    addTextStyle: {
        color: "#555",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        cursor: "pointer"
    },
    addTagsContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start", 
         gap: "5px",
        flexWrap: "wrap" as const
    },
    invitedAddorRemoveText: {
        color: "#555",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        cursor: "pointer",
        textDecoration: "underline"
    },
    inviteOuterContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "5px",
        width: "100%",
        height: "66px",
        color: "#444",
        border: "1px solid #44444480",
        margin:"0.75em 0em",
        borderRadius:"10px",
        "@media (max-width: 560px)": {
            width: "100%",
        }
    },
    inviteLabel: {
        color: "#44444480",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "1.1876em",
        letterSpacing: "0.00938em"
    },
    inviteMainSection: {
        display: "flex",
        gap: "10px",
        height: "100%",
        marginTop: "-2px",
    },
    inviteInnerSection: {
        display: "flex",
        gap: "4px",
        width: "fit-content"
    },
    inviteImageBox: {
        border: "1px solid gray",
        width: "31px",
        borderRadius: "50%"
    },
    invitedNameContainer: {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
        alignItems: "flex-start" as const,
    },
    invitedName: {
        color: "#444",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "9px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
    },
    invitedText: {
        color: "#444",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "9px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    },
    switchContainer:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        marginBottom:"1em"
     },
     privateEventDescription:{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal'
     },
     switchTitle:{
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
         margin:"0.2em 0em"
     },
     switchDescription:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        margin:"0.2em 0em"
     },
     contentSettings:{
        color: '#555',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        margin:"1em 0em"
     },
     addedMutedWords: {
        color: "#555",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        cursor: "pointer"
    },
    addedMutedWordsContainer: {
        marginTop: "20px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "5px",
        flexWrap: "wrap" as const
    },
    eachMutedWordContainer: {
        marginBottom: "10px",
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        padding: "4px 10px",
        border: "1px solid #F5EA16",
        borderRadius: "20px",
        backgroundColor: "rgba(245, 234, 22, 0.10)"
    },
    mutedWordText: {
        color: "#555",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        opacity: 0.6
    },
    mutedWordCloseBtn: {
        width: "8px",
        height: "8px",
        cursor: "pointer"
    },
    saveAndButton:{
        width: '30%',
        height: '3em',
        background: '#F5EA16',
        borderRadius:"25px",
        color: '#444',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        pointer:"cursor",
        textTransform:"none" as const,
        "&:hover":{
            background: '#F5EA16',
        }
    },
    saveAndButtonContainer:{
        display:"flex",
        justifyContent:"flex-end",
        width:"100%",
         marginBottom:"2em",
        [theme.breakpoints.down('md')]: {
          margin:"1em"
        },
    },
    spaceBetween:{
        display:"flex",
        justifyContent:"space-between"
    },
    backBtnNavigation:{
        display:"flex",
        alignItems:"center"
    },
    flexContainer:{
      display:"flex"
    },
    dateTimePickerContainer:{
        display:"flex",
        justifyContent:'space-between',
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column", 
          },
          "& > *:not(:last-child)": {
            marginRight: theme.spacing(4), 
        },
    },
    space3:{
        marginTop:"2em"
    },
    addThumbnailContainer:{
        margin:"0.75em 0em"
    },
    menuItem:{
        borderBottom:"1px rgba(68, 68, 68, 0.10) solid"
    },
    dateContainers: {
        position: "relative" as const,
      },
     dateInput: {
        width: "calc(100% - 48px)", 
        height: "auto",
        display: "block" as const,
        boxSizing: "border-box" as const,
        "& .react-datepicker-wrapper .react-datepicker__input-container input": {
            margin: '1em 0em',
            padding: '1.25em 3em',
            border: '1px solid lightgray',
            borderRadius: '10px'
          },
          [theme.breakpoints.down('md')]: {
            "& .react-datepicker-wrapper .react-datepicker__input-container input": {
                margin: '1em 0em',
                padding: '1.25em 2em',
                border: '1px solid lightgray',
                borderRadius: '10px'
              },
          },
    },
      datecalendaricon: {
        width: "24px",
        position: "absolute" as const,
        display: "block",
        top: "26px",
        right: "18px",
      },

    
});


export const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#FFFF00', 
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        backgroundColor: 'black', 
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
      backgroundColor: 'black !important', 
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});




// Customizable Area End

export class ScheduleLive extends ScheduleLiveController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    formatFileSize=(sizeInBytes: number)=>{
        const KB = sizeInBytes / 1024;
        const MB = KB / 1024;
        if (MB >= 1) {
            return `${MB.toFixed(2)} MB`;
        } else {
            return `${KB.toFixed(2)} KB`;
        }
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
       const {classes} = this.props
       const {thumbnailImage,videoFile,loading} = this.state
       const today = new Date();
       const currentDate = today.toISOString().split('T')[0];
       const currentTime = today.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });


        return (
          <Box className={classes.scheduleLiveContainer} >
               <div className="dashboard-outer">
                            <div className="dashboard-inner">
                              <SellerSidebar
                                navigation={""}
                                id={""}
                                classes={undefined}
                                isOpen={this.state.isSideBarOpen}
                                data-test-id="sellerSidebar"
                                activetabName= "create"
                                closeModal={this.handleSideBar}
                                />
                            </div>
                </div>
                <Loader loading={loading} />
                <Box className={classes.scheduleLiveInnerContainer} data-test-id="scheduleLiveMainContainer">
                        <Box className={classes.hideSideHamburgerSection}>
                                            <IconButton
                                                className={`${classes.hideHamburger}`}
                                                data-test-id="hamburger-btn"
                                                onClick={() => this.handleSideBar()}
                                            >
                                                <img src={hamburgerIcon} alt="hamburger" />
                                            </IconButton>
                        </Box>
                        <Box className={classes.spaceBetween}>
                            <Box className={classes.backBtnNavigation} m={0}>
                                <img src={backButton} alt="backButton" onClick={()=>this.handleBackNavigation()}/>
                                <Typography className={classes.screenTitle}>{configJSON.scheduleLive}</Typography> 
                            </Box>
                            <Box component='span' className={classes.flexContainer}>
                                    <Box  component="span" className={classes.iconStyle}><img src={calendarIc} alt="calendarIcon"/></Box>
                                    <Box  component="span" className={classes.iconStyle}><img src={notificationIc} alt="bellIcon"/></Box>
                            </Box>
                        </Box>
                        <Box className={classes.space3}>
                        <Grid container spacing={4}>
                            <Grid item  xs={12} md={12} lg={8}>
                            <TextField
                                fullWidth
                                data-test-id="showTitle"
                                label={configJSON.showTitle}
                                variant="outlined" 
                                className={classes.textFieldStyle}
                                value={this.state.showTitle}
                                onChange={this.handleTextFieldChange("showTitle")}
                             />
                             <Box mt={2}>
                              <TextField
                                fullWidth
                                data-test-id="description"
                                label={configJSON.description}
                                variant="outlined" 
                                className={classes.textAreaField}
                                value={this.state.description}
                                onChange={this.handleTextFieldChange("description")}
                                multiline
                            
                             />
                             </Box>
                             <Box>
                            
                             </Box>
                             <Box className={classes.dateTimePickerContainer} mt={2}>
                            
                            <Box className={classes.dateContainers}>
                            <Box component="span" className={classes.dateInput}>
                                <DatePicker
                                id="endDate"
                                placeholderText="DD/MM/YYYY"
                                popperPlacement="auto"
                                dateFormat="dd/MM/yyyy"
                                popperModifiers={[
                                    {
                                    name: "offset",
                                    options: {
                                        offset: [0, 10],
                                    },
                                    },
                                    {
                                    name: "flip",
                                    enabled: false,
                                    },
                                    {
                                    name: "preventOverflow",
                                    options: {
                                        padding: 16,
                                    },
                                    },
                                ]}
                                onChange={this.handleDateChange}
                                popperClassName="example-datepicker-class"
                                selected={this.state.date}
                                minDate={new Date()}
                                data-test-id ="datePicker"
                                onKeyDown={(event)=>{event.preventDefault()}}
                                ref={this.datePickerRef}
                                />
                            </Box>
                            <Box  component="span" className={classes.datecalendaricon} onClick={this.openDatePicker} data-test-id="calendarIcon">
                                <img src={calendarIc} alt={"calendar"} />
                            </Box>
                            </Box>
                               <TextField
                                    fullWidth
                                    data-test-id="timeInput"
                                    type="time"
                                    value={this.state.time} 
                                    onChange={this.handleTimeChange} 
                                    label={configJSON.time}
                                    variant="outlined"
                                    className={classes.textFieldStyle}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, 
                                        min: today.getTime() < new Date(`${currentDate}T${currentTime}`).getTime() ? currentTime : undefined 
                                    }}
                                />
                             </Box>

                             <Box className={classes.addThumbnailContainer}>
                             {thumbnailImage ? (
                                <>
                                <Box className={classes.thumbnailContainer} component="fieldset" data-test-id="thumbnailImagePreview">
                                <legend className={classes.addThumbnail}>{configJSON.addThumbnailLabel}</legend>
                                    <Box className={classes.uploadedFileName}>
                                         <img src={tickIcon} alt="Uploaded" className={classes.tickIcon}  />
                                         <Box>
                                         <Typography  className={classes.fileName}>{thumbnailImage.name}</Typography>
                                         <Typography  className={classes.fileSize}>{this.formatFileSize(thumbnailImage.size)}</Typography>
                                         </Box>
                                    </Box>
                                    <img src={crossIcon} alt="Remove" className={classes.crossIcon} onClick={this.handleRemoveImage} />
                                </Box>
                                </>
                            ) : (<>
                               
                                <Box className={classes.uploadContainer} component="fieldset">
                                <legend className={classes.addThumbnail}>{configJSON.addThumbnailLabel}</legend>
                                <label htmlFor="thumbnail-upload">
                                    <input
                                        type="file"
                                        accept=".jpg, .png"
                                        onChange={this.handleImageChange}
                                        style={{ display: "none" }}
                                        id="thumbnail-upload"
                                        data-test-id="thumbnailImageInput"
                                    />
                                        <Box className={classes.uploadIconContainer} >
                                            <img src={uploadIcon} alt="Upload Icon" className={classes.uploadIcon} />
                                            <Typography  className={classes.uploadHereText}>
                                                <Typography component="span" className={classes.uploadText}>
                                                {configJSON.upload}
                                                </Typography>
                                                {" "}
                                                {configJSON.here}
                                            </Typography>
                                            <Typography className={classes.validationSize}>{configJSON.validationSize}</Typography>
                                        </Box>
                                    </label>
                                </Box>
                                </>
                            )}
                            </Box>
                            <Box className={classes.addThumbnailContainer}>
                                {videoFile ? (
                                    <>  
                                    <Box className={classes.thumbnailContainer} component="fieldset" data-test-id="videoPreview">
                                    <legend className={classes.addThumbnail}>{configJSON.addVideo}</legend>
                                        <Box className={classes.uploadedFileName}>
                                            <img src={tickIcon} alt="Uploaded" className={classes.tickIcon} />
                                            <Box>
                                            <Typography className={classes.fileName}>{videoFile.name}</Typography>
                                            <Typography className={classes.fileSize}>{this.formatFileSize(videoFile.size)}</Typography>
                                            </Box>
                                        </Box>
                                        <img src={crossIcon} alt="Remove" className={classes.crossIcon} onClick={this.handleRemoveVideo} />
                                    </Box>
                                    </>
                                ) : (
                                    <>
                                            <Box className={classes.uploadContainer} component="fieldset">
                                            <legend className={classes.addThumbnail}>{configJSON.addVideo}</legend>
                                            <label htmlFor="video-upload">
                                                <input
                                                    type="file"
                                                    accept=".mp4"
                                                    onChange={this.handleVideoChange}
                                                    style={{ display: "none" }}
                                                    id="video-upload"
                                                    data-test-id="videoInput"
                                                />
                                                <Box className={classes.uploadIconContainer}>
                                                    <img src={uploadIcon} alt="Upload Icon" className={classes.uploadIcon} />
                                                    <Typography  className={classes.uploadHereText}>
                                                        <Typography component="span" className={classes.uploadText}>
                                                        {configJSON.upload}
                                                        </Typography>
                                                        {" "}
                                                        {configJSON.here}
                                                    </Typography>
                                                    <Typography className={classes.validationSize}>{configJSON.validationSize}</Typography>
                                                </Box>
                                             </label>
                                            </Box>
                                       
                                    </>
                                )}
                            </Box>

                           
                            <TextField
                                select
                                fullWidth
                                data-test-id="primaryCategory"
                                label={configJSON.selectYourPrimaryCategory}
                                value={this.state.primaryCategory}
                                onChange={this.handleSelectChange("primaryCategory")}
                                variant="outlined"
                                className={classes.textFieldStyle}
                        >
                            <MenuItem disabled value="default">
                            {configJSON.selectAnyOne}
                            </MenuItem>
                                {this.state.categoriesList?.map(category => (
                                    <MenuItem key={category.id} value={category.id}  data-test-id="categoryItem" className={classes.menuItem}>
                                        {category.attributes.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                fullWidth
                                data-test-id="subCategory"
                                label={configJSON.selectYourSubCategory}
                                value={this.state.subCategory}
                                onChange={this.handleSelectChange("subCategory")}
                                variant="outlined"
                                select
                                className={classes.textFieldStyle}
                                disabled={this.state.primaryCategory ==="default"}
                            >
                                <MenuItem disabled value="default">
                                {configJSON.selectAnyOne}
                                </MenuItem>
                                {this.state.subCategoriesList?.map(subCategory => (
                                    <MenuItem key={subCategory.id} value={subCategory.id} data-test-id="subCategoryItem" className={classes.menuItem}>
                                        {subCategory.attributes.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                          
                            
                            <>
                                <TextField
                                    data-test-id="addTag"
                                    fullWidth
                                    label={configJSON.addTagsLabel}
                                    variant="outlined"
                                    value={this.state.addTagsValue}
                                     className={classes.textFieldStyle}
                                    onChange={this.handleAddTagsText}
                                    InputProps={{
                                        endAdornment: (
                                            <Typography  onClick={this.handleAddTags} className={classes.addTextStyle}>
                                              {configJSON.add}
                                            </Typography>
                                        )
                                    }}
                                />
                                <Box className={classes.addTagsContainer}>
                                    {this.state.addTags.map((item, index) => {
                                        return (
                                            <Box key={index} className={classes.addTagsContainerInner} data-test-id="eachTag">
                                                <img onClick={() => this.handleTagClose(index)} className={classes.addTagClodeBtn} src={removeTagIcon} alt="close"  data-test-id={`eachTagCross${index}`}/>
                                                <Typography className={classes.addTagText}>{item}</Typography>
                                            </Box>
                                        );
                                    })}
                                </Box>

                            </>

                            <Box>
                            <Box className={classes.inviteOuterContainer} component="fieldset" >
                                <legend className={classes.inviteLabel}>{configJSON.moderators}</legend>
                                <Box className={classes.inviteMainSection}>
                                    <Box className={classes.inviteInnerSection}>
                                        <Box className={classes.inviteImageBox}>
                                            <img />
                                        </Box>

                                        <Box className={classes.invitedNameContainer}>
                                            <Typography className={classes.invitedName}>{configJSON.sampleName}</Typography>
                                            <Typography className={classes.invitedText}>{configJSON.invited}</Typography>
                                        </Box>

                                    </Box>
                                </Box>
                                         <Typography className={classes.invitedAddorRemoveText}>{configJSON.add}</Typography>
                                    </Box>
                                    <Box className={classes.inviteOuterContainer} component="fieldset">
                                        <legend className={classes.inviteLabel}>{configJSON.collaborators}</legend>
                                        <Box className={classes.inviteMainSection}>

                                            <Box className={classes.inviteInnerSection}>
                                                <Box className={classes.inviteImageBox}>
                                                    <img />
                                                </Box>
                                                <Box className={classes.invitedNameContainer}>
                                                    <Typography className={classes.invitedName}>{configJSON.sampleName2}</Typography>
                                                   
                                                    <Typography className={classes.invitedText}>{configJSON.invited}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Typography className={classes.invitedAddorRemoveText}>{configJSON.add}</Typography>
                                    </Box>
                            </Box>

                            
                            </Grid>
                            <Grid item  xs={12} md={12} lg={4}>
                            <Box className={classes.switchContainer}>
                                <Box>
                                <Typography className={classes.privateEventDescription}>
                                    {configJSON.switchDescriptions.privateEvent}
                                </Typography>
                                </Box>
                                <IOSSwitch
                                checked={this.state.privateEvent}
                                onChange={this.handleSwitchChange("privateEvent")}
                                />
                            </Box>

                            <Typography className={classes.contentSettings}>{configJSON.contentSettings}</Typography>

                            <Box className={classes.switchContainer}>
                                <Box>
                                <Typography className={classes.switchTitle}>
                                    {configJSON.switchTitles.explicitContent}
                                </Typography>
                                <Typography className={classes.switchDescription}>
                                    {configJSON.switchDescriptions.explicitContent}
                                </Typography>
                                </Box>
                                <IOSSwitch
                                checked={this.state.explicitContent}
                                onChange={this.handleSwitchChange("explicitContent")}
                               
                                />
                            </Box>
                            <Box className={classes.switchContainer}>
                                <Box>
                                <Typography className={classes.switchTitle}>
                                    {configJSON.switchTitles.muteWords}
                                </Typography>
                                <Typography className={classes.switchDescription}>
                                    {configJSON.switchDescriptions.muteWords}
                                </Typography>
                                </Box>
                                <IOSSwitch
                                data-test-id="muteWords"
                                checked={this.state.muteWords}
                                onChange={this.handleSwitchChange("muteWords")}
                                />
                            </Box>
                            {
                                this.state.muteWords &&
                                <Box>
                                            <TextField
                                                fullWidth
                                                data-test-id="mutewordsField"
                                                label={configJSON.muteWords}
                                                variant="outlined"
                                                value={this.state.mutedWordsValue}
                                                className={classes.textFieldStyle}
                                                onChange={this.handleAddMutedWordsText}
                                                InputProps={{
                                                    endAdornment: (
                                                        <Typography className={classes.addedMutedWords} onClick={this.handleMutedWords}>
                                                          {configJSON.add}
                                                        </Typography>
                                                    )
                                                }}
                                            />
                                            <Box className={classes.addedMutedWordsContainer}>
                                                {this.state.muteWordsList.map((item, index) => {
                                                    return (
                                                        <Box key={index} className={classes.eachMutedWordContainer} data-test-id="eachmuteWord">
                                                            <img onClick={() => this.handleCloseMutedWords(index)} 
                                                             className={classes.mutedWordCloseBtn}
                                                             src={removeTagIcon} alt="close"
                                                             data-test-id={`eachMuteWord${index}`}
                                                            />
                                                            <Typography className={classes.mutedWordText}>{item}</Typography>
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        </Box>
                            }


                            </Grid>
                            <Box className={classes.saveAndButtonContainer}> 
                                <Button data-test-id="save" className={classes.saveAndButton} onClick={this.handleSave}>{configJSON.save}</Button>
                            </Box>
                        </Grid>
                        </Box>
                        

                </Box>
                <MessageModalWeb data-test-id="errorMessage" displayPopup={this.state.errorModal} closeErrorPopup={this.errorModalClose} errorMessage={this.state.errorMessage} />
                <SuccessDialog
                        data-test-id="sucessMessage"
                        open={this.state.sucessModal}
                        closeFn={this.sucessMessageClose}
                        successTitle={configJSON.sucessMessage}
                        successMessage={configJSON.sucessDescription}
                    />
          </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(webStyles)(ScheduleLive)
// Customizable Area End