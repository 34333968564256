// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import DatePicker from "react-datepicker";

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
   
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    loading:boolean
    isSideBarOpen:boolean
    showTitle:string,
    description:string
    thumbnailImage: null | File
    videoFile: null | File;
    errorModal:boolean
    errorMessage:string
    sucessModal:boolean
    primaryCategory: string;
    subCategory: string,
    categoriesList:Array<any>
    subCategoriesList:Array<any>
    date:any
    time:string  | Blob
    addTags: string[],
    addTagsValue: string,
    muteWordsList:string[],
    mutedWordsValue:string,
    explicitContent: boolean,
    muteWords: boolean,
    privateEvent: boolean,
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ScheduleLiveController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCategoriesId: string =""
    getSubCategoriesId: string =""
    createScheduleLiveMsgId:string=""
    readonly datePickerRef: React.RefObject<DatePicker>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.datePickerRef = React.createRef()
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            loading:false,
            isSideBarOpen:true,
            showTitle:"",
            description:"",
            thumbnailImage: null,
            videoFile: null,
            errorModal:false,
            sucessModal:false,
            errorMessage:'',
            primaryCategory: "default",
            subCategory: "default",
            categoriesList:[],
            subCategoriesList:[],
            date:null,
            time:"",
            addTags: [],
            addTagsValue: "",
            muteWordsList:[],
            mutedWordsValue:"",
            explicitContent: false,
            muteWords: false,
            privateEvent: false,
            // Customizable Area End
        };

    }


    // Customizable Area Start

    async componentDidMount() {
        this.getCategories()
      }


    async receive(from: string, message: Message) {

        this.handlegetCategoriesResponse(from, message)
        this.handleSubCategoriesResponse(from, message)
        this.handleCreateResponse(from, message)
      }

      handlegetCategoriesResponse=(from: string, message: Message)=>{
        if (this.getCategoriesId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (apiResponse?.errors) {
            alert(apiResponse?.errors)
          } else {
            this.setState({
              categoriesList: apiResponse?.data
            })
          }
        }
      }

      handleSubCategoriesResponse=(from: string, message: Message)=>{
        if (this.getSubCategoriesId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          if (apiResponse?.errors) {
            alert(apiResponse?.errors)
          } else {
            this.setState({
              subCategoriesList: apiResponse?.data
            })
          }
        }
      }

      handleCreateResponse=(from: string, message: Message)=>{
        if (this.createScheduleLiveMsgId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (apiResponse?.errors) {
            const errorMessage = apiResponse.errors[0].live_streams.map((message:string) => message).join(', ');
             
              this.setState({loading:false})
              this.setState({ errorModal: true, errorMessage });

          } else {
            this.setState({loading:false})
            this.setState({sucessModal:true})
          }
        }
      }



    handleSideBar = () => {
        this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
    }
    handleBackNavigation=()=>{
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage),"LiveStreaming");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }

    handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const imageFile = files[0];
            if (imageFile.size <= 5 * 1024 * 1024) {
                this.setState({ thumbnailImage: imageFile });
            } else {
                this.setState({errorModal:true,errorMessage:configJSON.validateMessageForImage})
            }
        } 
    };
    handleVideoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const videoFile = files[0];
            if (videoFile.size <= 5 * 1024 * 1024) {
                this.setState({ videoFile });
            } else {
                this.setState({errorModal:true,errorMessage:configJSON.validateMessageForVideo})
            }
        } 
    };

    handleRemoveImage = () => {
        this.setState({ thumbnailImage: null });
    };
    handleRemoveVideo = () => {
        this.setState({ videoFile: null });
    };
    errorModalClose=()=>{
        this.setState({errorModal:false,errorMessage:''})
    }
    sucessMessageClose=()=>{
      this.setState({sucessModal:false})
     }
    handleSelectChange = (fieldName: keyof S) => (
        event: React.ChangeEvent<{ value: unknown }>
      ) => {
        const selectedValue = event.target.value as string;
        if (fieldName === "primaryCategory") {
           this.getSubCategories(selectedValue);
           this.setState({subCategory:"default"})
        }
        this.setState({[fieldName]: event.target.value as string,} as unknown as Pick<S, keyof S>);
      };
      handleTextFieldChange = (fieldName: keyof S) => (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        this.setState({
            [fieldName]: event.target.value,
        } as unknown as Pick<S, keyof S>);
      };

      handleDateChange = (selectedDate:  Date | null) => {
        this.setState({ date: selectedDate });
    }
    
      handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedTime = event.target.value;
        this.setState({ time: selectedTime });
      }

      handleAddTagsText = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
          addTagsValue: event.target.value,
        })
      };
    
      handleAddTags = () => {
        const trimmedValue = this.state.addTagsValue.trim();
        if (trimmedValue.length !== 0) {
            this.setState(state => ({
                addTags: [...state.addTags, trimmedValue],
                addTagsValue: ""
            }));
        }
      };
    
      handleTagClose = (index: number): void => {
        this.setState(state => {
          const newArray = state.addTags.filter((_, eachindex) => eachindex !== index);
          return { addTags: newArray };
        });
      };

      handleSwitchChange = (fieldName: keyof S) => (
        event: React.ChangeEvent<HTMLInputElement>
      ) => {
        this.setState({
          [fieldName]: event.target.checked,
        } as unknown as Pick<S, keyof S>);
        if(fieldName==="muteWords" && !event.target.checked){
          this.setState(state => ({
            muteWordsList: [],
      
          }))
        }
      };

      handleMutedWords = () => {
        const trimmedValue = this.state.mutedWordsValue.trim();
        if (trimmedValue.length !== 0) {
          this.setState(state => ({
              muteWordsList: [...state.muteWordsList, trimmedValue],
              mutedWordsValue: ""
      }));
      }
      };
    
      handleCloseMutedWords = (index: number): void => {
        this.setState(state => {
          const newArray = state.muteWordsList.filter((_, eachindex) => eachindex !== index);
          return { muteWordsList: newArray };
        });
      };

      handleAddMutedWordsText = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
          mutedWordsValue: event.target.value,
        })
      };

      handleSave=async()=>{
        this.setState({loading:true})
        let endPoint = `${configJSON.createSheduleLiveEndpoint}`
        const {showTitle,description,date,time,thumbnailImage,videoFile,
          subCategory,primaryCategory,addTags,explicitContent,muteWords,muteWordsList} = this.state
        const formData = new FormData()
        formData.append(`data[title]`,showTitle);
        formData.append(`data[description]`,description);
        const createdate = new Date(date);
        const offset = createdate.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(createdate.getTime() - offset);
        const formattedDate = date && adjustedDate.toISOString().split('T')[0];
        formData.append(`data[start_date]`,formattedDate);
        formData.append(`data[start_time]`,time);
        thumbnailImage && formData.append(`data[thumbnail]`,thumbnailImage);
        videoFile && formData.append(`data[video]`,videoFile);
        formData.append(`data[category_id]`,primaryCategory);
        formData.append(`data[sub_category_id]`,subCategory);

        addTags.forEach((eachTag:string) => {
        formData.append(`data[tag_list][]`, eachTag)});

        formData.append(`data[explicit_content]`,explicitContent +"");
        formData.append(`data[mute_words]`,muteWords + "");
        muteWordsList.forEach((eachWord:string) => {
          formData.append(`data[mute_words_arr][]`, eachWord)});

          formData.append(`data[stream_type]`,"instant_live");
          formData.append(`data[status]`,"schedhuled_live");
    
      
         
        const sellerLoginToken =  await getStorageData("singupLogin");

        const headers = {
            token: sellerLoginToken,
          };

        const createScheduleLiveMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.createScheduleLiveMsgId = createScheduleLiveMsg.messageId;
      
        createScheduleLiveMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        createScheduleLiveMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          createScheduleLiveMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postMethodType
        );

        createScheduleLiveMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
        );
      
        runEngine.sendMessage(createScheduleLiveMsg.id, createScheduleLiveMsg);





      }

      getCategories(){
        const singupLogin =  getStorageData("singupLogin");
        const headers = {
            token: singupLogin,
          };
        const getcategoriesMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCategoriesId = getcategoriesMsg.messageId;
      
        getcategoriesMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getCategoriesEndpoint 
        );
        getcategoriesMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
        getcategoriesMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(getcategoriesMsg.id, getcategoriesMsg);
    
      }
    
      getSubCategories(selectedValue:string){   
        const singupLogin =  getStorageData("singupLogin");
        const endPoint = `${configJSON.getSubCategoriesEndpoint }?category_id=${selectedValue}`
        const headers = {
            token: singupLogin,

          };
        const getSubCategoriesMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getSubCategoriesId = getSubCategoriesMsg.messageId;
      
        getSubCategoriesMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        getSubCategoriesMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
          getSubCategoriesMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(getSubCategoriesMsg.id, getSubCategoriesMsg);
    
      }

      openDatePicker = () => {
        if (this.datePickerRef.current) {
            this.datePickerRef.current.setOpen(true);
        }
    }

    
   
    // Customizable Area End
}