// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { WithStyles } from "@material-ui/core";
import { ChangeEvent } from "react";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
const navigation = require("react-navigation")
import AgoraRTC, { IAgoraRTCClient, IRemoteVideoTrack, IRemoteAudioTrack, ILocalAudioTrack, ILocalVideoTrack, IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';

export interface LiveInventoryListItem{
  productName: string;
  productId: string;
  price: string;
  customerName: string;
}

interface LiveStreamAttributes {
  id: number;
  title: string;
  description: string;
  explicit_content: boolean;
  mute_words: boolean;
  token: string;
  channel_name: string;
  u_id: string;
  comment_section: string | null;
  tags: string[];
  mute_words_arr: string[] | null;
  account_id: number;
  stream_url: string | null;
  stream_key: string | null;
  stream_type: string;
  status: string;
  start_date: string;
  start_time: string;
  is_paid: boolean;
  amount: number | null;
  user_restriction: boolean;
  moderator: boolean;
  is_private: boolean;
  account_ids: number[];
  moderator_ids: number[];
  collaborator_ids: number[];
  created_at: string;
  updated_at: string;
  start_date_time: string;
  thumbnail: string | null;
  host_name: string;
  host_bio: string;
  host_image: string;
  video: string | null;
  catalogues: string[];
}

interface LiveStreamData {
  id: string;
  type: string;
  attributes: LiveStreamAttributes;
}

interface LiveStreamResponse {
  data: LiveStreamData;
}

interface FullscreenDocument extends Document {
  mozCancelFullScreen?: () => Promise<void> | void;
  webkitExitFullscreen?: () => Promise<void> | void;
  msExitFullscreen?: () => Promise<void> | void;
  mozFullScreenElement?: () => Promise<void> | void;
  webkitFullscreenElement?: () => Promise<void> | void;
  msFullscreenElement?: () => Promise<void> | void;
}

type FullscreenElement = HTMLElement & {
  mozRequestFullScreen?: () => Promise<void>;
  webkitRequestFullscreen?: () => Promise<void>;
  msRequestFullscreen?: () => Promise<void>;
};

export interface User {
  uid: string;
  audioTrack?: ILocalAudioTrack; 
  videoTrack?: ILocalVideoTrack;
}

export interface RemoteUser{
  uid: string;
  audioTrack?: IRemoteAudioTrack; 
  videoTrack?: IRemoteVideoTrack;
}

export interface LocalUserProps {
  audioTrack?: ILocalAudioTrack;
  videoTrack?: ILocalVideoTrack;
  cameraOn: boolean;
  micOn: boolean;
}

interface Image {
  id: number;
  url: string;
}

interface Catalogue {
  catalogue_id: number;
  catalogue_title: string;
  catalogue_description: string;
  catalogue_selling_price: number;
  catalogue_mrp: number;
  quantity: number;
  images: Image[];
  customerName: string;
}

interface Attributes {
  id: number;
  title: string | null;
  description: string | null;
  explicit_content: boolean;
  mute_words: boolean;
  token: string;
  channel_name: string;
  u_id: string;
  comment_section: string | null;
  tags: string[];
  mute_words_arr: string[] | null;
  account_id: number;
  stream_url: string | null;
  stream_key: string | null;
  stream_type: string | null;
  status: string;
  start_date: string | null;
  start_time: string | null;
  is_paid: boolean;
  ammount: number | null;
  user_restriction: boolean;
  moderator: boolean;
  is_private: boolean;
  account_ids: number[];
  moderator_ids: number[];
  collaborator_ids: number[];
  created_at: string;
  updated_at: string;
  start_date_time: string;
  thumbnail: string | null;
  host_name: string;
  host_bio: string;
  host_image: string;
  video: string | null;
  catalogues: Catalogue[];
  pin_item: string | null;
}

interface LiveStream {
  id: string;
  type: string;
  attributes: Attributes;
}

interface LiveStreamData {
  data: LiveStream;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
    navigation: typeof navigation;
    id: string;
    // Customizable Area Start
   
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
      isSideBarOpen: boolean;
      liveInventoryList: Catalogue[];
      searchInventoryProducts: string;
      isModalOpen: boolean;
      anchorEl: HTMLElement | null;
      calling: boolean,
      appId: string,
      channel: string,
      token: string,
      micOn: boolean,
      cameraOn: boolean,
      remoteUsers: Array<IAgoraRTCRemoteUser>;
      isPaused: boolean,
      pausedImage: string,
      loading: boolean,
      uid: string,
      liveStreamId: number
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class GoLiveScreenController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getInventoryLiveStreamData:string="";
    getHostTokenApiID: string = "";
    client: IAgoraRTCClient;
    localTracks: {
      videoTrack: ILocalVideoTrack | null;
      audioTrack: ILocalAudioTrack | null;
    };
    videoContainerRef: React.RefObject<HTMLDivElement>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area End
        ];

        // Customizable Area Start
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

      this.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
      this.localTracks = {
        videoTrack: null,
        audioTrack: null,
      };

      this.videoContainerRef = React.createRef();
        // Customizable Area End

      this.state = {
        // Customizable Area Start
        isSideBarOpen: false,
        liveInventoryList: [],
        searchInventoryProducts: "",
        isModalOpen: false,
        anchorEl: null,
        calling: false,
        appId: "",
        channel: "",
        token: "",
        micOn: true,
        cameraOn: true,
        remoteUsers: [],
        isPaused: false,
        pausedImage: "",
        loading: false,
        uid: "",
        liveStreamId:0
        // Customizable Area End
      };

    }


    // Customizable Area Start
  async componentDidMount() {
    const liveStreamID = window.location.pathname.split("/").pop();
    liveStreamID && this.setState({ liveStreamId: Number(liveStreamID) }, () => {
      this.getHostToken(liveStreamID);
      this.getInventoryLiveData();
    });
  };

    async componentWillUnmount(): Promise<void> {
      const { audioTrack, videoTrack } = this.localTracks;
      if (audioTrack) {
        audioTrack.stop();
        audioTrack.close();
      }
      if (videoTrack) {
        videoTrack.stop();
        videoTrack.close();
      }
      this.client.leave();
    };

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
       
        const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
  
        let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
        if(responseJson.data){
          this.handleApiSuccess(apiRequestCallId, responseJson);
        } 
  
        if(responseJson.message || responseJson.error){
          const message = responseJson.message
          this.handleApiFailure(apiRequestCallId, message)
        }
      }
    }

    handleApi = async(data:{method: string; endpoint: string; body?:{}}) => {
      const {method, endpoint, body} = data;
      const token = await getStorageData("singupLogin");;
      const header = {
        "Content-Type": "application/json",
        token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      requestMessage.addData(getName(
        MessageEnum.RestAPIResponceEndPointMessage), endpoint);
  
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(getName(
        MessageEnum.RestAPIRequestMethodMessage),
        method);
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return requestMessage.messageId
    };

    handleApiSuccess = async(apiID:string, response: LiveStreamResponse & LiveStreamData) => {
      this.setState({
        loading: false
      });
      if (apiID === this.getInventoryLiveStreamData) {
        this.setState({ liveInventoryList: response.data.attributes.catalogues || [] })
      }
      if(apiID === this.getHostTokenApiID){
        const {token, channel_name, u_id} = response.data.attributes;
        this.setState({
          token,
          channel: channel_name,
          uid: u_id,
          appId: "bb0f5e1d6ff3429a8e16a479a8e7aaf4"
        }, ()=>{
            this.joinChannel()
        })
      };
    };
  
    handleApiFailure = (apiID:string, message: string) => {
      this.setState({
        loading: false
      });
    };

    getHostToken = async (hostTokenID: string) => {
      this.getHostTokenApiID = await this.handleApi({
        method: configJSON.exampleAPiMethod,
        endpoint: configJSON.liveStreamHostTokenEndPoint + +hostTokenID
      })
    }

    handleSideBar = () => {
        this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
    }
    handleInventoryProductSearch = (event: ChangeEvent<HTMLInputElement>) => {
      this.setState({
        searchInventoryProducts: event.target.value.toLowerCase()
      })
    }

    handleModalOpen = () => {
      this.setState({
        isModalOpen: true
      })
    }
    
    handleModalClose = (type:string) => {
      Boolean(type === "delete") && this.goToLiveStreaming();
      this.setState({
        isModalOpen: false
      })
    };

    goToLiveStreaming = () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage),"LiveStreaming");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message)
    };

    joinChannel = async () => {
      const { appId, channel, token, uid } = this.state;
      await this.client.join(appId, channel, token, +uid);
      this.localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      this.localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack();
  
      const tracks = Object.values(this.localTracks).filter((track): track is ILocalAudioTrack | ILocalVideoTrack => track !== null);
  
      await this.client.publish(Object.values(tracks));
  
      this.client.on("user-published", this.handleUserPublished);
      this.client.on("user-unpublished", this.handleUserUnpublished);
  
      this.setState({ calling: true });
    };
  
    leaveChannel = async () => {
        this.localTracks.audioTrack?.close();
        this.localTracks.videoTrack?.close();  
        this.setState({ remoteUsers: [] });
  
      await this.client.leave();
      this.setState({ calling: false });
    };
  
    handleUserPublished = async (user:IAgoraRTCRemoteUser, mediaType: "video" | "audio") => {
      await this.client.subscribe(user, mediaType);
      if (mediaType === "video") {
        this.setState((prevState) => ({
          remoteUsers: [...prevState.remoteUsers, user],
        }));
      }
      if (mediaType === "audio") {
        user.audioTrack?.play();
      }
    };
  
    handleUserUnpublished = (user:IAgoraRTCRemoteUser) => {
      this.setState((prevState) => ({
        remoteUsers: prevState.remoteUsers.filter(
          (remoteUser) => remoteUser.uid !== user.uid
        ),
      }));
    };
  
    toggleMic = () => {
      const { micOn } = this.state;
      this.localTracks.audioTrack?.setEnabled(!micOn);
      this.setState({ micOn: !micOn });
    };
  
    captureVideoFrame = (videoTrack: ILocalVideoTrack | null): Promise<string> => {
      const videoElement = document.createElement("video");
      videoElement.srcObject = videoTrack && new MediaStream([videoTrack.getMediaStreamTrack()]);
      videoElement.play();
    
      return new Promise((resolve) => {
        videoElement.addEventListener("loadeddata", () => {
          const width = videoElement.videoWidth;
          const height = videoElement.videoHeight;    
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const context = canvas.getContext("2d");
          context?.drawImage(videoElement, 0, 0, width, height);
          resolve(canvas.toDataURL("image/png"));
        });
      });
    };
    
    togglePause = async () => {
      const { isPaused } = this.state;

      if (isPaused) {
        this.setState({ pausedImage: "" });
      }
      this.setState({ isPaused: !isPaused });
    };

    enterFullscreen = () => {
      const element = this.videoContainerRef.current as FullscreenElement;
      if (!element) return;

      const requestFullscreen =
        element.requestFullscreen ||
        element.mozRequestFullScreen ||
        element.webkitRequestFullscreen ||
        element.msRequestFullscreen;

      if (requestFullscreen) {
        requestFullscreen.call(element);
      }
    };
  
    exitFullscreen = () => {
      const docs = document as FullscreenDocument;
      const exitFullscreenMethod =
      docs.exitFullscreen
  
    if (exitFullscreenMethod) {
      exitFullscreenMethod.call(docs);
    }
    };
  
    toggleFullscreen = () => {
      const docs = document as FullscreenDocument;
      if (!docs.fullscreenElement && !docs.mozFullScreenElement && !docs.webkitFullscreenElement && !docs.msFullscreenElement) {
        this.enterFullscreen();
      }
    };

    getInventoryLiveData() {
      const singupLogin = localStorage.getItem("singupLogin");
      const endPoint = `${configJSON.createSheduleLiveEndpoint}/${this.state.liveStreamId}`;
      const headers = {
        "Content-Type": "application/json",
          token: singupLogin,
      };
  
      const getLiveStreamMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getInventoryLiveStreamData = getLiveStreamMsg.messageId;      
  
      getLiveStreamMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
      );
      getLiveStreamMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
      );
      getLiveStreamMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'GET'
      );
      runEngine.sendMessage(getLiveStreamMsg.id, getLiveStreamMsg);
  }
    // Customizable Area End
}