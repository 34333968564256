import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "framework/src/Utilities";
const navigation = require('react-navigation')

export interface OrderItems {
  id: number;
  type: string;
  attributes: {
    id: number;
    order_management_order_id: number;
    order_item_order_id: string;
    quantity: number;
    old_unit_price: null;
    status: string;
    catalogue_id: number;
    catalogue_variant_id: number;
    order_status_id: number;
    created_at: string;
    updated_at: string;
    unit_price: number;
    total_price: number;
    catalogue: {
      id: number;
      type: string;
      attributes: {
        category_id: number;
        sub_category_id: number;
        title: string;
        description: string;
        status: string;
        mrp: number;
        selling_price: number;
        ask_price: number;
        bargain: boolean;
        on_order: boolean;
        is_ask_price: boolean;
        is_brand: boolean;
        sku: string;
        marked_destroy: boolean;
        product_images: [
          {
            id: number;
            url: string;
          }
        ];
        inventory_details: {
          id: number;
          fullfilment_by: string;
          procurement_type: string;
          procurement_sla: string;
          account_id: number;
          stock_quantity: number;
          catalogue_id: number;
          created_at: string;
          updated_at: string;
        }
      }
    };
    catalogue_variant: {
      id: number;
      type: string;
      attributes: {
        id: number;
        catalogue_id: number;
        catalogue_variant_color: {
          id: number;
          name: string;
          created_at: string;
          updated_at: string;
        };
        catalogue_variant_size: null;
        price: number;
        stock_qty: number;
        on_sale: boolean;
        sale_price: number;
        discount_price: null;
        length: null;
        breadth: null;
        height: null;
        created_at: string;
        updated_at: string;
        block_qty: null;
        product_images: [
          {
            id: number;
            url: string;
          }
        ];
        product_videos: [
          {
            id: number;
            url: string;
          }
        ]
      }
    }
  }
}
export interface CartData {
  id: number;
  type: string;
  attributes: {
    id: number;
    order_number: string;
    amount: number;
    account_id: number;
    coupon_code_id: null;
    delivery_address_id: null;
    status: string;
    custom_label: null;
    cancellation_reason: null;
    order_date: null;
    is_gift: boolean;
    placed_at: null;
    confirmed_at: null;
    in_transit_at: null;
    delivered_at: null;
    cancelled_at: null;
    refunded_at: null;
    source: null;
    shipment_id: null;
    delivery_charges: null;
    tracking_url: null;
    schedule_time: null;
    payment_failed_at: null;
    payment_pending_at: null;
    returned_at: null;
    deliver_by: null;
    tracking_number: null;
    is_error: boolean;
    delivery_error_message: null;
    order_status_id: number;
    is_group: boolean;
    is_availability_checked: boolean;
    created_at: string;
    updated_at: string;
    delivery_addresses: [];
    razorpay_order_id: null;
    charged: null;
    invoice_id: null;
    invoiced: null;
    shipping_charge: number;
    shipping_discount: number;
    shipping_net_amt: number;
    shipping_total: number;
    tax_charges: number;
    total_tax: number;
    sub_total: number;
    applied_discount: number;
    total: number;
    order_items: Array<OrderItems>;
    account: {
      id: number;
      type: string;
      attributes: {
        activated: boolean;
        country_code: null;
        email: string;
        upi_id: null;
        full_name: string;
        user_name: string;
        business_popup: boolean;
        full_phone_number: number;
        phone_number: number;
        type: null;
        created_at: string;
        updated_at: string;
        device_id: null;
        unique_auth_id: string;
        role: {
          id: number;
          name: string;
          created_at: string;
          updated_at: string;
          page_names: []
        };
        is_disabled: boolean;
        my_bio: null;
        updated_mobile_number: null;
        updated_email: null;
        same_drop_off: boolean;
        shipment_type: string;
        business_name: null;
        business_type: null;
        profile_photo: null;
        background_photo: null
      }
    };
    order_transaction: null;
    seller_details: {
      id: number;
      type: string;
      attributes: {
        activated: boolean;
        country_code: number;
        email: string;
        upi_id: string;
        full_name: string;
        user_name: string;
        business_popup: boolean;
        full_phone_number: number;
        phone_number: number;
        type: null;
        created_at: string;
        updated_at: string;
        device_id: string;
        unique_auth_id: string;
        role: {
          id: number;
          name: string;
          created_at: string;
          updated_at: string;
          page_names: []
        };
        is_disabled: boolean;
        my_bio: string;
        updated_mobile_number: number;
        updated_email: string;
        same_drop_off: boolean;
        shipment_type: string;
        business_name: string;
        business_type: string;
        profile_photo: string;
        background_photo: string;
      }
    }
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
    navigation: typeof navigation;
    id: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  cartData: Array<CartData>;
  loading: boolean;
  activeCartItem: null | number;
  activeCartItemToggle: boolean;
  isPopupVisible: boolean;
  popupMessage: string;
  clearStoreID: number | undefined;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
    id: string;
  // Customizable Area End
}

export default class Cfmultiplecarts1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCartDataApiID: string = "";
  updateCartDataApiID: string = "";
  deleteStoreApiID: string = "";
  clearCartApiID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      cartData: [],
      loading: false,
      activeCartItem: null,
      activeCartItemToggle: false,
      isPopupVisible: false,
      popupMessage: "",
      clearStoreID: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id
    ) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isApiSuccess(responseJson)) {
        this.apiSuccess(apiRequestCallId, responseJson);
      } else {
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    // super.componentDidMount();
    this.getCartData()
  }
  apiCallFunction = async (data: {
    method: string; endPoint: string; body?: {
      [key: string]: {
        id: number | undefined;
        catalogue_id: number;
        catalogue_variant_id: number;
        quantity: number;
      } | number
    } | null
  }) => {
    this.setState({
      loading: true
    })
    let { method, endPoint, body } = data;
    const token = await getStorageData("buerLoginToken");
    const header = {
      "Content-Type": "application/json",
      token
    };
    let apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body ? apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
      : apiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  isApiSuccess = (response: CartData) => {
    return response ? true : false
  }

  apiSuccess = (apiCallId: string, response: { data: Array<CartData> } & { data: CartData } & {message:string}) => {
    this.setState({
      loading: true
    })
    if (apiCallId === this.getCartDataApiID) {
      if(response.data){
        this.setState({
          cartData: response.data
        }) 
    }else if(response.message){
      this.setState({
        isPopupVisible: true,
        popupMessage: response.message
      })
    }
  }
    if (apiCallId === this.updateCartDataApiID) {
      let tempCartData = this.state.cartData.slice();
      const matchedIdx = this.state.cartData.findIndex((cart: CartData) => cart.id == response.data.id)
      if(response.data.attributes){
        tempCartData[matchedIdx].attributes.order_items = response.data.attributes.order_items
      } else{
        tempCartData = tempCartData.filter((cart:CartData) => cart.id != response.data.id)
      }
      this.setState({
        cartData: tempCartData
      }, () => {
        if(!tempCartData.length){
          this.setState({
            isPopupVisible: true,
            popupMessage: response.message
          })
        }
      })
    }

    if(apiCallId === this.clearCartApiID){
      const filteredStoreList = this.state.cartData.filter((cart:CartData) => cart.id != this.state.clearStoreID);
      this.setState({
        cartData: this.state.clearStoreID ? filteredStoreList : []
      }, ()=> {
        this.setState({
          isPopupVisible: this.state.cartData.length ? false : true,
          popupMessage: "Your Cart is empty, continue the shopping to add items."
        })
      })
    }
    this.setState({
      loading: false
    })
  }

  getCartData = async () => {
    this.getCartDataApiID = await this.apiCallFunction({
      method: configJSON.getApiMethod,
      endPoint: configJSON.getCartDataEndPoint,
    })
  }

  updateCartData = async (item: OrderItems, actionType: number) => {
    this.updateCartDataApiID = await this.apiCallFunction({
      method: configJSON.putApiMethod,
      endPoint: configJSON.putCartUpdateEndPoint,
      body: {
        order_items: {
          id: Number(item.id),
          catalogue_id: Number(item.attributes.catalogue_id),
          catalogue_variant_id: Number(item.attributes.catalogue_variant_id),
          quantity: actionType === 1 ? item.attributes.quantity + 1 : item.attributes.quantity - 1
        }
      }
    })
  }

  deleteCartData = async (id: number) => {
    this.updateCartDataApiID = await this.apiCallFunction({
      method: configJSON.deleteApiMethod,
      endPoint: configJSON.deleteCartDataEndPoint,
      body: {
        id: Number(id)
      }
    })
  }

  updateDelete = (item: OrderItems, actionType: number = 1) => {
    if (item.attributes.quantity === 1 && actionType === 0) {
      this.deleteCartData(item.id)
    } else {
      this.updateCartData(item, actionType)
    }
  }

  clearCart = async(storeCartID?:number) => {
    this.setState({
      clearStoreID: storeCartID
    })
    this.clearCartApiID = await this.apiCallFunction({
      method: configJSON.deleteApiMethod,
      endPoint: configJSON.clearCartEndPoint,
      body: storeCartID ? {id: storeCartID} : null
    })
  }

  calculateTotalStoreItemAmount = (orderItems: Array<OrderItems>) => {
    const storeTotalAmount = orderItems.reduce((acc: number, curr: OrderItems) => {
      return acc + +curr.attributes.total_price
    }, 0);
    return storeTotalAmount
  }

  cartItemToggle = (activeCartID:number) => {
    this.setState({
      activeCartItem: this.state.activeCartItem === activeCartID ? null : activeCartID,
    })
  }

  closePopup = () => {
    this.setState({
        isPopupVisible: false,
    }, () => {
        this.goToBuyerHome()
    });
}

  goToBuyerShoppingCart = (cartId: number) => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationTargetMessage), "BuyerShoppingCart");
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), cartId);
    this.send(msgData);
  };

  goToBuyerHome = () => {
    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageLandingPage)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(getName(MessageEnum.NavigationScreenNameMessage), "BuyerDashboard");
    this.send(msgData);
  };
  // Customizable Area End
}
