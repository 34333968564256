import React from "react";
// Customizable Area Start
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Box, Button, FormControl, InputLabel, Select, TextField, Typography } from "@material-ui/core";
import ShipmentProductController, { Props } from "./ShipmentProductController.web";
import { gear } from "./assets";
import {ExpandMoreRounded} from '@material-ui/icons';
export const configJSON = require("./config");
// Customizable Area End

export class ShipmentProduct extends ShipmentProductController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const styles = this.props.classes;
        return (
            <Box className={styles.shipment_Main_Container}>
                <Typography className={styles.shipment_Heading}>{configJSON.inventoryDetails}</Typography>
                <Box className={styles.shipment_Form_Container}>
                    <form data-testid="submitButtonTestId" className={`${styles.shipment_Form_Main_Container} shipmentForm`} onSubmit={this.handleShipmentSubmit}>
                        <FormControl variant="outlined" className={styles.formControl}>
                            <InputLabel htmlFor="outlined-age-native-simple">{configJSON.fulfilmentBy}</InputLabel>
                            <Select
                                data-testid="fulfilmentByTestId"
                                IconComponent={ExpandMoreRounded}
                                native
                                onChange={this.handleFulfilmentBy}
                                label="Fulfilment by  s"
                                className={styles.formControl_Option_Selected}
                                value={this.state.fulfilmentBy}
                            >
                                <option >{configJSON.select}</option>
                                {this.state.shipmentInvenrtyResponse?.fullfilment_by?.map((item) => {
                                    return (
                                        <>
                                            <option data-testid="fulfilmentByDataTestId" value={item}>{item}</option>
                                        </>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <FormControl variant="outlined" className={styles.formControl}>
                            <InputLabel htmlFor="outlined-age-native-simple">{configJSON.procurementType}</InputLabel>
                            <Select
                                data-testid="procurementTypeTestId"
                                IconComponent={ExpandMoreRounded}
                                value={this.state.procurementType}
                                native
                                onChange={this.handleProcurementType}
                                label="Procurement Type   s"
                                className={styles.formControl_Option_Selected}
                            >
                                <option >{configJSON.select}</option>
                                {this.state.shipmentInvenrtyResponse?.procurement_type?.map((item) => {
                                    return (
                                        <>
                                            <option data-testid="procurementDataTypeTestId" value={item}>{item}</option>
                                        </>
                                    )
                                })}

                            </Select>
                        </FormControl> 
                        <TextField
                            data-testid="procurementSLATestId"
                            id="outlined-required"
                            error={this.state.procurementSLArequried}
                            helperText={this.state.procurementSLArequried ? "Please don't leave it blank." : ""}
                            onChange={this.handleProcurementSLA}
                            className={styles.formControl_Option_TextBox}
                            label="Procurement SLA"
                            variant="outlined"
                            placeholder="Enter number of days"
                            value={this.state.procurementSLA}
                        />
                        <Box className={styles.formControl_Middle_Heading_Container}>
                            <img src={gear} alt="gear" className={styles.gear} />
                            <Typography className={styles.formControl_Middle_Heading}>{configJSON.time}</Typography>
                        </Box>
                        <TextField
                            data-testid="stockQuantityTestId"
                            id="outlined-required"
                            error={this.state.stockQuantityrequried}
                            helperText={this.state.stockQuantityrequried ? "Please don't leave it blank." : ""}
                            onChange={this.handleStockQuantity}
                            className={styles.formControl_Option_TextBox}
                            label="Stock Quantity"
                            defaultValue=" "
                            value={this.state.stockQuantity}
                            variant="outlined" />
                        <Typography className={styles.formControl_Charges_Heading}>{configJSON.charges}</Typography>
                        <TextField
                            data-testid="localChargerTestId"
                            id="outlined-required"
                            error={this.state.localChargerequried}
                            helperText={this.state.localChargerequried ? "Please don't leave it blank." : ""}
                            onChange={this.handleLocalCharge}
                            className={styles.formControl_Option_TextBox}
                            label="Local Delivery Charge"
                            defaultValue=" "
                            value={this.state.localCharge}
                            variant="outlined" />
                        <TextField
                            data-testid="zonalChargerTestId"
                            id="outlined-required"
                            error={this.state.zonalChargerequried}
                            helperText={this.state.zonalChargerequried ? "Please don't leave it blank." : ""}
                            onChange={this.handleZonalCharge}
                            className={styles.formControl_Option_TextBox}
                            label="Zonal Delivery Charge"
                            defaultValue=" "
                            value={this.state.zonalCharge}
                            variant="outlined" />
                        <TextField
                            data-testid="NationalChargeTestId"
                            id="outlined-required"
                            error={this.state.nationalChargerequried}
                            helperText={this.state.nationalChargerequried ? "Please don't leave it blank." : ""}
                            onChange={this.handleNationalCharge}
                            className={styles.formControl_Option_TextBox}
                            label="National Delivery Charge"
                            defaultValue=" "
                            value={this.state.nationalCharge}
                            variant="outlined" />
                        <Box className={styles.formControl_Button_Container}>
                            <Button type='submit' className={styles.formControl_Button}>{configJSON.save}</Button>
                        </Box>
                    </form>
                </Box>
            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const styles = () =>
    createStyles({
        shipment_Main_Container: {
            padding: "40px 60px 70px 55px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "25px",
            flexDirection: "column",
            "@media (max-width: 560px)": {
                padding: "40px 24px 70px 24px",
            }
        },
        shipment_Form_Container: {
            width: "100%"
        },
        shipment_Form_Main_Container: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: "25px",
            flexDirection: "column",
        },
        shipment_Heading: {
            color: "#555",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
        },
        formControl: {
            width: "50vw",
            height: "56px",
            "@media (max-width: 1000px)": {
                width: "100%",
            }
        },
        formControl_Heading: {
            color: "rgba(68, 68, 68, 0.50)",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
        },
        formControl_Option_Selected: {
            height: "100%",
            color: "#444",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            "& .MuiSelect-select": {
                height: "fit-content !important",
                background:"transparent"
            },
            "& .MuiListItem-root": {
                height: "100%",
            },
            "@media (max-width: 560px)": {
                width: "100%",
            }
        },
        formControl_Option_TextBox: {
            width: "50vw",
            height: "56px",
            color: "#444",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            "@media (max-width: 1000px)": {
                width: "100%",
            }
        },
        formControl_Middle_Heading_Container: {
            display: "flex",
            gap: "5px",
        },
        formControl_Middle_Heading: {
            color: "rgba(68, 68, 68, 0.70)",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "normal",
        },
        formControl_Charges_Heading: {
            color: "#555",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
        },
        formControl_Button_Container: {
            width: "100%",
            display: "flex",
            justifyContent: "end",
            "@media (max-width: 560px)": {
                justifyContent: "center",
            }
        },
        formControl_Button: {
            padding: "0 10%",
            height: "48px",
            width: "fit-content",
            background: "#F5EA16",
            borderRadius: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#555",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            cursor: "pointer",
            "@media (max-width: 560px)": {
                padding: "0 6%",
            },
            "&:hover": {
                background: "#F5EA16",
            },
        },
        gear: {
            width: "16px",
            height: "16px"
        },
        selectDropDown: {
            color: "#444444",
            marginRight: "10px",
            cursor: "pointer"
        }
    });
export default withStyles(styles)(ShipmentProduct)
// Customizable Area End