import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  calendarIc,
  notificationIc,
  notificationRedDotIc,
  hamburgerIc,
  auction,
  instant,
  post,
  schedule,
  story,
  test,
} from "./assets";

const theme = createTheme({});
// Customizable Area End

import LiveStreamingController, {
  Props,
  configJSON,
} from "./LiveStreamingController";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";

export default class LiveStreaming extends LiveStreamingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.liveStreaming_Main_Container}>
          <Box sx={webStyle.liveStreaming_side_Menu}>
            <div className="dashboard-outer">
              <div className="dashboard-inner">
                <SellerSidebar
                  navigation={"/SellerDashboard"}
                  id={""}
                  classes={undefined}
                  isOpen={this.state.isOpen}
                  data-test-id="sellerSidebar"
                  activetabName="create"
                  closeModal={() => this.toggleDrawer()}
                  data-testid="isOpenTestId"
                />
              </div>
            </div>
          </Box>
          <Box sx={webStyle.liveStreaming_Options_Main_Container}>
            <Box sx={webStyle.liveStreaming_Options_Heading_Container}>
              <Typography component={Box} sx={webStyle.liveStreaming_Options_Heading}>{configJSON.create}</Typography>
              <Box sx={webStyle.liveStreaming_Header_Box}>
                <Box sx={webStyle.liveStreaming_Icon_Box}>
                  <img src={calendarIc} style={{ width: "24px", height: "24px" }} />
                </Box>
                <Box sx={webStyle.liveStreaming_Icon_Box}>
                  <img src={notificationIc} style={{ width: "24px", height: "24px" }} />
                  <img src={notificationRedDotIc} style={{ width: "10px", height: "10px", position: "absolute", top: "5px", right: "8px" }} />
                </Box>
                <Box sx={webStyle.liveStreaming_Icon_Hidden_Box} onClick={() => this.toggleDrawer()} data-testid="toggleDrawerTestId">
                  <img src={hamburgerIc} style={{ width: "24px", height: "24px" }} />
                </Box>
              </Box>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={4}>
              <Box sx={webStyle.liveStreaming_Options_Box} onClick={this.handleInstant} data-testid="instantLiveTestId">
                <img src={instant} alt="Image" style={{ width: "24px", height: "24px" }} />
                <Typography component={Box} sx={webStyle.liveStreaming_Options_Box_Text}>{configJSON.instant}</Typography>
              </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
              <Box sx={webStyle.liveStreaming_Options_Box} onClick={this.handleSchedule} data-testid="scheduleTestId">
                <img src={schedule} alt="Image" style={{ width: "24px", height: "24px" }} />
                <Typography component={Box} sx={webStyle.liveStreaming_Options_Box_Text}>{configJSON.schedule}</Typography>
              </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
              <Box sx={webStyle.liveStreaming_Options_Box} onClick={this.handleTestPost} data-testid="testLiveTestId">
                <img src={test} alt="Image" style={{ width: "24px", height: "24px" }} />
                <Typography component={Box} sx={webStyle.liveStreaming_Options_Box_Text}>{configJSON.test}</Typography>
              </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
              <Box sx={webStyle.liveStreaming_Options_Box} onClick={this.handleCreatePost} data-testid="createPostTestId">
                <img src={post} alt="Image" style={{ width: "24px", height: "24px" }} />
                <Typography component={Box} sx={webStyle.liveStreaming_Options_Box_Text}>{configJSON.post}</Typography>
              </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
              <Box sx={webStyle.liveStreaming_Options_Box} onClick={this.handleCreateStory} data-testid="CreateStoryTestId">
                <img src={story} alt="Image" style={{ width: "24px", height: "24px" }} />
                <Typography component={Box} sx={webStyle.liveStreaming_Options_Box_Text}>{configJSON.story}</Typography>
              </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
              <Box sx={webStyle.liveStreaming_Options_Box} onClick={this.handleAuction} data-testid="auctionTestId">
                <img src={auction} alt="Image" style={{ width: "24px", height: "24px" }} />
                <Typography component={Box} sx={webStyle.liveStreaming_Options_Box_Text}>{configJSON.auction}</Typography>
              </Box>
              </Grid>
        </Grid>
            </Box>
          </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  liveStreaming_Main_Container: {
    display: "flex",
    marginTop: "103px",
    "@media (max-width: 1149px)": {
      marginTop: "70px"
    }
  },
  liveStreaming_side_Menu:{
    marginTop: "0px",
    "@media (max-width: 991px)": {
      marginTop: "20px"
    }
  },
  liveStreaming_Options_Main_Container: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "50px 60px 0px 24px",
    gap: "30px",
    "@media (max-width: 991px)": {
      padding: "50px 60px 50px 24px"
    },
    "@media (max-width: 550px)": {
      padding: "50px 3% 50px"
    }
  },
  liveStreaming_Options_Heading_Container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  liveStreaming_Options_Heading: {
    color: "rgba(68, 68, 68, 0.80)",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal"
  },
  liveStreaming_Header_Box: {
    display: "flex",
    gap: "25px"
  },
  liveStreaming_Icon_Box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "48px",
    height: "48px",
    borderRadius: "10px",
    border: "1px solid #FFF",
    background: "#FFF",
    boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.10)",
    cursor: "pointer",
    position: "relative"
  },
  liveStreaming_Icon_Hidden_Box: {
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    width: "48px",
    height: "48px",
    borderRadius: "10px",
    border: "1px solid #FFF",
    background: "#FFF",
    boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.10)",
    cursor: "pointer",
    position: "relative",
    "@media (max-width: 991px)": {
      display: "flex"
    }
  },
  liveStreaming_Options_Container: {
    display: "flex",
    gap: "22px",
    flexWrap: "wrap",
    "@media (max-width: 789px)": {
      justifyContent: "center"
    }
  },
  liveStreaming_Options_Box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "8px",
    width: "100%",
    height: "116px",
    borderRadius: "10px",
    border: "1px solid rgba(68, 68, 68, 0.20)",
    background: "#FFF",
    cursor: "pointer",
    "@media (max-width: 400px)": {
      width: "100%"
    }
  },
  liveStreaming_Options_Box_Text: {
    color: "#444",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    cursor: "pointer"
  }
};
// Customizable Area End
