export const imgVector = require("../assets/Vector.png");
export const imgProduct = require("../assets/Image.png");
export const imgEdit = require("../assets/edit.png");
export const visa = require("../assets/visa.png");
export const rupay = require("../assets/Rupay.png");
export const master = require("../assets/Master.png");
export const paytm = require("../assets/Paytm.png");
export const exclamation = require("../assets/exclamation.png");





